import {
  boolean,
  count,
  entity,
  equals,
  query,
  string,
  truthy,
  number,
  likeAny,
  isNull,
  distinct,
  ternary,
  every,
} from '~/prix'
import { FilterHighlight, optionsBooleanValue } from '../legalEntityGeoprocessingMapLevels.data'

interface LegalEntitiesGeoprocessingQueryOptions {
  groupColumn: string
  idColumn: string | null
  id: string | null
  filter: FilterHighlight | null
  highlight: FilterHighlight | null
}

export default function legalEntitiesGeoprocessingQuery({
  groupColumn,
  idColumn,
  id,
  filter,
  highlight,
}: LegalEntitiesGeoprocessingQueryOptions) {
  ;({
    groupColumn,
    idColumn,
    id,
    filter,
    highlight,
  })

  return query('legalEntityGeoprocessing')
    .select({
      geoId: entity('legalEntityGeoprocessing').property(groupColumn),
      count:
        filter?.value || highlight?.value
          ? count(distinct(entity('legalEntityGeoprocessing').property('legalEntityId')))
          : count(entity('legalEntityGeoprocessing').property('legalEntityId')),
      highlight:
        highlight?.type === 'size' && highlight?.value
          ? count(
              equals(
                entity('legalEntity').property(highlight?.type),
                string().value(`${highlight.value!}`),
              ),
            )
          : highlight?.type === 'attendance' &&
            highlight?.courseProduct &&
            highlight?.value &&
            highlight.eadOption !== 'portfolio'
          ? count(
              distinct(
                ternary(
                  every(
                    equals(
                      entity(
                        `legalEntityAttendance${
                          highlight?.value === 'lastYear' ? 'LastYear' : 'LastMonth'
                        }`,
                      ).property('productCode'),
                      string().value(highlight?.courseProduct),
                    ),
                  ),
                  entity('legalEntityGeoprocessing').property('legalEntityId'),
                  number().value(null as any),
                ),
              ),
            )
          : highlight?.type === 'attendance' &&
            highlight?.value &&
            highlight.eadOption === 'portfolio' &&
            !highlight?.courseProduct
          ? count(
              distinct(
                ternary(
                  every(
                    equals(
                      entity(
                        `legalEntityAttendance${
                          highlight?.value === 'lastYear' ? 'LastYear' : 'LastMonth'
                        }`,
                      ).property(`isActivePortfolio`),
                      boolean().value(true),
                    ),
                  ),
                  entity('legalEntityGeoprocessing').property('legalEntityId'),
                  number().value(null as any),
                ),
              ),
            )
          : highlight?.type === 'attendance' && highlight?.value && !highlight?.courseProduct
          ? count(
              distinct(
                ternary(
                  every(
                    equals(
                      entity(
                        `legalEntityAttendance${
                          highlight?.value === 'lastYear' ? 'LastYear' : 'LastMonth'
                        }`,
                      ).property(`dataSourceId`),
                      number().value(Number(highlight?.attendanceSource)),
                    ),
                  ),
                  entity('legalEntityGeoprocessing').property('legalEntityId'),
                  number().value(null as any),
                ),
              ),
            )
          : highlight?.type === 'cnaes' && highlight?.value
          ? count(
              likeAny(
                entity('legalEntity').property(highlight?.type),
                highlight?.value
                  .toString()
                  .split(',')
                  .map(cnae_id => string().value(`%,${cnae_id!}%`)),
                { sensitive: true },
              ),
            )
          : highlight?.type === 'legalNatureId' && highlight?.value
          ? count(
              equals(
                entity('legalEntity').property(highlight?.type),
                string().value(`${highlight.value!}`),
              ),
            )
          : highlight?.type === 'segment' && highlight?.value
          ? count(
              likeAny(
                entity('legalEntity').property('cnaes'),
                highlight?.value
                  .toString()
                  .split(',')
                  .map(cnae_id => string().value(`%,${cnae_id!}%`)),
                { sensitive: true },
              ),
            )
          : highlight?.type === 'attendance' && highlight?.courseProduct
          ? count(
              equals(
                entity('courseProduct').property('productCode'),
                number().value(Number(highlight?.courseProduct)),
              ),
            )
          : optionsBooleanValue.includes(`${highlight?.type}`) && highlight?.value
          ? count(equals(entity('legalEntity').property(highlight?.type), boolean().value(true)))
          : number().value(null as unknown as number),
    })
    .join({
      current: entity('legalEntityGeoprocessing').property('legalEntityId'),
      target: entity('legalEntity').property('id'),
      join: 'inner',
    })
    [
      // EaD source
      (filter?.type === 'attendance' && filter?.courseProduct) ||
      (highlight?.type === 'attendance' && highlight?.courseProduct)
        ? 'join'
        : 'dummy'
    ]({
      current: entity('legalEntityGeoprocessing').property('legalEntityId'),
      target: entity(`courseRegisterIndividual`).property('legalEntityId'),
      join: 'left',
    })
    [
      (filter?.type === 'attendance' && filter?.courseProduct) ||
      (highlight?.type === 'attendance' && highlight?.courseProduct)
        ? 'join'
        : 'dummy'
    ]({
      current: entity('courseRegisterIndividual').property('courseProductId'),
      target: entity(`courseProduct`).property('id'),
      join: 'left',
    })
    [
      (filter?.type === 'attendance' && filter?.value === 'lastMonth') ||
      (highlight?.type === 'attendance' && highlight?.value === 'lastMonth')
        ? 'join'
        : 'dummy'
    ]({
      current: entity('legalEntityGeoprocessing').property('legalEntityId'),
      target: entity(`legalEntityAttendanceLastMonth`).property('legalEntityId'),
      join: 'left',
    })
    [
      (filter?.type === 'attendance' && filter?.value === 'lastYear') ||
      (highlight?.type === 'attendance' && highlight?.value === 'lastYear')
        ? 'join'
        : 'dummy'
    ]({
      current: entity('legalEntityGeoprocessing').property('legalEntityId'),
      target: entity(`legalEntityAttendanceLastYear`).property('legalEntityId'),
      join: 'left',
    })
    .join({
      current: entity('legalEntityGeoprocessing').property('streetId'),
      target: entity('street').property('id'),
      join: 'left',
    })
    [idColumn === 'neighborhoodId' ? 'join' : 'dummy']({
      current: entity('street').property('id'),
      target: entity('neighborhoodStreetIntersection').property('streetId'),
      join: 'inner',
    })
    .join({
      current: entity('legalEntityGeoprocessing').property('countryId'),
      target: entity('country').property('id'),
      join: 'inner',
    })
    .join({
      current: entity('legalEntityGeoprocessing').property('stateId'),
      target: entity('state').property('id'),
      join: 'inner',
    })
    .join({
      current: entity('legalEntityGeoprocessing').property('cityId'),
      target: entity('city').property('id'),
      join: 'inner',
    })
    .where(
      ...[
        isNull(entity('legalEntity').property('deletedAt')),
        idColumn !== null && id !== null && idColumn === 'neighborhoodId'
          ? equals(entity('neighborhoodStreetIntersection').property(idColumn), string().value(id))
          : null,

        idColumn !== null &&
        id !== null &&
        idColumn !== 'countryIsoCode' &&
        idColumn !== 'neighborhoodId'
          ? equals(entity('legalEntityGeoprocessing').property(idColumn), string().value(id))
          : null,

        idColumn === 'countryIsoCode'
          ? equals(entity('country').property('iso'), number().value(Number(id!)))
          : null,

        filter?.type === 'attendance' && filter?.value && filter?.attendanceSource
          ? equals(
              entity(
                `legalEntityAttendance${filter?.value === 'lastYear' ? 'LastYear' : 'LastMonth'}`,
              ).property(`dataSourceId`),
              number().value(Number(filter?.attendanceSource)),
            )
          : null,

        filter?.type === 'attendance' && filter?.courseProduct && filter.value
          ? equals(
              entity(
                `legalEntityAttendance${filter?.value === 'lastYear' ? 'LastYear' : 'LastMonth'}`,
              ).property('productCode'),
              string().value(filter?.courseProduct),
            )
          : null,

        filter?.type === 'attendance' &&
        filter?.value &&
        filter.eadOption === 'portfolio' &&
        !filter?.courseProduct
          ? equals(
              entity(
                `legalEntityAttendance${filter?.value === 'lastYear' ? 'LastYear' : 'LastMonth'}`,
              ).property('isActivePortfolio'),
              boolean().value(true),
            )
          : null,

        filter?.type === 'size' && filter?.value
          ? equals(
              entity('legalEntity').property(`${filter?.type}`),
              string().value(`${filter?.value!}`),
            )
          : null,

        filter?.type === 'cnaes' && filter?.value
          ? likeAny(
              entity('legalEntity').property(filter?.type),
              filter?.value
                .toString()
                .split(',')
                .map(cnae_id => string().value(`%,${cnae_id!}%`)),
              { sensitive: true },
            )
          : null,

        filter?.type === 'segment' && filter?.value
          ? likeAny(
              entity('legalEntity').property('cnaes'),
              filter?.value
                .toString()
                .split(',')
                .map(cnae_id => string().value(`%,${cnae_id!}%`)),
              { sensitive: true },
            )
          : null,

        filter?.type === 'legalNatureId' && filter?.value
          ? equals(
              entity('legalEntity').property(`${filter?.type}`),
              string().value(`${filter?.value!}`),
            )
          : null,

        optionsBooleanValue.includes(`${filter?.type}`)
          ? equals(entity('legalEntity').property(`${filter?.type}`), boolean().value(true))
          : null,
      ].filter(truthy),
    )
    .limit(10000)
}

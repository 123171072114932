import chroma from 'chroma-js'
import sub from 'date-fns/sub'
import React, { useCallback, useMemo } from 'react'
import { colors } from '~/design'
import { truthy } from '~/prix'
import { BottomAxis, BottomAxisProps } from '~/prix/react/components/lineChart/bottomAxis'
import { Series } from '~/prix/react/components/lineChart/lineChart'
import LineChartWithScroll from '~/prix/react/components/lineChart/lineChartWithScroll'
import { formatDate, parseDate, stringifyDate } from '~/prix/types/date'
import { parseDateTime } from '~/prix/types/dateTime'
import {
  AbovePredictionComponent,
  BelowPredictionComponent,
  Component,
  MovingThresholdComponent,
  TimeSeriesItem,
  TTestOutbreaksComponent,
} from './timeSeries.entity'
import { format, parse, parseISO, startOfMonth, startOfWeek } from 'date-fns'
import { useParams } from 'react-router-dom'

const series: Series[] = [
  {
    label: 'Predição',
    marker: {
      color: colors.nausPurple,
    },
    line: {
      color: colors.nausPurple,
      width: 3,
      dashed: true,
      curve: 'curveMonotoneX',
    },
  },
  {
    label: 'Ocorrências',
    marker: {
      color: colors.sebraeBlue,
    },
    line: {
      color: colors.sebraeBlue,
      width: 3,
      curve: 'curveMonotoneX',
    },
  },
]

export interface TimeSeriesDefaultBottomChartProps {
  items: TimeSeriesItem[]
  components: Component[]
  height: number
  periodicity: string
}

function TimeSeriesDefaultBottomChart({
  items,
  components,
  height,
  periodicity,
}: TimeSeriesDefaultBottomChartProps) {
  const { eventDate: eventDateParam } = useParams()
  const lastDay = parseISO(items[items.length - 1].date)
  const chartItems = useMemo(() => {
    switch (periodicity) {
      case 'daily':
        return items.map(item => ({
          values: [item.predictedValue, item.value],
          date: formatDate(parseDateTime(item.date), true),
        }))
      case 'weekly':
        let currentWeekStart = startOfWeek(parseDateTime(items[0].date))
        let currentWeekValues = {
          predictedValue: 0,
          value: 0,
        }

        const weeklyItems: any[] = []

        items.forEach(item => {
          const itemWeekStart = startOfWeek(parseDateTime(item.date))
          let checkPredicament = false
          if (itemWeekStart.getTime() === currentWeekStart.getTime()) {
            currentWeekValues.predictedValue += Number(item.predictedValue)
            currentWeekValues.value += Number(item.value)
          } else {
            if (item.predictedValue !== null) checkPredicament = true
            weeklyItems.push({
              values: [
                checkPredicament ? currentWeekValues.predictedValue : null,
                !checkPredicament ? currentWeekValues.value : null,
              ],
              date: format(currentWeekStart, 'dd/MM/yy'),
            })
            currentWeekStart = itemWeekStart
            currentWeekValues = {
              predictedValue: Number(item.predictedValue),
              value: Number(item.value),
            }
          }
        })

        return weeklyItems
      case 'monthly':
        const monthlyItems = items.reduce((acc: any, item) => {
          const monthYearKey = format(parseDateTime(item.date), 'MM/yyyy')
          if (!acc[monthYearKey]) {
            acc[monthYearKey] = {
              predictedValue: 0,
              value: 0,
              date: monthYearKey,
            }
          }

          acc[monthYearKey].predictedValue += Number(item.predictedValue)
          acc[monthYearKey].value += Number(item.value)
          acc[monthYearKey].date = monthYearKey

          return acc
        }, {})

        const sortedKeys = Object.keys(monthlyItems).sort((a, b) => {
          const dateA = parseDateTime(`01/${a}`)
          const dateB = parseDateTime(`01/${b}`)
          return dateA.getTime() - dateB.getTime()
        })

        return sortedKeys.map(key => ({
          values: [
            null,
            monthlyItems[key].predictedValue !== 0 && monthlyItems[key].value == 0
              ? null
              : monthlyItems[key].value,
          ],
          date: monthlyItems[key].date,
        }))
      case 'yearly':
        let currentYear = format(parseDateTime(items[0].date), 'yyyy')
        let currentYearValues = {
          predictedValue: null,
          value: 0,
        }
        const yearlyItems = []

        items.forEach(item => {
          const itemYear = format(parseDateTime(item.date), 'yyyy')
          if (itemYear === currentYear) {
            currentYearValues.value += Number(item.value)
          } else {
            yearlyItems.push({
              values: [null, currentYearValues.value],
              date: currentYear,
            })
            currentYear = itemYear
            currentYearValues = {
              predictedValue: null,
              value: Number(item.value),
            }
          }
        })

        yearlyItems.push({
          values: [null, currentYearValues.value],
          date: currentYear,
        })

        return yearlyItems
      default:
        return items.map(item => ({
          values: [item.predictedValue, item.value],
          date: formatDate(parseDateTime(item.date), true),
        }))
    }
  }, [items, periodicity]) as {
    values: number[] | null[]
    date: string
  }[]

  const indexOfComponentAnalysis = useMemo(
    () => items?.findIndex(item => item.components) ?? null,
    [items],
  )
  const indexOfEventDate = eventDateParam
    ? items.findIndex(item => new Date(item.date).getTime() / 1000 === Number(eventDateParam)) + 9
    : null

  const finalEventIndex =
    indexOfEventDate && indexOfEventDate + 9 > indexOfComponentAnalysis
      ? indexOfComponentAnalysis
      : indexOfEventDate

  const itemDate = parseISO(items[indexOfComponentAnalysis].date)
  let itemWidth = 65
  let adjustedDate: Date
  let dateFormat = 'dd/MM/yy'
  switch (periodicity) {
    case 'daily':
      itemWidth = 65
      adjustedDate = itemDate
      break
    case 'weekly':
      itemWidth = 70
      adjustedDate = startOfWeek(itemDate)
      dateFormat = 'dd/MM/yy'
      break
    case 'monthly':
      itemWidth = chartItems.length < 6 ? 90 : 75
      adjustedDate = startOfMonth(itemDate)
      dateFormat = 'MM/yyyy'
      break
    case 'yearly':
      itemWidth = chartItems.length < 5 ? 300 : 100
      adjustedDate = itemDate
      break
    default:
      itemWidth = 65
      adjustedDate = itemDate
  }

  const chartItemIndex = chartItems.findIndex(
    item => parse(item.date, dateFormat, new Date()).getTime() === adjustedDate.getTime(),
  )

  const firstNonNullPredictedValueIndex = chartItems.findIndex(item => item.values[0] !== null)
  const indexOfPredictionStart =
    chartItemIndex !== -1
      ? periodicity === 'daily'
        ? indexOfComponentAnalysis + 1
        : periodicity === 'weekly'
        ? firstNonNullPredictedValueIndex
        : null
      : null

  const indexOfPredictionEnd = chartItems ? chartItems.length - 1 : items.length - 1
  const [outbreaks, emerging, notification, abovePrediction, belowPrediction] = useMemo(() => {
    const outbreaks = components
      .filter(component => component.type == 'tTestOutbreaks')
      .map(component => (component as TTestOutbreaksComponent).detections)
      .flat()
      .filter(truthy)
    const aboveMovingThreshold = components
      .filter(component => component.type == 'movingThreshold')
      .map(component => (component as MovingThresholdComponent).detections)
      .flat()
      .filter(truthy)
    const aboveFixedThreshold = components
      .filter(component => component.type == 'fixedThreshold')
      .map(component => (component as MovingThresholdComponent).detections)
      .flat()
      .filter(truthy)
    const abovePrediction = components
      .filter(component => component.type == 'abovePrediction')
      .map(component => (component as AbovePredictionComponent).detections)
      .flat()
      .filter(truthy)
    const belowPrediction = components
      .filter(component => component.type == 'belowPrediction')
      .map(component => (component as BelowPredictionComponent).detections)
      .flat()
      .filter(truthy)
    const outbreaksWithIndex = outbreaks.map(outbreak => {
      let startDate = parseISO(outbreak.start)
      let endDate = parseISO(outbreak.end)

      if (periodicity === 'weekly') {
        startDate = startOfWeek(startDate)
        endDate = startOfWeek(endDate)
      } else if (periodicity === 'monthly') {
        startDate = startOfMonth(startDate)
        endDate = startOfMonth(endDate)
      }

      const startIndex = chartItems.findIndex(
        item => parse(item.date, dateFormat, new Date()).getTime() === startDate.getTime(),
      )
      const endIndex = chartItems.findIndex(
        item => parse(item.date, dateFormat, new Date()).getTime() === endDate.getTime(),
      )

      return {
        ...outbreak,
        startIndex,
        endIndex,
      }
    })
    const aboveMovingThresholdUntilOutbreak = aboveMovingThreshold
      .map(above => {
        const outbreakIntersectingAbove = outbreaksWithIndex.find(
          outbreak => outbreak.start >= above.start && outbreak.start <= above.end,
        )
        if (outbreakIntersectingAbove && outbreakIntersectingAbove.start === above.start) {
          return null
        }
        return {
          ...above,
          end: outbreakIntersectingAbove
            ? stringifyDate(
                sub(parseDate(outbreakIntersectingAbove.start), {
                  days: 1,
                }),
              )
            : above.end,
        }
      })
      .filter(truthy)
    const aboveFixedThresholdUntilOutbreakOrMoving = aboveFixedThreshold
      .map(above => {
        const outbreakIntersectingAbove = outbreaksWithIndex.find(
          outbreak => outbreak.start >= above.start && outbreak.start <= above.end,
        )
        if (outbreakIntersectingAbove && outbreakIntersectingAbove.start === above.start) {
          return null
        }
        const movingIntersectingAbove = aboveMovingThresholdUntilOutbreak.find(
          moving => moving.start >= above.start && moving.start <= above.end,
        )
        if (movingIntersectingAbove && movingIntersectingAbove.start === above.start) {
          return null
        }
        return {
          ...above,
          end: outbreakIntersectingAbove
            ? stringifyDate(
                sub(parseDate(outbreakIntersectingAbove.start), {
                  days: 1,
                }),
              )
            : movingIntersectingAbove
            ? stringifyDate(
                sub(parseDate(movingIntersectingAbove.start), {
                  days: 1,
                }),
              )
            : above.end,
        }
      })
      .filter(truthy)

    const abovePredictionUntilOutbreakOrMoving = abovePrediction
      .map(above => {
        const outbreakIntersectingAbove = outbreaksWithIndex.find(
          outbreak => outbreak.start >= above.start && outbreak.start <= above.end,
        )
        if (outbreakIntersectingAbove && outbreakIntersectingAbove.start === above.start) {
          return null
        }
        const movingIntersectingAbove = aboveMovingThresholdUntilOutbreak.find(
          moving => moving.start >= above.start && moving.start <= above.end,
        )
        if (movingIntersectingAbove && movingIntersectingAbove.start === above.start) {
          return null
        }
        return {
          ...above,
          end: outbreakIntersectingAbove
            ? stringifyDate(
                sub(parseDate(outbreakIntersectingAbove.start), {
                  days: 1,
                }),
              )
            : movingIntersectingAbove
            ? stringifyDate(
                sub(parseDate(movingIntersectingAbove.start), {
                  days: 1,
                }),
              )
            : above.end,
        }
      })
      .filter(truthy)

    const belowPredictionUntilOutbreakOrMoving = belowPrediction
      .map(below => {
        const outbreakIntersectingAbove = outbreaksWithIndex.find(
          outbreak => outbreak.start >= below.start && outbreak.start <= below.end,
        )
        if (outbreakIntersectingAbove && outbreakIntersectingAbove.start === below.start) {
          return null
        }
        const movingIntersectingAbove = aboveMovingThresholdUntilOutbreak.find(
          moving => moving.start >= below.start && moving.start <= below.end,
        )
        if (movingIntersectingAbove && movingIntersectingAbove.start === below.start) {
          return null
        }
        return {
          ...below,
          end: outbreakIntersectingAbove
            ? stringifyDate(
                sub(parseDate(outbreakIntersectingAbove.start), {
                  days: 1,
                }),
              )
            : movingIntersectingAbove
            ? stringifyDate(
                sub(parseDate(movingIntersectingAbove.start), {
                  days: 1,
                }),
              )
            : below.end,
        }
      })
      .filter(truthy)

    const aboveMovingThresholdUntilOutbreakWithIndex = aboveMovingThresholdUntilOutbreak.map(
      above => {
        let startDate = parseISO(above.start)
        let endDate = parseISO(above.end)

        if (periodicity === 'weekly') {
          startDate = startOfWeek(startDate)
          endDate = startOfWeek(endDate)
        } else if (periodicity === 'monthly') {
          startDate = startOfMonth(startDate)
          endDate = startOfMonth(endDate)
        }

        const startIndex = chartItems.findIndex(
          item => parse(item.date, dateFormat, new Date()).getTime() === startDate.getTime(),
        )
        const endIndex = chartItems.findIndex(
          item => parse(item.date, dateFormat, new Date()).getTime() === endDate.getTime(),
        )

        return {
          ...above,
          startIndex,
          endIndex,
        }
      },
    )

    const aboveFixedThresholdUntilOutbreakOrMovingWithIndex =
      aboveFixedThresholdUntilOutbreakOrMoving.map(above => {
        let startDate = parseISO(above.start)
        let endDate = parseISO(above.end)

        if (periodicity === 'weekly') {
          startDate = startOfWeek(startDate)
          endDate = startOfWeek(endDate)
        } else if (periodicity === 'monthly') {
          startDate = startOfMonth(startDate)
          endDate = startOfMonth(endDate)
        }

        const startIndex = chartItems.findIndex(
          item => parse(item.date, dateFormat, new Date()).getTime() === startDate.getTime(),
        )
        const endIndex = chartItems.findIndex(
          item => parse(item.date, dateFormat, new Date()).getTime() === endDate.getTime(),
        )

        return {
          ...above,
          startIndex,
          endIndex,
        }
      })

    const abovePredictionUntilOutbreakOrMovingWithIndex = abovePredictionUntilOutbreakOrMoving.map(
      above => {
        let startDate = parseISO(above.start)
        let endDate = parseISO(above.end)

        if (periodicity === 'weekly') {
          startDate = startOfWeek(startDate)
          endDate = startOfWeek(endDate)
        } else if (periodicity === 'monthly') {
          startDate = startOfMonth(startDate)
          endDate = startOfMonth(endDate)
        }

        const startIndex = chartItems.findIndex(
          item => parse(item.date, dateFormat, new Date()).getTime() === startDate.getTime(),
        )
        const endIndex = chartItems.findIndex(
          item => parse(item.date, dateFormat, new Date()).getTime() === endDate.getTime(),
        )

        return {
          ...above,
          startIndex,
          endIndex,
        }
      },
    )

    const belowPredictionUntilOutbreakOrMovingWithIndex = belowPredictionUntilOutbreakOrMoving.map(
      below => {
        let startDate = parseISO(below.start)
        let endDate = parseISO(below.end)

        if (periodicity === 'weekly') {
          startDate = startOfWeek(startDate)
          endDate = startOfWeek(endDate)
        } else if (periodicity === 'monthly') {
          startDate = startOfMonth(startDate)
          endDate = startOfMonth(endDate)
        }

        const startIndex = chartItems.findIndex(
          item => parse(item.date, dateFormat, new Date()).getTime() === startDate.getTime(),
        )
        const endIndex = chartItems.findIndex(
          item => parse(item.date, dateFormat, new Date()).getTime() === endDate.getTime(),
        )

        return {
          ...below,
          startIndex,
          endIndex,
        }
      },
    )

    return [
      outbreaksWithIndex,
      aboveMovingThresholdUntilOutbreakWithIndex,
      aboveFixedThresholdUntilOutbreakOrMovingWithIndex,
      abovePredictionUntilOutbreakOrMovingWithIndex,
      belowPredictionUntilOutbreakOrMovingWithIndex,
    ]
  }, [components, items, chartItems])

  const eventIndexes = useMemo(() => {
    if (periodicity === 'yearly') return null

    const predictionIndexes = {
      startIndex: indexOfPredictionStart ? indexOfPredictionStart : null,
      endIndex: indexOfPredictionEnd ? indexOfPredictionEnd : null,
    }

    const allObjects = outbreaks
      .concat(abovePrediction, belowPrediction, emerging, notification, predictionIndexes)
      .sort((a, b) => a.startIndex - b.startIndex)

    const indexes = allObjects.map(object => {
      return {
        startWidth: itemWidth * object.startIndex + 1.5,
        endWidth: itemWidth * object.endIndex + 1.5,
      }
    })

    return indexes
  }, [outbreaks, emerging, notification, abovePrediction, belowPrediction])

  const renderBottomAxis = useCallback(
    (props: BottomAxisProps) => (
      <BottomAxis
        {...props}
        getLabel={index => {
          if (chartItems[index]) {
            return chartItems[index].date
          } else {
            return '0'
          }
        }}
        before={
          <>
            {abovePrediction.map((above, index) => (
              <React.Fragment key={index}>
                <line
                  x1={above.startIndex * itemWidth}
                  y1={0}
                  x2={above.startIndex * itemWidth}
                  y2={props.top}
                  stroke='#f39c12'
                  strokeWidth={2}
                />
                <rect
                  x={above.startIndex * itemWidth}
                  y={0}
                  width={(above.endIndex - above.startIndex) * itemWidth + itemWidth}
                  height={props.top}
                  fill='rgba(243, 156, 18, 0.1)'
                />
                <line
                  x1={above.endIndex * itemWidth + itemWidth}
                  y1={0}
                  x2={above.endIndex * itemWidth + itemWidth}
                  y2={props.top}
                  stroke='#f39c12'
                  strokeWidth={2}
                />
                <text
                  x={above.startIndex * itemWidth + 3}
                  y={15}
                  fill='#f39c12'
                  fontSize={12}
                  fontWeight={600}
                >
                  {(above.endIndex - above.startIndex) * itemWidth + itemWidth < 75
                    ? 'Acima'
                    : 'Acima do esperado'}
                </text>
              </React.Fragment>
            ))}

            {belowPrediction.map((below, index) => (
              <React.Fragment key={index}>
                <line
                  x1={below.startIndex * itemWidth}
                  y1={0}
                  x2={below.startIndex * itemWidth}
                  y2={props.top}
                  stroke='#1abc9c'
                  strokeWidth={2}
                />
                <rect
                  x={below.startIndex * itemWidth}
                  y={0}
                  width={(below.endIndex - below.startIndex) * itemWidth + itemWidth}
                  height={props.top}
                  fill='rgba(26, 188, 156, 0.1)'
                />
                <line
                  x1={below.endIndex * itemWidth + itemWidth}
                  y1={0}
                  x2={below.endIndex * itemWidth + itemWidth}
                  y2={props.top}
                  stroke='#1abc9c'
                  strokeWidth={2}
                />
                <text
                  x={below.startIndex * itemWidth + 3}
                  y={15}
                  fill='#1abc9c'
                  fontSize={12}
                  fontWeight={600}
                >
                  {(below.endIndex - below.startIndex) * itemWidth + itemWidth < 75
                    ? 'Acima'
                    : 'Acima do esperado'}
                </text>
              </React.Fragment>
            ))}

            {notification.map((above, index) => (
              <React.Fragment key={index}>
                <line
                  x1={above.startIndex * itemWidth}
                  y1={0}
                  x2={above.startIndex * itemWidth}
                  y2={props.top}
                  stroke='black'
                  strokeWidth={2}
                />
                <rect
                  x={above.startIndex * itemWidth}
                  y={0}
                  width={(above.endIndex - above.startIndex) * itemWidth + itemWidth}
                  height={props.top}
                  fill='rgba(0, 0, 0, 0.1)'
                />
                <line
                  x1={above.endIndex * itemWidth + itemWidth}
                  y1={0}
                  x2={above.endIndex * itemWidth + itemWidth}
                  y2={props.top}
                  stroke='black'
                  strokeWidth={2}
                />
                <text
                  x={above.startIndex * itemWidth + 5}
                  y={15}
                  fill='black'
                  fontSize={12}
                  fontWeight={600}
                >
                  Notificação
                </text>
              </React.Fragment>
            ))}

            {emerging.map((above, index) => (
              <React.Fragment key={index}>
                <line
                  x1={above.startIndex * itemWidth}
                  y1={0}
                  x2={above.startIndex * itemWidth}
                  y2={props.top}
                  stroke='#f39c12'
                  strokeWidth={2}
                />
                <rect
                  x={above.startIndex * itemWidth}
                  y={0}
                  width={(above.endIndex - above.startIndex) * itemWidth + itemWidth}
                  height={props.top}
                  fill='rgba(243, 156, 18, 0.1)'
                />
                <line
                  x1={above.endIndex * itemWidth + itemWidth}
                  y1={0}
                  x2={above.endIndex * itemWidth + itemWidth}
                  y2={props.top}
                  stroke='#f39c12'
                  strokeWidth={2}
                />
                <text
                  x={above.startIndex * itemWidth + 5}
                  y={15}
                  fill='#f39c12'
                  fontSize={12}
                  fontWeight={600}
                >
                  Emergente
                </text>
              </React.Fragment>
            ))}

            {outbreaks.map((outbreak, index) => (
              <React.Fragment key={index}>
                <line
                  x1={outbreak.startIndex * itemWidth}
                  y1={0}
                  x2={outbreak.startIndex * itemWidth}
                  y2={props.top}
                  stroke='red'
                  strokeWidth={2}
                />
                <rect
                  x={outbreak.startIndex * itemWidth}
                  y={0}
                  width={(outbreak.endIndex - outbreak.startIndex) * itemWidth + itemWidth}
                  height={props.top}
                  fill='rgba(255, 0, 0, 0.1)'
                />
                <line
                  x1={outbreak.endIndex * itemWidth + itemWidth}
                  y1={0}
                  x2={outbreak.endIndex * itemWidth + itemWidth}
                  y2={props.top}
                  stroke='red'
                  strokeWidth={2}
                />
                <text
                  x={outbreak.startIndex * itemWidth + 5}
                  y={15}
                  fill='red'
                  fontSize={12}
                  fontWeight={600}
                >
                  Surto
                </text>
              </React.Fragment>
            ))}

            {indexOfPredictionStart !== null && (
              <>
                <line
                  x1={indexOfPredictionStart * itemWidth}
                  y1={0}
                  x2={indexOfPredictionStart * itemWidth}
                  y2={props.top}
                  stroke={colors.nausPurple}
                  strokeWidth={2}
                />
                <rect
                  x={indexOfPredictionStart * itemWidth}
                  y={0}
                  width={(indexOfPredictionEnd - indexOfPredictionStart) * itemWidth + itemWidth}
                  height={props.top}
                  fill={chroma(colors.nausPurple).alpha(0.1).css()}
                />
                <line
                  x1={indexOfPredictionEnd * itemWidth + itemWidth}
                  y1={0}
                  x2={indexOfPredictionEnd * itemWidth + itemWidth}
                  y2={props.top}
                  stroke={colors.nausPurple}
                  strokeWidth={2}
                />
                <text
                  x={indexOfPredictionStart * itemWidth + 5}
                  y={15}
                  fill={colors.nausPurple}
                  fontSize={12}
                  fontWeight={600}
                >
                  Predição
                </text>
              </>
            )}
          </>
        }
      />
    ),
    [items, outbreaks, chartItems],
  )

  return (
    <LineChartWithScroll
      itemWidth={itemWidth}
      height={height}
      items={chartItems}
      series={series}
      initialScroll={
        finalEventIndex
          ? itemWidth * (finalEventIndex + 1.5)
          : indexOfComponentAnalysis !== null
          ? itemWidth * (indexOfComponentAnalysis + 1.5)
          : 'end'
      }
      initialScrollEndBased
      BottomAxis={renderBottomAxis}
      topSpacingForFixedAxis={30}
      eventIndexes={eventIndexes}
      periodicity={periodicity}
      lastDayOfData={lastDay}
    />
  )
}

export default React.memo(TimeSeriesDefaultBottomChart)

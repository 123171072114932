import {
  GeoChildrenLegalEntitiesPossibilities,
  GeoLegalEntitiesPossibilities,
} from '~/packages/legalEntityGeoprocessing/map/legalEntityGeoprocessingMapLevels.data'
import { eadOptions } from '~/packages/legalEntityGeoprocessing/map/menu/legalEntityGeoprocessingMapMenuTypes'
import {
  boolean,
  descending,
  entity,
  equals,
  number,
  query,
  some,
  string,
  sum,
  truthy,
} from '~/prix'
import left from '~/prix/functions/left'

export default function disproportionateAttentionQuery(
  geoFilter: {
    geoLevel: GeoLegalEntitiesPossibilities | null
    geoLevelId: string | null
    geoAggregate: GeoChildrenLegalEntitiesPossibilities | null
  },
  filters: {
    size: 'EPP' | 'MEI' | 'ME' | null
    economicActivity: string | null
    dataSourceId: number | null
    attendancePeriod: 'lastYear' | 'lastMonth' | null
    eadOption: keyof typeof eadOptions | null
    courseProductCode: string | null
  },
) {
  /**
   * Gerar parâmetros WHERE compatíveis com a PrixApi filtrando a legal_entity por porte. EPP, MEI e EE.
   * @returns Operador de comparação ou nulo
   */
  function filterBySize(fromSource: 'public' | 'attendances') {
    const targetEntity =
      fromSource === 'public'
        ? 'disproportionateAttentionPublic'
        : 'disproportionateAttentionAttendances'

    if (filters.size) {
      switch (filters.size) {
        case 'EPP':
          return equals(entity(targetEntity).property('size'), string().value('small'))
        case 'ME':
          return equals(entity(targetEntity).property('size'), string().value('micro'))
        case 'MEI':
          return equals(entity(targetEntity).property('isMei'), boolean().value(true))
      }
    }
    return null
  }
  /**
   * Gerar parâmetros WHERE compatíveis com a PrixApi filtrando pelo seu main_cnae (Em níveis de seção, divisão, grupo e classe.).
   * @returns Operador de comparação ou nulo
   */
  function filterByEconomicActivity(fromSource: 'public' | 'attendances') {
    const targetEntity =
      fromSource === 'public'
        ? 'disproportionateAttentionPublic'
        : 'disproportionateAttentionAttendances'

    if (filters.economicActivity) {
      const cnaes = filters.economicActivity.split(',').map(item => {
        return equals(
          left(entity(targetEntity).property('cnaeId'), number().value(item.length)),
          string().value(item),
        )
      })
      return some(...cnaes)
    }
  }
  /**
   * Gerar parâmetros WHERE compatíveis com a PrixApi filtrando os atendimentos pelo fonte de dados.
   * @returns Operador de comparação ou nulo
   */
  function filterByDataSource() {
    if (filters.dataSourceId) {
      if (filters.attendancePeriod === null) {
        return [
          equals(
            entity('disproportionateAttentionAttendances').property('dataSourceId'),
            number().value(filters.dataSourceId),
          ),
        ]
      } else {
        switch (filters.attendancePeriod) {
          case 'lastYear':
            return [
              equals(
                entity('disproportionateAttentionAttendances').property('hasLastYear'),
                boolean().value(true),
              ),
              equals(
                entity('disproportionateAttentionAttendances').property('dataSourceId'),
                number().value(filters.dataSourceId),
              ),
            ]
          case 'lastMonth':
            return [
              equals(
                entity('disproportionateAttentionAttendances').property('hasLastMonth'),
                boolean().value(true),
              ),
              equals(
                entity('disproportionateAttentionAttendances').property('dataSourceId'),
                number().value(filters.dataSourceId),
              ),
            ]
        }
      }
    }
    return []
  }
  /**
   * Gerar parâmetros WHERE compatíveis com a PrixApi filtrando os atendimentos pelo fonte de dados do EAD, Portfólio ou individual.
   * @returns Operador de comparação ou nulo
   */
  function filterByEadOptions() {
    if (filters.eadOption) {
      switch (filters.eadOption) {
        case 'all':
          return [null]
        case 'portfolio':
          return [
            equals(
              entity('disproportionateAttentionAttendances').property('isActivePortfolio'),
              boolean().value(true),
            ),
          ]
        case 'individual':
          if (filters.courseProductCode) {
            return [
              equals(
                entity('disproportionateAttentionAttendances').property('courseProductCode'),
                string().value(filters.courseProductCode),
              ),
            ]
          } else {
            return [null]
          }
        default:
          return [null]
      }
    }
    return [null]
  }
  /**
   * Gerar parâmetros WHERE compatíveis com a PrixApi filtrando a query pelo seu nível geográfico (Estado, cidade, etc...).
   * @returns Operador de comparação ou nulo
   */
  function filterByGeoFilter(fromSource: 'public' | 'attendances') {
    let geoFilterWhere = undefined
    let geoFilterAggregate = undefined

    const targetEntity =
      fromSource === 'public'
        ? 'disproportionateAttentionPublic'
        : 'disproportionateAttentionAttendances'

    if (geoFilter.geoAggregate) {
      switch (geoFilter.geoAggregate) {
        case 'countries':
          geoFilterAggregate = entity(targetEntity).property(`countryId`)
          break
        case 'cities':
          geoFilterAggregate = entity(targetEntity).property(`cityId`)
          break
        case 'macroRegions':
          geoFilterAggregate = entity(targetEntity).property(`macroRegionId`)
          break
        case 'mesoRegions':
          geoFilterAggregate = entity(targetEntity).property(`mesoRegionId`)
          break
        case 'microRegions':
          geoFilterAggregate = entity(targetEntity).property(`microRegionId`)
          break
        case 'neighborhoods':
          geoFilterAggregate = entity(targetEntity).property(`neighborhoodId`)
          break
        case 'states':
          geoFilterAggregate = entity(targetEntity).property(`stateId`)
          break
      }
    }
    if (geoFilter.geoLevel && geoFilter.geoLevelId) {
      geoFilterWhere = equals(
        entity(targetEntity).property(`${geoFilter.geoLevel}Id`),
        string().value(geoFilter.geoLevelId),
      )
    }
    return {
      geoFilterWhere,
      geoFilterAggregate,
    }
  }

  /**
   * Filtros unidos para as queries de atendimento e legal_entity para reutilização posterior.
   */
  const prixApiFilters = (fromSource: 'public' | 'attendances') =>
    [
      filterBySize(fromSource),
      filterByEconomicActivity(fromSource),
      filterByGeoFilter(fromSource).geoFilterWhere,
    ].filter(truthy)
  /**
   * Filtros unidos para filtrar os atendimentos em específico.
   */
  const prixApiFiltersAttendance = [
    ...prixApiFilters('attendances'),
    ...filterByDataSource(),
    ...filterByEadOptions(),
  ].filter(truthy)

  const publicQuery = query('disproportionateAttentionPublic')
    .select({
      cnaeId: entity('disproportionateAttentionPublic').property('cnaeId'),
      cnaeDescription: entity('disproportionateAttentionPublic').property('cnaeDescription'),
      count: sum(entity('disproportionateAttentionPublic').property('count')),
      geoId: filterByGeoFilter('public').geoFilterAggregate,
    })
    .where(...prixApiFilters('public'))
    .order(descending('count'))

  const attendanceQuery = query('disproportionateAttentionAttendances')
    .select({
      cnaeId: entity('disproportionateAttentionAttendances').property('cnaeId'),
      cnaeDescription: entity('disproportionateAttentionAttendances').property('cnaeDescription'),
      count: sum(entity('disproportionateAttentionAttendances').property('count')),
      geoId: filterByGeoFilter('attendances').geoFilterAggregate,
    })
    .where(...prixApiFiltersAttendance)
    .order(descending('count'))

  return {
    publicQuery,
    attendanceQuery,
  }
}

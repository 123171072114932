import { entity, query, count, distinct, equals, truthy, string, number, isNull, descending } from '~/prix'

export default function mostUsedCitiesQuery({
  stateAbbreviation,
  stateId,
  dataSourceId,
}: {
  stateAbbreviation?: string,
  stateId?: string,
  dataSourceId: number,
}) {
  return query('legalEntityGeoprocessing')
    .select({
      geoId: entity('legalEntityGeoprocessing').property('cityId'),
      geoName: entity('city').property('name'),
      totalLegalEntity: count(distinct(entity('legalEntityGeoprocessing').property('legalEntityId'))),
      totalAttendances: count(
        distinct(
          equals(
            entity(
              `legalEntityAttendanceLastYear`,
            ).property(`dataSourceId`),
            number().value(dataSourceId),
            entity('legalEntityGeoprocessing').property('legalEntityId'),
          ),
        ),
      ),
    })
    .join({
      current: entity('legalEntityGeoprocessing').property('legalEntityId'),
      target: entity('legalEntity').property('id'),
      join: 'inner',
    })
    .join({
      current: entity('legalEntityGeoprocessing').property('legalEntityId'),
      target: entity('legalEntityAttendanceLastYear').property('legalEntityId'),
      join: 'left',
    })
    .join({
      current: entity('legalEntityGeoprocessing').property('legalEntityId'),
      target: entity('legalEntityAttendance').property('legalEntityId'),
      join: 'left',
    })
    .join({
      current: entity('legalEntityGeoprocessing').property('stateId'),
      target: entity('state').property('id'),
      join: 'inner',
    })
    .join({
      current: entity('legalEntityGeoprocessing').property('cityId'),
      target: entity('city').property('id'),
      join: 'inner',
    })
    .where(
      ...[
        isNull(entity('legalEntity').property('deletedAt')),
        stateAbbreviation && stateAbbreviation !== undefined
          ? equals(entity('state').property('abbreviation'), string().value(stateAbbreviation))
          : null,

        stateId && stateId !== undefined
          ? equals(entity('state').property('id'), string().value(stateId))
          : null,
      ].filter(truthy),
    )
    .order(descending('totalAttendances'))
}
import { boolean, defineEntity, entity, foreign, number, object, string } from '~/prix'

const disproportionateAttentionPublicEntity = defineEntity({
  key: 'disproportionateAttentionAttendances',
  title: 'Atendimentos para atendimento desproportcional',
  alternativeTitles: ['Atendimentos de empresas por cnae'],
  sources: ['olap','oltp'],
  schema: object({
    cnaeId: foreign(entity('cnae').property('id')).label('Código cnae'),
    cnaeDescription: string().label('Descrição de cnae'),
    count: number().label('Quantidade de empresas'),
    countryId: foreign(entity('country').property('id')).label('País do agrupamento'),
    macroRegionId: foreign(entity('macroRegion').property('id')).label(
      'Macrorregião do agrupamento',
    ),
    mesoRegionId: foreign(entity('mesoRegion').property('id')).label('Mesorregião do agrupamento'),
    microRegionId: foreign(entity('microRegion').property('id')).label(
      'Microrregião do agrupamento',
    ),
    stateId: foreign(entity('state').property('id')).label('Estado do agrupamento'),
    cityId: foreign(entity('city').property('id')).label('Cidade do agrupamento'),
    dataSourceId: foreign(entity('dataSource').property('id')),
    size: string().label('Porte da empresa'),
    isMei: boolean().label('Indica se a empresa é MEI'),
    hasLastYear: boolean().label(
      'Indica se o agrupamento representa os que foram atendidos no último ano.',
    ),
    hasLastMonth: boolean().label(
      'Indica se o agrupamento representa os que foram atendidos no último Mês.',
    ),
    courseProductId: number().label('Identificador prix do curso EaD.'),
    courseProductCode: string().label('Código EaD do curso realizado.'),
    isActivePortfolio: boolean().label('Indica se o o curso EaD está ativo no portfólio.'),
  }),
})

export default disproportionateAttentionPublicEntity

import React, { useCallback, useEffect, useRef, useState } from 'react'
import PulseLoader from 'react-spinners/PulseLoader'
import useMedia from 'react-use/lib/useMedia'
import styled from 'styled-components'
import AppLayout from '~/components/appLayout'
import GeoprocessingMapError from '~/components/geoprocessingMapError'
import MapIcon from '~/meta/map.svg'
import SatelliteIcon from '~/meta/satelite.svg'
import LegalEntityReadOne from '~/packages/legalEntity/read/legalEntityReadOne.component'
import { AppError, enumerated } from '~/prix'
import EnumeratedField from '~/prix/react/components/form/field/enumeratedField'
import LoadingQueriesMessage from '~/prix/react/components/loadingQueriesMessage'
import GoogleMaps from '~/prix/react/components/map/googleMaps'
import Modal from '~/prix/react/components/modal'
import {
  Breadcrumb,
  Filter,
  GeoChildrenLegalEntitiesPossibilities,
  GeoItem,
  GeoItemCategory,
  GeoLegalEntitiesPossibilities,
  geoLevelToGeoItem,
  Level,
} from './disproportionateAttentionMapLevels.data'
import MapMenu from './disproportionateAttentionMapMenu.component'
import Handle from '~/prix/react/components/handle'
import useLegalEntityGeoprocessingPolygonsMap from './disproportionateAttentionPolygonsMap.hook'
import BarChart from './barChart.component'
import DisproportionateAttentionGeoItemComponent from './disproportionateAttentionGeoItem.component'
import LeftArrowIcon from '~/meta/left-angle-arrow.svg'
import { OverlaySearchableMap } from '~/packages/legalEntityGeoprocessing/map/legalEntityGeoprocessingMap.screen'

export const levelKeyAux = {
  macroRegions: 'Macrorregião',
  states: 'Estado',
  mesoRegions: 'Mesorregião',
  microRegions: 'Microrregião',
  cities: 'Cidade',
  neighborhoods: 'Bairro',
  streets: 'Rua',
}

const Wrapper = styled.div`
  display: flex;
  flex: 1;

  .gm-style iframe + div {
    border: none !important;
  }
`

const MapLevelFieldOverlay = styled.div`
  position: absolute;
  top: 10px;
  margin-left: 10px;
  width: 300px;
  height: 55px;

  input {
    height: 27.5px;
  }

  @media (max-width: 1100px) {
    width: 40%;
  }

  @media (max-width: 768px) {
    width: 40%;
  }

  @media (max-width: 360px) {
    width: 175px;
  }
`

const MapTypeOverlayFirstGroup = styled.div`
  position: absolute;
  display: flex;
  margin-left: 10px;
  flex-direction: row;
  bottom: 85px;

  .button {
    display: flex;
    align-items: center;
    justify-content: center;
    border: transparent;
    cursor: pointer;
    padding: 4px 4px 0px 4px;
  }

  .svg-opacity {
    opacity: 0.5;
  }

  .border-left {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }

  .border-right {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  }
`

const TitleWrapper = styled.div`
  display: inline-flex;
  flex-flow: row nowrap;
  max-width: 100%;
  span {
    font-size: 1.4rem;
    flex: 1;
    white-space: pre;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: bolder;
  }
`

export const LegalEntityListTitle = styled.span`
  padding: 0.75em 0.75em;
  text-align: center;
  color: #414141;
`

const OverlayLoading = styled.div`
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 94, 184, 0.6);
  backdrop-filter: blur(5px);
  z-index: 1;
  align-items: center;
  text-align: center;

  .center {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    .message {
      margin-top: 10px;
      color: #fff;
      font-weight: bold;
      text-shadow: 0 5px 5px rgba(0, 0, 0, 0.05);
    }
  }

  .center-error {
    border-radius: 4px;
    position: absolute;
    top: 50%;
    left: 50%;
    padding: 30px 30px 20px 30px;
    background-color: #fff;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    cursor: default;

    .row {
      display: flex;
      flex-direction: row;
    }

    .column {
      display: flex;
      flex-direction: column;
      margin-right: 5px;
    }

    .button {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 15px;
    }
  }
`

const EntitiesSection = styled.div`
  display: flex;
  flex-direction: column;
  margin: 10px;

  height: 100%;
  overflow-y: scroll;
  border-radius: 12px;

  &::-webkit-scrollbar {
    width: 8px;
    margin: 0;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #888;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: #555;
  }
`

const CardEntitiesList = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
`

const CardEntitiesListTitle = styled.div`
  display: flex;
  background: var(--p-action, #0f438a);
  padding: 0 25px;
  width: 100%;
  gap: 10px;

  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  button {
    height: 3rem;
    width: 3rem;
    padding: 8px;
    align-items: center;
    gap: 4px;
    flex-shrink: 0;

    border: none;
    border-radius: 100px;
    background: #005eb8;
    cursor: pointer;

    svg {
      margin-top: 2px;
      fill: #fff;
    }
  }

  h3 {
    color: #fff;
    font-weight: 400;
    line-height: 23.3px;
    font-size: 2rem;
  }

  div {
    min-width: 3rem;
  }
`

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px 10px;
`
const AnalisysButton = styled.button`
  display: flex;
  padding: 10px 15px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  flex-shrink: 0;
  cursor: pointer;
  border: none;
  font-size: 16px;

  border-radius: 4px;
  background: #005eb8;
  color: #fff;
  box-shadow: 0px 1px 4px 0px rgba(20, 46, 82, 0.2);

  &:hover {
    background: #0f438a;
    color: #fff;
    transition: 0.1s;
  }
`

const AttentionList = styled.div`
  padding: 0 17px 30px 17px;
`

export interface disproportionateAttentionMapProps {
  mapTypeId: 'satellite' | 'map'
  onMapTypeIdChange: (mapTypeId: 'satellite' | 'map') => void
  selectedLegalEntityId: string | null
  filter: Filter | null
  onFilterChange: (filter: Filter | null) => void
  level: Level | null
  onLevelChange: (level: Level) => void
  onChildrenGeoLevelChange: (childrenGeoLevel: GeoChildrenLegalEntitiesPossibilities) => void
  breadcrumbs: Breadcrumb[]
  title?: string
  markerVariationForValue: (value: string) => void
  markerVariationLoading: boolean
  userGeoState: any
  hasUserRoles: boolean
  error?: AppError
}

export default function disproportionateAttentionMap({
  mapTypeId,
  onMapTypeIdChange,
  selectedLegalEntityId,
  breadcrumbs,
  level,
  onLevelChange,
  filter,
  onFilterChange,
  title,
  markerVariationForValue,
  markerVariationLoading,
  userGeoState,
  error,
}: disproportionateAttentionMapProps) {
  const anyPointerCoarse = useMedia('(any-pointer: coarse)')
  const hoverNone = useMedia('(hover: none)')
  const isTouchable = anyPointerCoarse || hoverNone
  const isMobile = useMedia('(max-width: 768px)')
  const map = useRef<google.maps.Map | null>(null)

  const [selectedGeoId, setSelectedGeoId] = useState<string | null>(null)
  const [selectedGeo, setSelectedGeoItems] = useState<{
    id: string
    name: string
    levelKey: string
    geoLevel: string
    topTenMostUnderated: Array<GeoItemCategory>
  } | null>(null)
  const [mouseOnCenter, setMouseOnCenter] = useState<boolean>(false)

  const result = useLegalEntityGeoprocessingPolygonsMap({
    filter,
    by: level
      ? (`${level.geo}Id` as unknown as
        | 'stateId'
        | 'countryId'
        | 'countryIsoCode'
        | 'macroRegionId'
        | 'mesoRegionId'
        | 'microRegionId'
        | 'cityId')
      : 'stateId',
    childrenGeoLevel: level
      ? (level.childrenGeoLevel as
        | 'macroRegions'
        | 'states'
        | 'mesoRegions'
        | 'microRegions'
        | 'cities'
        | 'neighborhoods')
      : 'states',
    id: level?.id || '30',
    isEnabled: true,
  })

  useEffect(() => {
    if (!result.bounds || !map.current) {
      return
    }
    map.current.fitBounds(result.bounds)
  }, [map.current, result.bounds])

  useEffect(() => {
    if (isTouchable) {
      handleDispose()
    }
  }, [isTouchable, level?.geo, level?.childrenGeoLevel])

  const handleClickGeoItemButton = useCallback(
    (
      item: Partial<GeoItem> & {
        id: string
        name: string
        levelKey: GeoLegalEntitiesPossibilities
        geoLevel: GeoChildrenLegalEntitiesPossibilities
        topTenCategories: GeoItemCategory[]
      },
    ) => {
      setSelectedGeoItems({
        id: item.id,
        name: item.name,
        levelKey: item.levelKey,
        geoLevel: item.geoLevel,
        topTenMostUnderated: item.topTenCategories,
      })
    },
    [onLevelChange],
  )

  const handleClickNextLevelButton = useCallback(
    (
      item: Partial<GeoItem> & {
        id: string
        levelKey: string
      },
    ) => {
      const nextGeo = geoLevelToGeoItem[item.levelKey] as GeoLegalEntitiesPossibilities | null

      if (!nextGeo) {
        // item.clusterItems.sort((a, b) => ('' + a.name).localeCompare(b.name))
        return
      }

      const nextLevel = {
        geo: nextGeo,
        id: String(item.id),
        childrenGeoLevel: getInnerGeoChildren(nextGeo),
      }
      onLevelChange(nextLevel)
      setSelectedGeoItems(null)
    },
    [onLevelChange],
  )

  const handleClick = useCallback(
    (item: GeoItem) => {
      if (item.topTenCategories.length > 0) {
        if (isTouchable) {
          setSelectedGeoId(old => (old !== item.id ? item.id : null))
          return
        }
        handleClickGeoItemButton(item as any)
      }
    },
    [handleClickGeoItemButton, isTouchable],
  )

  const handleMouseOver = useCallback(
    (item: GeoItem) => {
      if (!isTouchable) {
        setSelectedGeoId(item.id)
      }
    },
    [isTouchable],
  )

  const handleMouseCenter = useCallback(
    (value: boolean) => {
      if (!isTouchable) {
        setMouseOnCenter(value)
      }
    },
    [isTouchable],
  )

  const handleMouseOut = useCallback(
    (item: GeoItem) => {
      if (!isTouchable) {
        setSelectedGeoId(null)
      }
    },
    [isTouchable],
  )

  const handleDispose = useCallback(() => {
    setSelectedGeoId(null)
    setSelectedGeoItems(null)
  }, [])
  const handleBreadcrumbChangeLevel = useCallback(
    (newLevelKey: GeoLegalEntitiesPossibilities) => {
      const breadcrumb = breadcrumbs.find(
        breadcrumb => `${breadcrumb.geo}.${breadcrumb.id}` === newLevelKey,
      )
      if (!breadcrumb) {
        return
      }
      onLevelChange({
        ...breadcrumb,
        childrenGeoLevel: getInnerGeoChildren(breadcrumb.geo || 'states'),
      })
      setSelectedGeoItems(null)
    },
    [onLevelChange, breadcrumbs],
  )

  const [menuVisibility, setMenuVisibility] = useState(false)
  const toggleMenuVisibility = () => setMenuVisibility(!menuVisibility)

  const [isBreadcrumbFocused, setIsBreadcrumbFocused] = useState(false)
  const breadcrumbInput = document.querySelector('input[id="react-select-2-input"]')

  breadcrumbInput?.addEventListener('focusin', () => {
    setIsBreadcrumbFocused(true)
  })

  breadcrumbInput?.addEventListener('focusout', () => {
    setIsBreadcrumbFocused(false)
  })

  function handleClickViewForAll() {
    setSelectedGeoItems({
      geoLevel: 'country',
      id: '30',
      levelKey: 'country',
      name: 'Brasil',
      topTenMostUnderated: result.allResults || [],
    })
  }

  return (
    <AppLayout
      title={title}
      initialMenuVisibility={menuVisibility}
      dockActive='map'
      menu={() => (
        <MapMenu
          onChangeFilter={onFilterChange}
          filter={filter}
          isLoading={result.isLoading}
          loadingMessage={result.queries}
          toggleMenuVisibility={toggleMenuVisibility}
          error={error}
          toggleViewForAll={level?.geo === 'country' ? handleClickViewForAll : null}
        />
      )}
      card={{
        title: isMobile ? '' : 'Atendimento desproporcional',
      }}
    >
      <Wrapper>
        <Handle error={error}>
          {result.isLoading ? (
            <OverlayLoading>
              <div className='center'>
                <PulseLoader color={'#fff'} />
                <span className='message'>
                  <LoadingQueriesMessage queries={result.queries} />
                </span>
              </div>
            </OverlayLoading>
          ) : null}
          {result.error ? <GeoprocessingMapError error={result.error} tryAgain={true} /> : null}

          {Number(filter?.attendanceSource) === 5 &&
            filter?.eadOption === 'individual' &&
            !filter?.courseProductCode ? (
            <OverlaySearchableMap>
              <div className='center'>
                <span>Selecione um curso</span>
              </div>
            </OverlaySearchableMap>
          ) : null}
          <GoogleMaps ref={map} mapTypeId={mapTypeId}>
            {userGeoState.isLoading === false
              ? result.items?.map((item, index) => (
                <DisproportionateAttentionGeoItemComponent
                  {...item}
                  subtitle={item.legendValues}
                  key={String(index)}
                  isSelected={selectedGeoId === item.id}
                  isTouchable={isTouchable}
                  onClick={handleClick}
                  handleClickMobile={handleClickGeoItemButton}
                  onMouseOver={handleMouseOver}
                  onMouseOut={handleMouseOut}
                  onMouseCenter={handleMouseCenter}
                  isMouseOnCenter={mouseOnCenter}
                  onDispose={handleDispose}
                  markerVariationForValue={markerVariationForValue}
                  markerVariationLoading={markerVariationLoading}
                  mapVisualization={{
                    type: mapTypeId,
                    visualizationType: 'chloroplethic',
                  }}
                />
              ))
              : null}
          </GoogleMaps>

          <MapLevelFieldOverlay>
            <EnumeratedField
              fieldSchema={enumerated(
                breadcrumbs.reduce(
                  (acc, breadcrumb) => ({
                    ...acc,
                    [`${breadcrumb.geo}.${breadcrumb.id}`]: breadcrumb.subTitle
                      ? `${breadcrumb.title} (${breadcrumb.subTitle})`
                      : breadcrumb.title,
                  }),
                  {} as { [key: string]: string },
                ),
              ).isRequired()}
              value={level ? `${level.geo}.${level.id}` : ''}
              onDirectChange={handleBreadcrumbChangeLevel}
            />
          </MapLevelFieldOverlay>

          {isBreadcrumbFocused && window.innerHeight < 300 ? null : (
            <>
              <MapTypeOverlayFirstGroup>
                <button
                  title='Mapa'
                  className='button border-left'
                  onClick={() => onMapTypeIdChange('map')}
                >
                  <div className={mapTypeId !== 'map' ? 'svg-opacity' : ''}>
                    <MapIcon width={35} height={35} />
                  </div>
                </button>

                <button
                  title='Satélite'
                  className='button border-right'
                  onClick={() => onMapTypeIdChange('satellite')}
                >
                  <div className={mapTypeId !== 'satellite' ? 'svg-opacity' : ''}>
                    <SatelliteIcon width={35} height={35} />
                  </div>
                </button>
              </MapTypeOverlayFirstGroup>
            </>
          )}

          <Modal
            isOpen={!!selectedGeo}
            onClose={() => {
              setSelectedGeoItems(null)
            }}
            hideCloseButton={true}
            modalStyle='halfScreen'
            customStyles={{
              content: {
                maxWidth: '100%',
                background: 'transparent',
              },
            }}
          >
            <EntitiesSection>
              <CardEntitiesList>
                <CardEntitiesListTitle>
                  <button
                    onClick={() => {
                      setSelectedGeoItems(null)
                    }}
                  >
                    <LeftArrowIcon />
                  </button>
                  <h3>{selectedGeo?.name}</h3>
                  <div></div>
                </CardEntitiesListTitle>
              </CardEntitiesList>
              <div style={{ background: '#FFF' }}>
                {selectedGeo?.levelKey !== 'cities' && selectedGeo?.geoLevel !== 'country' ? (
                  <ButtonWrapper>
                    <AnalisysButton
                      style={{ marginBottom: 0, maxHeight: '40px' }}
                      onClick={(event: { preventDefault: () => void }) => {
                        event.preventDefault()
                        handleClickNextLevelButton({
                          id: selectedGeo?.id || '30',
                          levelKey: selectedGeo
                            ? (selectedGeo.levelKey as GeoChildrenLegalEntitiesPossibilities)
                            : 'states',
                        })
                      }}
                    >
                      Analisar cidades
                    </AnalisysButton>
                  </ButtonWrapper>
                ) : null}
                <AttentionList>
                  {selectedGeo?.topTenMostUnderated?.map((underatedCategory, index) => (
                    <BarChart
                      title={`${underatedCategory.cnaeDescription} (${underatedCategory.cnaeId})`}
                      key={index}
                      buttonProps={{
                        onClick: event => {
                          event.preventDefault()
                          const url = `/app/map/${selectedGeo.geoLevel}/${selectedGeo.id
                            }?definedOption=customized&filterType=cnaes&filterName=${underatedCategory.cnaeDescription.replace(
                              /( )/g,
                              '+',
                            )}&filterValue=${underatedCategory.cnaeId}`

                          location.replace(url)
                        },
                        title: 'Buscar empresas',
                      }}
                      items={[
                        {
                          title: 'Dentre todas as empresas',
                          percentage: underatedCategory.publicRate,
                        },
                        {
                          title: 'Dentre as empresas atendidas pelo SEBRAE',
                          percentage: underatedCategory.attendanceRate,
                        },
                      ]}
                    />
                  ))}
                </AttentionList>
              </div>
            </EntitiesSection>
          </Modal>
          <Modal
            isOpen={!!selectedLegalEntityId}
            onClose={() => onLevelChange(level ?? { geo: 'country', id: null })}
          >
            {selectedLegalEntityId ? <LegalEntityReadOne id={selectedLegalEntityId} /> : null}
          </Modal>
        </Handle>
      </Wrapper>
    </AppLayout>
  )
}

/**
 * Escolher automaticamente um agrupamento por children a partir da chave parente.
 * @param key Chave do nível atual.
 * @returns Agrupamento de geo.
 */

function getInnerGeoChildren(
  key: GeoLegalEntitiesPossibilities,
): GeoChildrenLegalEntitiesPossibilities {
  switch (key) {
    case 'state':
      return 'cities'
    case 'city':
      return 'neighborhoods'
    case 'country':
      return 'states'
    case 'mesoRegion':
      return 'cities'
    case 'microRegion':
      return 'cities'
    default:
      return 'states'
  }
}

import { defineEntity, entity, foreign, number, object, string } from '~/prix'
import { point } from '~/prix/types/geoJson'

const legalEntityAttendanceMonthlyEntity = defineEntity({
  key: 'legalEntityAttendanceLastYear',
  title: 'Atendimentos no último ano por empresas',
  alternativeTitles: ['Atendimentos dos agentes no último ano por empresa'],
  sources: ['olap','oltp'],
  schema: object({
    legal_entity_id: foreign(entity('legalEntity').property('id')).label(
      'Entidade que recebeu o atendimento',
    ),
    tradeName: string().label('Nome fantasia', 'Nome público', 'Nome'),
    corporateName: string().isOptional().label('Razão social', 'Nome de registro', 'Nome'),
    cnpj: string().label('CNPJ'),
    pointOnStreet: point().label('Coordenadas de rua'),
    pointOnStreetDirectionAngle: number().label('Ângulo de direcionamento das coordenadas de rua'),
    attendances: number().label('Quantidade de atendimentos no período'),
  }),
})

export default legalEntityAttendanceMonthlyEntity

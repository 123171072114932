import { entity, equals, query, string, max, min, isNull, lessOrEqual, date } from '~/prix'

export default function eachSourceAttendanceDates() {
  return (
    // Querying Radar ALI
    query('legalEntityAttendance')
      .select({
        dataSourceId: entity('legalEntityAttendanceDataSource').property('dataSourceId'),
        dataSourceName: entity('dataSource').property('name'),
        maxAttendanceDate: max(entity('legalEntityAttendance').property('startDate')),
        minAttendanceDate: min(entity('legalEntityAttendance').property('startDate')),
      })
      .join({
        current: entity('legalEntityAttendance').property('id'),
        target: entity('legalEntityAttendanceDataSource').property('legalEntityAttendanceId'),
        join: 'inner',
      })
      .join({
        current: entity('legalEntityAttendanceDataSource').property('dataSourceId'),
        target: entity('dataSource').property('id'),
        join: 'inner',
      })
      .where(
        equals(
          entity('legalEntityAttendanceDataSource').property('dataSourceId'),
          string().value('3'),
        ),
      )
      // Querying BrasilMais
      .union(
        query('legalEntityAttendance')
          .select({
            dataSourceId: entity('legalEntityAttendanceDataSource').property('dataSourceId'),
            dataSourceName: entity('dataSource').property('name'),
            maxAttendanceDate: max(entity('legalEntityAttendance').property('startDate')),
            minAttendanceDate: min(entity('legalEntityAttendance').property('startDate')),
          })
          .join({
            current: entity('legalEntityAttendance').property('id'),
            target: entity('legalEntityAttendanceDataSource').property('legalEntityAttendanceId'),
            join: 'inner',
          })
          .join({
            current: entity('legalEntityAttendanceDataSource').property('dataSourceId'),
            target: entity('dataSource').property('id'),
            join: 'inner',
          })
          .where(
            equals(
              entity('legalEntityAttendanceDataSource').property('dataSourceId'),
              string().value('4'),
            ),
          ),
        // Querying EAD
        query('legalEntityAttendance')
          .select({
            dataSourceId: entity('legalEntityAttendanceDataSource').property('dataSourceId'),
            dataSourceName: entity('dataSource').property('name'),
            maxAttendanceDate: max(entity('legalEntityAttendance').property('startDate')),
            minAttendanceDate: min(entity('legalEntityAttendance').property('startDate')),
          })
          .join({
            current: entity('legalEntityAttendance').property('id'),
            target: entity('legalEntityAttendanceDataSource').property('legalEntityAttendanceId'),
            join: 'inner',
          })
          .join({
            current: entity('legalEntityAttendanceDataSource').property('dataSourceId'),
            target: entity('dataSource').property('id'),
            join: 'inner',
          })
          .where(
            equals(
              entity('legalEntityAttendanceDataSource').property('dataSourceId'),
              string().value('5'),
            ),
            lessOrEqual(
              entity('legalEntityAttendance').property('startDate'),
              date().value(
                `${new Date().getFullYear()}-${new Date().getMonth() + 1}-${new Date().getDate()}`,
              ),
            ),
          ),
        // Querying AppSebrae
        query('legalEntityAttendance')
          .select({
            dataSourceId: entity('legalEntityAttendanceDataSource').property('dataSourceId'),
            dataSourceName: entity('dataSource').property('name'),
            maxAttendanceDate: max(entity('legalEntityAttendance').property('startDate')),
            minAttendanceDate: min(entity('legalEntityAttendance').property('startDate')),
          })
          .join({
            current: entity('legalEntityAttendance').property('id'),
            target: entity('legalEntityAttendanceDataSource').property('legalEntityAttendanceId'),
            join: 'inner',
          })
          .join({
            current: entity('legalEntityAttendanceDataSource').property('dataSourceId'),
            target: entity('dataSource').property('id'),
            join: 'inner',
          })
          .where(
            equals(
              entity('legalEntityAttendanceDataSource').property('dataSourceId'),
              string().value('6'),
            ),
          ),
      )
  )
}

import {
  count,
  entity,
  equals,
  query,
  string,
  truthy,
  number,
  distinct,
  boolean,
  isNull,
} from '~/prix'
import { FilterHighlight } from '../legalEntityGeoprocessingMapLevels.data'
import { LegalEntityAttendanceQueryProps } from './legalEntityAttendancePortfolioEadIndex.query'

export default function legalEntityAttendancePortfolioEadQuery({
  groupColumn,
  idColumn,
  id,
  filter: filterIncoming,
}: LegalEntityAttendanceQueryProps) {
  let filter: FilterHighlight = {
    type: 'attendance',
    value: 'lastYear',
  }
  if (filterIncoming) {
    filter = filterIncoming
  }

  return query('legalEntityGeoprocessing')
    .select({
      geoId: entity('legalEntityGeoprocessing').property(groupColumn),
      count: count(distinct(entity('legalEntityGeoprocessing').property('legalEntityId'))),
    })
    .join({
      current: entity('legalEntityGeoprocessing').property('legalEntityId'),
      target: entity('legalEntity').property('id'),
      join: 'inner',
    })
    [
      filter == null || (filter?.type === 'attendance' && filter?.value === 'lastMonth')
        ? 'join'
        : 'dummy'
    ]({
      current: entity('legalEntityGeoprocessing').property('legalEntityId'),
      target: entity(`legalEntityAttendanceLastMonth`).property('legalEntityId'),
      join: 'left',
    })
    [
      filter == null || (filter?.type === 'attendance' && filter?.value === 'lastYear')
        ? 'join'
        : 'dummy'
    ]({
      current: entity('legalEntityGeoprocessing').property('legalEntityId'),
      target: entity(`legalEntityAttendanceLastYear`).property('legalEntityId'),
      join: 'left',
    })
    .join({
      current: entity('legalEntityGeoprocessing').property('streetId'),
      target: entity('street').property('id'),
      join: 'left',
    })
    [idColumn === 'neighborhoodId' ? 'join' : 'dummy']({
      current: entity('street').property('id'),
      target: entity('neighborhoodStreetIntersection').property('streetId'),
      join: 'inner',
    })
    .join({
      current: entity('legalEntityGeoprocessing').property('countryId'),
      target: entity('country').property('id'),
      join: 'inner',
    })
    .join({
      current: entity('legalEntityGeoprocessing').property('stateId'),
      target: entity('state').property('id'),
      join: 'inner',
    })
    .join({
      current: entity('legalEntityGeoprocessing').property('cityId'),
      target: entity('city').property('id'),
      join: 'inner',
    })
    .where(
      ...[
        // equals(entity('legalEntity').property('registrationStatus'), string().value('active')),
        isNull(entity('legalEntity').property('deletedAt')),
        idColumn !== null && id !== null && idColumn === 'neighborhoodId'
          ? equals(entity('neighborhoodStreetIntersection').property(idColumn), string().value(id))
          : null,

        idColumn !== null &&
        id !== null &&
        idColumn !== 'countryIsoCode' &&
        idColumn !== 'neighborhoodId'
          ? equals(entity('legalEntityGeoprocessing').property(idColumn), string().value(id))
          : null,

        idColumn === 'countryIsoCode'
          ? equals(entity('country').property('iso'), number().value(Number(id!)))
          : null,

        equals(
          entity(
            `legalEntityAttendance${filter?.value === 'lastYear' ? 'LastYear' : 'LastMonth'}`,
          ).property(`dataSourceId`),
          number().value(Number(5)),
        ),

        equals(
          entity(
            `legalEntityAttendance${filter?.value === 'lastYear' ? 'LastYear' : 'LastMonth'}`,
          ).property('isActivePortfolio'),
          boolean().value(true),
        ),
      ].filter(truthy),
    )
    .limit(10000)
}

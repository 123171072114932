import React, { useState } from 'react'
import { colors } from '~/design'
import styled from 'styled-components'
import useMedia from 'react-use/lib/useMedia'
import ArrowUpIcon from '~/components/icons/arrows/48px_triangle-up-19.svg'
import ArrowDownIcon from '~/components/icons/arrows/48px_triangle-down-20.svg'
import ClipLoader from 'react-spinners/ClipLoader'

const Header = styled.div`
  padding-left: 70px;
  background: ${colors.sebraeBlue};

  .top-card {
    background: ${colors.sebraeBlue};
    display: flex;
    padding: 5px 0;
    align-items: center;
    justify-content: space-between;
    height: 70px;

    @media (max-width: 768px) {
      height: 100px;
    }

    @media (max-width: 768px) {
      justify-content: center;
    }

    @media (max-width: 400px) {
      height: 150px;
    }
  }

  .top-card-info {
    margin-left: 48px;
    display: flex;
    flex-direction: column;
    line-height: normal;
    color: #fff;
    justify-content: center;
    margin-bottom: 4px;

    h1 {
      font-size: 24px;
      font-weight: 400;
      margin: 0;
    }

    @media (max-width: 768px) {
      margin-left: 0px;
    }
  }

  .entities-data-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: right;
    align-items: center;
    gap: 48px;
    padding-right: 38px;

    .container {
      display: flex;
      flex-direction: column;

      .description {
        color: #58c4da;
        text-align: right;
        font-size: 13px;
        font-weight: 400;
      }

      .value {
        color: #fff;
        font-size: 18px;
        font-style: italic;
        font-weight: 700;
        text-align: right;
        min-width: 150px;
      }
    }
  }

  @media (max-width: 768px) {
    left: 0px;
    width: 100%;
    z-index: 0;
    padding-left: 0px;
  }
`

const BackWrapper = styled.div`
  width: 50%;
  display: flex;
  justify-content: end;
  padding-right: 10rem;
`

const BackButton = styled.button`
  width: fit-content;
  height: fit-content;

  border: none;
  background-color: transparent;
  cursor: pointer;

  color: #58c4da;
  font-size: 16px;
  font-weight: 700;
  line-height: 23.3px;
`

const DashboardTabsWrapper = styled.div`
  display: flex;
  background: ${colors.sebraeBlue};
  height: 42px;
  padding: 0 42px;
  align-items: flex-end;

  .active {
    background: #0f438a;
    color: #fff;
    font-size: 18px;
    line-height: 19px;
  }
`

const Tab = styled.div`
  display: flex;
  max-width: 220px;
  align-items: center;
  justify-content: center;
  height: 58px;
  width: 100%;
  padding: 8px 14px;
  margin: 0px 1px 0px 1px;

  @media (max-width: 900px) {
    padding: 0px;
  }

  color: #f0f6fe;
  text-align: center;
  font-size: 18px;
  font-weight: 400;
  line-height: 19px;

  border-radius: 12px 12px 0px 0px;
  cursor: pointer;

  .row {
    display: flex;
    flex: 1;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }

  .column-text {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* margin-left: 20px; */
  }

  .column-icon {
    flex-direction: column;
    margin: auto;
  }

  :hover {
    background: rgba(15, 67, 138, 0.4);
    color: #fff;
    font-size: 18px;
    line-height: 19px;
    transition: 0.3s;
  }
`

export interface ContentProps {
  content: {
    height?: string
    display?: string
  }
}

export default function TopCard({
  title,
  outOfRangeEntities,
  totalEntities,
  onToggleMenu,
  selectedLegalEntity,
  customStyles,
  dataSource,
  setDataSource,
  tabIndicators,
  isLoadingIndicatorsData,
}: {
  title?: string
  outOfRangeEntities?: number | string
  totalEntities?: number | string
  onToggleMenu?: any
  selectedLegalEntity?: {
    selectedLegalEntityId: string
    selectedLegalEntityName: string
  }
  customStyles?: ContentProps
  dataSource?: string
  setDataSource?: React.Dispatch<React.SetStateAction<string>>
  tabIndicators?: any
  isLoadingIndicatorsData?: boolean
}) {
  const isMobile = useMedia('(max-width: 768px)')
  const isMobileLarge = useMedia('(max-width: 1024px)')
  const isTagsCondition = useMedia('(max-width: 1120px)')
  const [activeTab, setActiveTab] = useState(dataSource)

  const handleTabClick = (dataSource: string) => {
    if (setDataSource) {
      setDataSource(dataSource)
      setActiveTab(dataSource)
    }
  }

  const renderIndicatorIcon = (
    condition: boolean,
    percentage: number,
    upColor: string | undefined,
    downColor: string | undefined,
  ) => {
    if (isLoadingIndicatorsData) {
      return (
        <div style={{ display: 'flex' }}>
          <ClipLoader color={'#fff'} size={18} />
        </div>
      )
    } else if (isLoadingIndicatorsData === false && condition === true && percentage !== 0) {
      return (
        <div style={{ display: 'flex', marginLeft: '5px' }}>
          <ArrowUpIcon height={18} width={18} fill={upColor} />
        </div>
      )
    } else if (isLoadingIndicatorsData === false && condition === false && percentage !== 0) {
      return (
        <div style={{ display: 'flex', marginLeft: '5px' }}>
          <ArrowDownIcon height={18} width={18} fill={downColor} />
        </div>
      )
    } else {
      return null
    }
  }

  const renderRadarAliIndicator = renderIndicatorIcon(
    tabIndicators?.radarAli.condition,
    tabIndicators?.radarAli.percentage,
    '#14EBAC',
    '#EB1453',
  )

  const renderEadIndicator = renderIndicatorIcon(
    tabIndicators?.ead.condition,
    tabIndicators?.ead.percentage,
    '#14EBAC',
    '#EB1453',
  )

  const renderPortfolioIndicator = renderIndicatorIcon(
    tabIndicators?.portfolio.condition,
    tabIndicators?.portfolio.percentage,
    '#14EBAC',
    '#EB1453',
  )

  const renderAppSebraeIndicator = renderIndicatorIcon(
    tabIndicators?.appSebrae.condition,
    tabIndicators?.appSebrae.percentage,
    '#14EBAC',
    '#EB1453',
  )

  return title || outOfRangeEntities || totalEntities || onToggleMenu || selectedLegalEntity ? (
    <Header>
      <div className='top-card' style={customStyles ? customStyles.content : undefined}>
        <div className='top-card-info'>
          <h1>{title}</h1>
        </div>

        {!isMobileLarge && !selectedLegalEntity && (
          <div
            className='entities-data-wrapper'
            style={!outOfRangeEntities ? { width: '40%' } : {}}
          >
            {outOfRangeEntities ? (
              <div className='container'>
                <span className='description'>Total de empresas fora dos limites da seleção</span>
                <span className='value'>
                  {totalEntities === 'Carregando...' ? 'Carregando...' : outOfRangeEntities}
                </span>
              </div>
            ) : null}

            {totalEntities && (
              <div className='container'>
                <span className='description'>Total da seleção</span>
                <span className='value'>
                  {outOfRangeEntities === 'Carregando...' ? 'Carregando...' : totalEntities}
                </span>
              </div>
            )}
          </div>
        )}

        {selectedLegalEntity && (
          <BackWrapper>
            <BackButton onClick={onToggleMenu}>{'<'} Voltar a página anterior</BackButton>
          </BackWrapper>
        )}
      </div>

      {location.pathname === '/app' && !isMobile && (
        <DashboardTabsWrapper>
          <Tab
            style={activeTab === 'Geral' ? { backgroundColor: '#0f438a' } : {}}
            className={activeTab === 'Geral' ? 'active' : ''}
            onClick={() => handleTabClick('Geral')}
          >
            Geral
          </Tab>
          <Tab
            style={
              activeTab === 'SEBRAE Na Sua Empresa (App)' ? { backgroundColor: '#0f438a' } : {}
            }
            className={activeTab === 'SEBRAE Na Sua Empresa (App)' ? 'active' : ''}
            onClick={() => handleTabClick('SEBRAE Na Sua Empresa (App)')}
          >
            <div className='row'>
              {/* <div className='column-text'>SEBRAE Na Sua Empresa (App)</div> */}
              <div className='column-text'>SEBRAE Na Sua Empresa (App)</div>
              <div className='column-icon'>{renderAppSebraeIndicator}</div>
            </div>
          </Tab>
          <Tab
            style={activeTab === 'Portfólio Ead' ? { backgroundColor: '#0f438a' } : {}}
            className={activeTab === 'Portfólio Ead' ? 'active' : ''}
            onClick={() => handleTabClick('Portfólio Ead')}
          >
            <div className='row'>
              <div className='column-text'>Portfólio EaD</div>
              <div className='column-icon'>{renderPortfolioIndicator}</div>
            </div>
          </Tab>
          <Tab
            style={activeTab === 'EaD' ? { backgroundColor: '#0f438a' } : {}}
            className={activeTab === 'EaD' ? 'active' : ''}
            onClick={() => handleTabClick('EaD')}
          >
            <div className='row'>
              <div className='column-text'>EaD</div>
              <div className='column-icon'>{renderEadIndicator}</div>
            </div>
          </Tab>
          <Tab
            style={activeTab === 'Radar ALI' ? { backgroundColor: '#0f438a' } : {}}
            className={activeTab === 'Radar ALI' ? 'active' : ''}
            onClick={() => handleTabClick('Radar ALI')}
          >
            <div className='row'>
              <div className='column-text'>Radar ALI</div>
              <div className='column-icon'>{renderRadarAliIndicator}</div>
            </div>
          </Tab>
        </DashboardTabsWrapper>
      )}
    </Header>
  ) : null
}

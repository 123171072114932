import React, { useMemo } from 'react'
import styled from 'styled-components'
import { contrastGradientColor } from '~/design'
import { formatAsBrNumber } from '~/prix/utils/types/number'
import { FilterHighlight } from '../legalEntityGeoprocessingMapLevels.data'
import { census, profits, scores } from '../menu/legalEntityGeoprocessingCustomizedMapMenu.component'

interface LegendContainerProps {
  isHeatmapEnabled: boolean
  maxValue: number | null
  minValue: number | null
  isLoadingLegend: boolean
  minPercentColor: number | null
  maxPercentColor: number | null
  highlight?: FilterHighlight | null
  level?: string
  suffix?: any
}

const LegendLabelBackground = styled.div`
  flex: 1;
  align-items: center;
  justify-content: center;
  padding-bottom: 10px;
  border-radius: 5px;
`

const Column = styled.div`
  .legend-container {
    margin: 16px 16px 0;
    height: 120px;

    div {
      display: flex;

      .column-background {
        width: 25px;
        flex-direction: column;
        align-items: left;
        margin-right: 10px;
      }

      .column {
        flex: 1;
        flex-direction: column;
        height: 65px;
      }

      .row {
        flex-direction: row;
        align-items: center;
        padding: 5px;

        .row-span {
          font-size: 14px;
          color: #363636;
          font-weight: normal;
          margin-bottom: 16px;
        }
      }

      .line {
        border: none;
      }

      .column-loading {
        flex-direction: row;
        align-items: center;
        padding: 5px;
        height: 65px;

        .loading-span {
          font-size: 14px;
          color: #363636;
          font-weight: normal;
        }
      }
    }
  }
`
export default function LegendContainer({
  isHeatmapEnabled,
  maxValue,
  minValue,
  isLoadingLegend,
  minPercentColor,
  maxPercentColor,
  highlight,
  level,
  suffix,
}: LegendContainerProps) {
  const isHighlightCensus =
    highlight && census.includes(highlight?.type) && highlight?.value === 'censusUpdated'

  const isHighlightProfits =
    highlight && profits.includes(highlight.type) && highlight?.value

  const isHighlightScores =
    highlight && scores.includes(highlight.type) && highlight?.value

  const isHighlightCensusAndProfits = isHighlightCensus || isHighlightProfits || isHighlightScores
  const legendAux = highlight && !isHighlightCensus && !isHighlightProfits && !isHighlightScores && level !== 'street' && level !== 'neighborhood' ? ` empresa${minValue !== 1 ? 's' : ''}` : highlight && (level === 'street' || level === 'neighborhood') ? '%' : highlight && isHighlightProfits && !isHighlightScores ? ' reais' : highlight && isHighlightScores ? ' de 5' : ` empresa${minValue !== 1 ? 's' : ''}`

  const areEqualValues =
    maxValue === minValue ||
    (maxValue === null && minValue === Infinity) ||
    (maxValue === -Infinity && minValue === null) ||
    (maxValue === null && (minValue == 0)) ||
    (maxValue === 0 && minValue === null)

  const colorResult = useMemo(() => {
    const minColor = contrastGradientColor(minPercentColor)
    const maxColor = contrastGradientColor(maxPercentColor)

    const heatmapColors = isHeatmapEnabled ? 'linear-gradient(#e74c3c, #ffd400, #2ecc71)' : null
    const notEqualsColors = isLoadingLegend || (minPercentColor === null || maxPercentColor === null && !areEqualValues) ? `linear-gradient(#0f438a, #3988c7, #aee8ff)` : null
    const equalColors = areEqualValues ? `linear-gradient(#aee8ff, #aee8ff)` : `linear-gradient(${maxColor.hex('rgb')}, ${minColor.hex('rgb')})`

    return heatmapColors ? heatmapColors : notEqualsColors ? notEqualsColors : equalColors
  }, [isHeatmapEnabled, isLoadingLegend, minPercentColor, maxPercentColor, areEqualValues])

  const maxValueResult = useMemo(() => {
    const heatmapValue = isHeatmapEnabled ? 'Mais empresas' : null
    const nullableHighlightValue = !maxValue === null && highlight && !isLoadingLegend && !isHighlightCensusAndProfits ? '0%' : null
    const nullableValue = maxValue === null && !isLoadingLegend ? '0' + ` ${legendAux}` : null
    const hasValue = maxValue ? `${formatAsBrNumber(maxValue)}${highlight?.value && !isHighlightCensusAndProfits && level !== 'street' && level !== 'neighborhood' ? '% das' : ''}` + `${legendAux}` : null
    const hasSuffix = !isHeatmapEnabled && maxValue && suffix ? `${formatAsBrNumber(maxValue)}${suffix ? suffix : ''}` : null

    return hasSuffix ? hasSuffix : heatmapValue ? heatmapValue : nullableHighlightValue ? nullableHighlightValue : nullableValue ? nullableValue : hasValue ? hasValue : null

  }, [isHeatmapEnabled, maxValue, highlight, isLoadingLegend, isHighlightCensusAndProfits, legendAux, level, suffix])

  const minValueResult = useMemo(() => {
    const heatmapValue = isHeatmapEnabled ? 'Menos empresas' : null
    const nullableHighlightValue = minValue === null && highlight && !isLoadingLegend && !isHighlightCensusAndProfits ? '0%' : null
    const nullableValue = minValue === null || minValue == Infinity ? '0' + ` ${legendAux}` : null
    const hasValue = minValue ? `${formatAsBrNumber(minValue)}${highlight?.value && !isHighlightCensusAndProfits ? '% das' : ''}` + ` ${legendAux}` : null
    const hasSuffix = !isHeatmapEnabled && minValue && suffix ? `${minValue == Infinity ? '0' : formatAsBrNumber(minValue)}${suffix ? suffix : ''}` : null

    return hasSuffix ? hasSuffix : heatmapValue ? heatmapValue : nullableHighlightValue ? nullableHighlightValue : nullableValue ? nullableValue : hasValue ? hasValue : null

  }, [isHeatmapEnabled, maxValue, highlight, isLoadingLegend, isHighlightCensusAndProfits, legendAux, suffix])


  return (
    <>
      {maxValue ?
        <Column>
          <div className='legend-container'>
            <label>Legenda</label>
            <div className='line-container-label'>
              <hr className='line' />
            </div>
            <div>
              <div className='column-background'>
                <LegendLabelBackground
                  style={{
                    background: colorResult
                  }}
                />
              </div>
              {isLoadingLegend ? (
                <div className='column-loading'>
                  <span className='loading-span'>{'Carregando...'}</span>
                </div>
              ) : areEqualValues ? (
                <div className='column-loading'>
                  {maxValueResult}
                </div>
              ) : null}
              {!areEqualValues && !isLoadingLegend && (
                <div className='column'>
                  <div className='row'>
                    <span className='row-span'>
                      {maxValueResult}
                    </span>
                  </div>
                  <div className='row'>
                    <span className='row-span'>
                      {minValueResult}
                    </span>
                  </div>
                </div>
              )}
            </div>
          </div>
        </Column>
        : null}
    </>
  )
}
import React, { useEffect, useMemo, useRef, useState } from 'react'
import PulseLoader from 'react-spinners/PulseLoader'
import useMedia from 'react-use/lib/useMedia'
import styled from 'styled-components'
import TopCard from '~/components/topCard'
import mapHeatmapQuery from '~/packages/legalEntityGeoprocessing/heatmap/mapHeatmap.query'
import legalEntityAttendanceAllCoursesEadQuery from '~/packages/legalEntityGeoprocessing/map/definedQueries/legalEntityAttendanceAllCoursesEad.query'
import legalEntityAttendanceAppSebraeQuery from '~/packages/legalEntityGeoprocessing/map/definedQueries/legalEntityAttendanceAppSebrae.query'
import legalEntityAttendancePortfolioEadQuery from '~/packages/legalEntityGeoprocessing/map/definedQueries/legalEntityAttendancePortfolioEad.query'
import legalEntityAttendanceRadarAliQuery from '~/packages/legalEntityGeoprocessing/map/definedQueries/legalEntityAttendanceRadarAli.query'
import { entity, equals, formatAsBrNumber, string, query } from '~/prix'
import { QueryBase } from '~/prix/query'
import Handle from '~/prix/react/components/handle'
import GeoJsonMultiPolygon from '~/prix/react/components/map/geoJsonMultiPolygon'
import GoogleMaps from '~/prix/react/components/map/googleMaps'
import useAPI from '~/prix/react/hooks/api'
import useGeolocation from '~/prix/react/hooks/geolocation'
import useGoogleMaps from '~/prix/react/hooks/googleMaps'
import useItems from '~/prix/react/hooks/items'
import { GeoJsonPoint } from '~/prix/types/geoJson'
import useGeoLegalEntities from '../legalEntity/geoLegalEntities.hook'
import MapHeatmapLayer from '../legalEntityGeoprocessing/heatmap/mapHeatmapLayer.component'
import DashboardItem from './dashboardItem.component'
import DashboardGridItemAttendance from './gridItems/dashboardGridItemAttendance.component'
import DashboardGridItemCourseRegisterIndividual from './gridItems/dashboardGridItemCourseRegisterIndividual.component'
import DashboardGridItemIndividual from './gridItems/dashboardGridItemIndividual.component'
import DashboardGridItemMostUsedCourseProductList from './gridItems/dashboardGridItemMostUsedCourseProductList.component'
import DashboardGridItemPublic from './gridItems/dashboardGridItemPublic.component'
import DashboardGridItemRadar from './gridItems/dashboardGridItemRadar.component'
import DashboardGridItemShortcuts from './gridItems/dashboardGridItemShortcuts.component'
import DashboardGridItemSubnormalClusters from './gridItems/dashboardGridItemSubnormalClusters.component'
import DashboardGridItemSystem from './gridItems/dashboardGridItemSystem.component'
import DashboardGridItemMonthAttendanceLastYear from './gridItems/dashboardGridItemMonthAttendanceLastYear.component'
import useItem from '~/prix/react/hooks/item'
import asGeoJson from '~/prix/functions/asGeoJson'
import DashboardGridItemMostUsedStateList from './gridItems/dashboardGridItemMostUsedStateList.component'
import DashboardGridItemMostUsedCityList from './gridItems/dashboardGridItemMostUsedCityList.component'
import DashboardGridItemDisproportionateComponent from './gridItems/dashboardGridItemDisproportionate.component'
import DashboardGridItemCourseDropout from './gridItems/dashboardGridItemCourseDropout.component'

const WrapperMap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .bottom {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 0px 10px 0px;
    a {
      color: #005eb8;
      color: #005eb8;

      &:hover {
        color: #0f438a;
        color: #0f438a;
      }
    }
  }
`

const MapWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 275px;
  width: 100%;

  .overlay-isLoading {
    position: absolute;
    background-color: rgba(0, 94, 184, 0.4);
    backdrop-filter: blur(5px);
    z-index: 1;
    text-align: center;
    height: 275px;

    .center {
      display: flex;
      flex-direction: column;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      .message {
        margin-top: 10px;
        color: #fff;
        font-weight: bold;
        text-shadow: 0 5px 5px rgba(0, 0, 0, 0.05);
      }
    }
  }
`

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  background-color: #f8f8f8;
  align-items: flex-start;
  background-color: #f8f8f8;

  @media (max-width: 1180px) {
    flex-direction: column;
    align-items: center;
    align-items: center;
    gap: 48px;
    overflow-x: hidden;
  }

  @media (max-width: 768px) {
    max-width: 97vw;
  }
`

const MainContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  align-content: flex-start;

  width: 70%;
  gap: 26px;
  padding: 48px 24px 88px 24px;
  height: -webkit-fill-available;

  @media (max-width: 1180px) {
    padding: 48px 24px 0px 24px;
    width: 99%;
  }

  @media (max-width: 768px) {
    padding: 0px 24px;
    margin-top: -72px;
  }

  @media (max-width: 400px) {
    margin-top: -100px;
  }

  .centralize {
    display: flex;
    flex-direction: row;
    width: 100%;
    flex: 1;
    gap: 14px;
    flex-wrap: wrap;
    padding-bottom: 20px;

    @media (max-width: 1500px) {
      justify-content: center;
    }

    @media (max-width: 768px) {
      gap: 26px;
    }

    .main-big {
      max-width: 450px;
    }
  }
`

const AsideContent = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  align-content: flex-start;
  justify-content: center;
  flex-wrap: wrap;

  width: 30%;

  gap: 25px;
  padding: 25px 38px 28px 25px;
  background: #e0e5eb;
  background: #e0e5eb;

  @media (max-width: 1180px) {
    margin-top: -45px;
    width: 76%;
    padding: 0px;
    background: #f8f8f8;
    background: #f8f8f8;
  }

  @media (max-width: 1024px) {
    padding: 0 24px;
    width: 85%;
  }
`

const ActiveDashboardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 8px;
  width: 100%;

  h2 {
    color: var(--p-action, #0f438a);
    font-size: 33px;
    font-weight: 400;
    margin: 0;
  }

  h3 {
    color: var(--p-action, #0f438a);
    font-weight: 300;
    line-height: 23.3px;
    font-size: 16px;
    margin: 0;
  }
`

const BottomWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: -20px;
  padding: 0px 0px 10px 0px;
  a {
    color: #005eb8;

    &:hover {
      color: #0f438a;
    }
  }
`

const dataSourceOptions = [
  'SEBRAE',
  'Portfólio EaD', // Utilizando o id da RFB para o portfólio
  'SEBRAE Na Sua Empresa',
  'Radar ALI',
  'Brasil Mais',
  'EaD',
  'SEBRAE Na Sua Empresa (App)',
]

export default function DashboardComponent({
  dataSourceId,
  tabIndicators,
  lastDates,
  isLoadingIndicatorsData,
}: {
  dataSourceId: number
  tabIndicators?: any // TODO types
  lastDates?: any // TODO types
  isLoadingIndicatorsData?: boolean
}) {
  const [totalAttendancesCondition, setTotalAttendancesCondition] = useState<number | null>(null)
  const [sumPublicItems, setSumPublicItems] = useState()
  const { context } = useAPI()
  const isMobile = useMedia('(max-width: 768px)')
  const isDashboardAsideCondition = useMedia('(max-width: 1180px)')
  const wrapperWidth = useRef<HTMLDivElement>(null)
  const [error, setError] = useState<Error | null>(null)
  const [isLoadingMap, setIsLoadingMap] = useState(true)
  const { google } = useGoogleMaps()
  const map = useRef<google.maps.Map | null>(null)

  const isEnabled = true

  const hasUserRoles = context.user
    ? !context.user?.roles.some(
        item => item === 'systemAdministrator' || item === 'administrator' || item === 'manager',
      )
    : false

  const geolocation = context.user?.roles.includes('agent') ? useGeolocation() : null

  function mapLoadAction() {
    setIsLoadingMap(false)
  }

  const dataSourceDefinedOption = useMemo(() => {
    const definedOptionAppSebrae =
      dataSourceId === 6
        ? {
            default: 'legalEntityAttendanceAppSebraeQuery',
            query: legalEntityAttendanceAppSebraeQuery,
            source: 6,
            positionSubmenu: 7,
          }
        : null

    const definedOptionPortfolioEad =
      dataSourceId === 1
        ? {
            default: 'legalEntityAttendancePortfolioEadQuery',
            query: legalEntityAttendancePortfolioEadQuery,
            source: 5,
            positionSubmenu: 6,
          }
        : null

    const definedOptionEad =
      dataSourceId === 5
        ? {
            default: 'legalEntityAttendanceAllCoursesEadQuery',
            query: legalEntityAttendanceAllCoursesEadQuery,
            source: 5,
            positionSubmenu: 5,
          }
        : null

    const definedOptionRadarALI =
      dataSourceId === 3
        ? {
            default: 'legalEntityAttendanceRadarAliLastYear',
            query: legalEntityAttendanceRadarAliQuery,
            source: 3,
            positionSubmenu: 3,
          }
        : null

    const definedOptionResult =
      dataSourceId === 6
        ? definedOptionAppSebrae
        : dataSourceId === 5
        ? definedOptionEad
        : dataSourceId === 1
        ? definedOptionPortfolioEad
        : dataSourceId === 3
        ? definedOptionRadarALI
        : null

    if (dataSourceId) {
      const definedOption = {
        filterName: null,
        filterType: null,
        filterValue: null,
        title: '',
        searchType: 'lastMonthAndYearPeriod',
        menu: 'Empresas atendidas',
        type: 'legalEntityAttendance',
        ...definedOptionResult,
      }

      return definedOption
    }
  }, [dataSourceId])

  const { isLoading, item: stateInfo } = useItem(
    () =>
      query('state')
        .select({
          id: entity('state').property('id'),
          name: entity('state').property('name'),
          center: asGeoJson(entity('state').property('center')),
        })
        .where(
          equals(
            entity('state').property('abbreviation'),
            string().value(String(context.user?.stateAbbreviation)),
          ),
        )
        .limit(1),
    [context.user],
  )

  const geoResult = useGeoLegalEntities({
    geo: stateInfo && hasUserRoles ? 'state' : 'country',
    id: stateInfo && hasUserRoles ? String(stateInfo.id) : '30',
    childrenGeoLevel: undefined,
    filter: null,
    highlight: null,
    isEnabled: !!isEnabled,
    definedOption: dataSourceDefinedOption,
    level: {
      geo: stateInfo && hasUserRoles ? 'state' : 'country',
      id: stateInfo && hasUserRoles ? String(stateInfo.id) : '30',
      childrenGeoLevel: undefined,
    },
  })

  const result = useItems(
    () =>
      isEnabled && dataSourceDefinedOption
        ? (mapHeatmapQuery({
            parentId: stateInfo?.id && hasUserRoles ? String(stateInfo.id) : '30',
            availableGeoLevel: stateInfo?.id && hasUserRoles ? 'state' : 'country',
            filter: null,
            mapLevel: stateInfo?.id && hasUserRoles ? 'state' : 'country',
            definedOption: dataSourceDefinedOption,
          }) as QueryBase)
        : (null as never),
    [isEnabled, dataSourceDefinedOption, stateInfo, hasUserRoles],
    {
      cache: 60 * 60 * 24 * 7,
      autoLoad: isEnabled && dataSourceId !== 0,
    },
  )

  const data = useMemo(() => {
    if (result.items && result.isLoading === false && google) {
      const heatmapPoints: { weight: number; location: google.maps.LatLng }[] = []
      result.items.forEach(item => {
        if (item.location) {
          const locationAsGeoJson = item.location as GeoJsonPoint
          const location = new google.maps.LatLng(
            locationAsGeoJson.coordinates[1],
            locationAsGeoJson.coordinates[0],
          )
          heatmapPoints.push({
            weight: item.weight,
            location,
          })
        }
      })
      return heatmapPoints
    } else {
      return []
    }
  }, [result.isLoading, result.items, google])

  useEffect(() => {
    setTotalAttendancesCondition(null)
  }, [dataSourceId])

  return (
    <Wrapper>
      {isMobile && <TopCard title={`Olá ${context.user!.name}!`} />}
      <MainContent>
        {!isMobile && (
          <ActiveDashboardWrapper>
            <h2>Dashboard</h2>
            <h3>
              Informações abrangentes provenientes das fontes do {dataSourceOptions[dataSourceId]}
            </h3>
          </ActiveDashboardWrapper>
        )}
        {totalAttendancesCondition === null ||
        totalAttendancesCondition === undefined ||
        (totalAttendancesCondition !== null && totalAttendancesCondition > 0) ? (
          <div className='centralize'>
            {/* Geral */}
            {dataSourceId === 0 ? (
              <div className='centralize'>
                {geolocation &&
                geolocation.longitude &&
                geolocation.latitude &&
                context.user?.roles.includes('agent') &&
                isMobile ? (
                  <DashboardItem
                    title='Radar de empresas não atendidas'
                    subtitle='Lista e visualização prévia de empresas mais próximas'
                    component={<DashboardGridItemRadar />}
                    kind='secondary'
                  />
                ) : null}
                <DashboardItem
                  title='Público'
                  subtitle={`Seu público dentre as empresas ativas: ${formatAsBrNumber(
                    Number(sumPublicItems),
                  )}`}
                  kind='main'
                  component={
                    <DashboardGridItemPublic
                      onChangeValues={setSumPublicItems}
                      userGeoStateAbbreviation={
                        hasUserRoles && context.user ? context.user.stateAbbreviation : undefined
                      }
                      dataSourceId={dataSourceId}
                    />
                  }
                />
                <DashboardItem
                  title='Geocodificação'
                  subtitle='Progresso de conversão de endereços nas empresas ativas de todos os portes'
                  kind='secondary'
                  component={
                    <DashboardGridItemSystem
                      userGeoStateAbbreviation={
                        hasUserRoles && context.user ? context.user.stateAbbreviation : undefined
                      }
                      dataSourceId={dataSourceId}
                    />
                  }
                />
                <DashboardItem
                  title='Atendimentos'
                  subtitle={`Totalizadores de atendimentos a nível ${
                    context.user?.roles.includes('administrator') ||
                    context.user?.roles.includes('manager') ||
                    context.user?.roles.includes('systemAdministrator')
                      ? 'nacional'
                      : 'estadual'
                  }`}
                  component={
                    <DashboardGridItemAttendance
                      userGeoStateAbbreviation={
                        hasUserRoles && context.user ? context.user.stateAbbreviation : undefined
                      }
                      dataSourceId={dataSourceId}
                    />
                  }
                  kind='secondary'
                />
                <DashboardItem
                  title='Pessoas físicas'
                  subtitle='Totalizadores de pessoas físicas'
                  component={
                    <DashboardGridItemIndividual
                      userGeoStateAbbreviation={
                        hasUserRoles && context.user ? context.user.stateAbbreviation : undefined
                      }
                      dataSourceId={dataSourceId}
                    />
                  }
                  kind='secondary'
                />
              </div>
            ) : null}

            {/* Contadores Radar ALI, SEBRAE Na Sua Empresa (App) */}
            {dataSourceId !== 0 && dataSourceId !== 1 && dataSourceId !== 5 ? (
              <>
                <DashboardItem
                  title='Atendimentos'
                  subtitle={`Totalizadores de atendimentos a nível ${
                    context.user?.roles.includes('administrator') ||
                    context.user?.roles.includes('manager') ||
                    context.user?.roles.includes('systemAdministrator')
                      ? 'nacional'
                      : 'estadual'
                  }`}
                  component={
                    <DashboardGridItemAttendance
                      userGeoStateAbbreviation={
                        hasUserRoles && context.user ? context.user.stateAbbreviation : undefined
                      }
                      dataSourceId={dataSourceId}
                      handleCountAttendances={setTotalAttendancesCondition}
                      hasUserRoles={hasUserRoles}
                    />
                  }
                  kind='secondary'
                />
                <DashboardItem
                  title='Atendimentos no último ano'
                  subtitle={`Totalizadores de atendimentos a nível ${
                    context.user?.roles.includes('administrator') ||
                    context.user?.roles.includes('manager') ||
                    context.user?.roles.includes('systemAdministrator')
                      ? 'nacional'
                      : 'estadual'
                  }`}
                  component={
                    <DashboardGridItemAttendance
                      userGeoStateAbbreviation={
                        hasUserRoles && context.user ? context.user.stateAbbreviation : undefined
                      }
                      dataSourceId={dataSourceId}
                      isLastYearQuery={true}
                    />
                  }
                  kind='secondary'
                />
              </>
            ) : null}

            {/* Contadores EaD ou Porfólio EaD */}
            {dataSourceId !== 0 && (dataSourceId === 1 || dataSourceId === 5) ? (
              <>
                <DashboardItem
                  title={dataSourceId === 5 ? 'Todos os cursos' : 'Cursos do portfólio'}
                  subtitle={`Totalizadores de matrículas e atendimentos a nível ${
                    context.user?.roles.includes('administrator') ||
                    context.user?.roles.includes('manager') ||
                    context.user?.roles.includes('systemAdministrator')
                      ? 'nacional'
                      : 'estadual'
                  }`}
                  component={
                    <DashboardGridItemCourseRegisterIndividual
                      userGeoStateAbbreviation={
                        hasUserRoles && context.user ? context.user.stateAbbreviation : undefined
                      }
                      dataSourceId={dataSourceId}
                    />
                  }
                  kind='secondary'
                />
                <DashboardItem
                  title={
                    dataSourceId === 5
                      ? 'Todos os cursos no último ano'
                      : 'Cursos do portfólio no último ano'
                  }
                  subtitle={`Totalizadores de atendimentos a nível ${
                    context.user?.roles.includes('administrator') ||
                    context.user?.roles.includes('manager') ||
                    context.user?.roles.includes('systemAdministrator')
                      ? 'nacional'
                      : 'estadual'
                  }`}
                  component={
                    <DashboardGridItemCourseRegisterIndividual
                      userGeoStateAbbreviation={
                        hasUserRoles && context.user ? context.user.stateAbbreviation : undefined
                      }
                      dataSourceId={dataSourceId}
                      isLastYearQuery={true}
                    />
                  }
                  kind='secondary'
                />
              </>
            ) : null}

            {/* Mapa */}
            <DashboardItem
              title='Mapa de atendimentos no último ano'
              subtitle={`Em destaque os atendimentos a nível ${
                context.user?.roles.includes('administrator') ||
                context.user?.roles.includes('manager') ||
                context.user?.roles.includes('systemAdministrator')
                  ? 'nacional'
                  : 'estadual'
              }`}
              component={
                <WrapperMap>
                  <Handle error={error}>
                    <MapWrapper
                      ref={wrapperWidth}
                      style={
                        isLoadingMap
                          ? { height: '275px', pointerEvents: 'none' }
                          : { height: 'none', pointerEvents: 'none' }
                      }
                    >
                      {data.length === 0 && result.isLoading !== false && isLoadingMap === false ? (
                        <div
                          className='overlay-isLoading'
                          style={{ width: wrapperWidth.current?.clientWidth }}
                        >
                          <div className='center'>
                            <PulseLoader color={'#fff'} />
                            <span className='message'>Carregando localizações...</span>
                          </div>
                        </div>
                      ) : null}
                      <GoogleMaps
                        ref={map}
                        onLoad={mapLoadAction}
                        mapContainerStyle={
                          dataSourceId !== 0
                            ? {
                                width: '100%',
                                height: '100%',
                                minHeight: '275px',
                              }
                            : { display: 'none' }
                        }
                        mapTypeId={'satellite'}
                        zoom={stateInfo?.id && hasUserRoles ? 6 : 3}
                        center={{
                          lat:
                            stateInfo?.id && hasUserRoles === true
                              ? stateInfo.center.coordinates[1]
                              : !hasUserRoles
                              ? Number(-15.49435598360633)
                              : undefined,
                          lng:
                            stateInfo?.id && hasUserRoles === true
                              ? stateInfo.center.coordinates[0]
                              : !hasUserRoles
                              ? Number(-50.909979045002146)
                              : undefined,
                        }}
                        options={{
                          disableDefaultUI: true,
                        }}
                      >
                        <>
                          {geoResult.items?.map(item => (
                            <GeoJsonMultiPolygon
                              geoJson={item.multiPolygon}
                              options={{
                                fillColor: '#fff',
                                fillOpacity: 0,
                                strokeColor: '#fff',
                                strokeWeight: 0.5,
                                zIndex: 1,
                              }}
                            />
                          ))}
                          <MapHeatmapLayer
                            heatmapPoints={data}
                            zoomLevel={stateInfo?.id && hasUserRoles ? 4 : 3}
                            resolutionThreshold={stateInfo?.id && hasUserRoles ? 11 : 10}
                          />
                        </>
                      </GoogleMaps>
                    </MapWrapper>
                    <div className='bottom'>
                      <a
                        href={`/app/map/${hasUserRoles ? 'state' : 'country'}/${
                          hasUserRoles ? stateInfo?.id : '30'
                        }?visualizationType=heatmap&definedOption=${
                          dataSourceDefinedOption?.default
                        }`}
                      >
                        Ver mais
                      </a>
                    </div>
                  </Handle>
                </WrapperMap>
              }
              kind='secondary'
              isMap={dataSourceId === 0 ? false : true}
            />

            {/* Principais cursos, estados, cidades, atendimentos realizados nos ultimos meses, desproporcional */}
            {dataSourceId !== 0 ? (
              <>
                {dataSourceId === 1 ? (
                  <>
                    <DashboardItem
                      title='Principais cursos do portfólio no último ano'
                      subtitle={`Em destaque os principais cursos vinculados a empresas a nível ${
                        context.user?.roles.includes('administrator') ||
                        context.user?.roles.includes('manager') ||
                        context.user?.roles.includes('systemAdministrator')
                          ? 'nacional'
                          : 'estadual'
                      }`}
                      component={
                        <DashboardGridItemMostUsedCourseProductList
                          userGeoStateAbbreviation={
                            hasUserRoles && context.user
                              ? context.user.stateAbbreviation
                              : undefined
                          }
                          dataSourceId={dataSourceId}
                        />
                      }
                      kind='secondary'
                    />
                    <DashboardItem
                      title='Matrículas por mês no último ano'
                      subtitle={`Em destaque as matrículas realizadas por mês no último ano a nível ${
                        context.user?.roles.includes('administrator') ||
                        context.user?.roles.includes('manager') ||
                        context.user?.roles.includes('systemAdministrator')
                          ? 'nacional'
                          : 'estadual'
                      }`}
                      activeDataSourceId={dataSourceId}
                      tabIndicators={tabIndicators}
                      lastDates={lastDates}
                      component={
                        <DashboardGridItemMonthAttendanceLastYear
                          userGeoStateAbbreviation={
                            hasUserRoles && context.user
                              ? context.user.stateAbbreviation
                              : undefined
                          }
                          dataSourceId={dataSourceId}
                          isPortfolioEad={true}
                          activeDataSourceId={dataSourceId}
                          tabIndicators={tabIndicators}
                          lastDates={lastDates}
                          isLoadingIndicatorsData={isLoadingIndicatorsData}
                        />
                      }
                      kind='lineChart'
                    />
                  </>
                ) : null}

                {dataSourceId !== 1 ? (
                  <>
                    {hasUserRoles && context.user && context.user.stateAbbreviation ? (
                      <DashboardItem
                        title='Principais cidades atendidas no último ano'
                        subtitle={`Em destaque as principais cidades atendidas a nível estadual`}
                        component={
                          <DashboardGridItemMostUsedCityList
                            userGeoStateAbbreviation={
                              hasUserRoles && context.user
                                ? context.user.stateAbbreviation
                                : undefined
                            }
                            stateId={stateInfo && stateInfo.id ? String(stateInfo?.id) : undefined}
                            dataSourceId={dataSourceId}
                          />
                        }
                        kind='secondary'
                      />
                    ) : (
                      <DashboardItem
                        title='Principais estados atendidos no último ano'
                        subtitle={`Em destaque os principais estados atendidos a nível ${
                          context.user?.roles.includes('administrator') ||
                          context.user?.roles.includes('manager') ||
                          context.user?.roles.includes('systemAdministrator')
                            ? 'nacional'
                            : 'estadual'
                        }`}
                        component={
                          <DashboardGridItemMostUsedStateList dataSourceId={dataSourceId} />
                        }
                        kind='secondary'
                      />
                    )}

                    <DashboardItem
                      title='Atendimentos por mês no último ano'
                      subtitle={`Em destaque os atendimentos realizados por mês no último ano a nível ${
                        context.user?.roles.includes('administrator') ||
                        context.user?.roles.includes('manager') ||
                        context.user?.roles.includes('systemAdministrator')
                          ? 'nacional'
                          : 'estadual'
                      }`}
                      activeDataSourceId={dataSourceId}
                      tabIndicators={tabIndicators}
                      lastDates={lastDates}
                      component={
                        <DashboardGridItemMonthAttendanceLastYear
                          userGeoStateAbbreviation={
                            hasUserRoles && context.user
                              ? context.user.stateAbbreviation
                              : undefined
                          }
                          dataSourceId={dataSourceId}
                          activeDataSourceId={dataSourceId}
                          tabIndicators={tabIndicators}
                          lastDates={lastDates}
                          isLoadingIndicatorsData={isLoadingIndicatorsData}
                        />
                      }
                      kind='lineChart'
                    />
                  </>
                ) : null}

                <DashboardItem
                  title='Atendimentos desproporcionais'
                  subtitle={`Em destaque CNAEs com muita ocorrência mas baixo índice de atendimento`}
                  component={
                    <>
                      <DashboardGridItemDisproportionateComponent
                        userGeoStateAbbreviation={
                          hasUserRoles && context.user ? context.user.stateAbbreviation : undefined
                        }
                        dataSourceId={dataSourceId}
                      />
                      <BottomWrapper>
                        <a
                          href={`/app/disproportionate-attention/${
                            hasUserRoles ? 'state' : 'country'
                          }/${hasUserRoles ? stateInfo?.id : '30'}?filterAttendanceSource=${
                            dataSourceId === 1 ? 5 : dataSourceId
                          }${
                            dataSourceId === 5
                              ? '&filterEadOption=all'
                              : dataSourceId === 1
                              ? '&filterEadOption=portfolio'
                              : ''
                          }`}
                        >
                          Ver mais
                        </a>
                      </BottomWrapper>
                    </>
                  }
                  kind='secondary'
                />
              </>
            ) : null}
          </div>
        ) : (
          <div className='centralize'>
            <span style={{ marginLeft: '10px' }}>Sem dados disponíveis para essa fonte.</span>
          </div>
        )}
      </MainContent>
      <AsideContent>
        {geolocation &&
        geolocation.longitude &&
        geolocation.latitude &&
        context.user?.roles.includes('agent') &&
        !isMobile ? (
          <DashboardItem
            title='Radar de empresas não atendidas'
            subtitle='Lista e visualização prévia de empresas mais próximas'
            component={<DashboardGridItemRadar />}
            kind='aside'
          />
        ) : null}
        <DashboardItem
          title='Links e Atalhos Úteis'
          component={<DashboardGridItemShortcuts />}
          kind='aside'
        />

        <DashboardItem
          title='Evasão de cursos'
          component={<DashboardGridItemCourseDropout />}
          kind='aside'
        />

        <DashboardItem
          title='Aglomerados Subnormais'
          component={<DashboardGridItemSubnormalClusters />}
          kind='aside'
        />

        {/* Ajustar tornando flex o background cinza */}
        {dataSourceId !== 0 && !isDashboardAsideCondition ? (
          <div style={{ minHeight: '350px' }} />
        ) : (
          <div style={{ minHeight: '25px' }} />
        )}
      </AsideContent>
    </Wrapper>
  )
}

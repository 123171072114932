import { entity, query, equals, string, ascending } from '~/prix'
import React, { ChangeEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import styled from 'styled-components'
import { parseDate } from '~/prix/types/date'
import { TimeSeriesDataProps } from './timeSeries.data'
import useItems from '~/prix/react/hooks/items'
import Handle from '~/prix/react/components/handle'
import TimeSeriesDefaultBottomChartComponent from './timeSeriesDefaultBottomChart.component'
import {
  Component,
  HarmonicComponent,
  PolynomialComponent,
  TimeSeriesItem,
} from './timeSeries.entity'
import TimeSeriesTrendBottomChartComponent from './timeSeriesTrendBottomChart.component'
import TimeSeriesCalendarEffectBottomChartComponent from './timeSeriesCalendarEffectBottomChart.component'
import TimeSeriesMap, { SelectedGeoItem } from './timeSeriesMap.component'
import TimeSeriesHarmonicBottomChartComponent from './timeSeriesHarmonicBottomChart.component'
import TimeSeriesCnae from './timeSeriesCnae.component'
import TimeSeriesHarmonicInfoLine from './timeSeriesHarmonicInfoLine.component'
import TimeSeriesLatestImpactBottomChartComponent from './timeSeriesLatestImpactBottomChart.component'
import ExpandArrowsIcon from '~/meta/arrows-expand-left.svg'
import Modal from '~/prix/react/components/modal'
import ArrowExpandUpLeftIcon from '~/meta/arrows-expand-up-left.svg'
import CompressIcon from '~/meta/compress-arrows.svg'
import DecompressIcon from '~/meta/decompress-arrows.svg'
import TimeSeriesSegment from './timeSeriesSegment.component'
import { useNavigate, useParams } from 'react-router'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;

  overflow-x: hidden;

  input,
  select,
  .between-inputs {
    border-radius: 2px;
    background-color: #fff;
    box-shadow: rgb(0 0 0 / 30%) 0px 1px 4px -1px;
    color: rgb(86, 86, 86);
    height: 27px;
    line-height: 25px;
    padding: 0px 5px;
    font-size: 14px;
    border: none;

    &.between-inputs input {
      box-shadow: none;
    }
  }

  .details {
    display: flex;
    position: relative;
    flex: 1;

    .details-selector,
    .between-inputs,
    .state-selector {
      position: absolute;
      top: 5px;
    }

    .details-selector {
      left: 5px;
      width: 125px;
    }

    .state-selector {
      left: 135px;
      width: 150px;
    }

    .between-inputs {
      left: 290px;
      display: flex;

      input {
        border: none;
      }

      span {
        padding: 0px 5px;
      }
    }

    @media (max-width: 1024px) {
      .between-inputs {
        left: 5px;
        top: 40px;
      }
    }
  }

  .bottom {
    display: flex;
    background-color: #fff;
    min-height: 220px;
    flex-direction: column;

    header {
      width: 100%;
      display: flex;
      padding: 5px 8px;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;

      div {
        display: flex;
        gap: 8px;
      }
    }

    main {
      display: flex;
      flex: 1;
      align-items: center;
      justify-content: center;
    }
  }
`

const ModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 16px;

  header {
    width: 100%;
    display: flex;
    padding: 5px 8px;
    flex-direction: row;
    align-items: center;
    gap: 8px;
  }

  main {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
    height: 100%;
  }

  input,
  select,
  .between-inputs {
    border-radius: 2px;
    background-color: #fff;
    box-shadow: rgb(0 0 0 / 30%) 0px 1px 4px -1px;
    color: rgb(86, 86, 86);
    height: 27px;
    line-height: 25px;
    padding: 0px 5px;
    font-size: 14px;
    border: none;

    &.between-inputs input {
      box-shadow: none;
    }
  }

  .details {
    display: flex;
    position: relative;
    flex: 1;

    .details-selector,
    .between-inputs {
      position: absolute;
      top: 5px;
    }

    .details-selector {
      left: 5px;
      width: 130px;
    }

    .between-inputs {
      left: 140px;
      display: flex;

      input {
        border: none;
      }

      span {
        padding: 0px 5px;
      }
    }
  }
`

const ButtonHeaderContainer = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: 2px;
  background-color: #fff;
  box-shadow: rgb(0 0 0 / 30%) 0px 1px 4px -1px;
  color: rgb(86, 86, 86);
  height: 27px;
  line-height: 25px;
  padding: 0px 5px;
  font-size: 14px;
  border: none;
  cursor: pointer;
`

const periodicityOptions = [
  {
    key: 'daily',
    label: 'Diariamente',
  },
  {
    key: 'weekly',
    label: 'Semanalmente',
  },
  {
    key: 'monthly',
    label: 'Mensalmente',
  },
  {
    key: 'yearly',
    label: 'Anualmente',
  },
]

export interface TimeSeriesProps {
  timeSeries: TimeSeriesDataProps
}

export default function TimeSeries({ timeSeries }: TimeSeriesProps) {
  const [bottomChartSelected, setBottomChartSelected] = useState('default')
  const [startString, setStartString] = useState('')
  const [endString, setEndString] = useState('')
  const [selectedGeoLevel, setSelectedGeoLevel] = useState<SelectedGeoItem | null>(null)
  const [optionSelected, setOptionSelected] = useState('states')
  const [periodicitySelected, setPeriodicitySelected] = useState('daily')
  const [isExpanded, setIsExpanded] = useState<boolean>(false)
  const [isCompressed, setIsCompressed] = useState<boolean>(false)
  const mainRef = useRef<HTMLDivElement>(null)
  const [mainHeight, setMainHeight] = useState(0)
  const navigate = useNavigate()
  const { timeSeriesKey: timeSeriesKeyParam, state: stateKey } = useParams()

  useEffect(() => {
    let timeout: NodeJS.Timeout | null = null

    const handleResize = () => {
      if (mainRef.current) {
        setMainHeight(mainRef.current.offsetHeight)
      }
    }

    const checkHeight = () => {
      if (mainRef.current && mainRef.current.offsetHeight > 0) {
        setMainHeight(mainRef.current.offsetHeight)
        clearInterval(interval)
      }
    }

    const interval = setInterval(checkHeight, 100)

    window.addEventListener('resize', handleResize)

    timeout = setTimeout(() => {
      if (mainRef.current) {
        setMainHeight(mainRef.current.offsetHeight)
        clearInterval(interval)
      }
    }, 1000)

    return () => {
      window.removeEventListener('resize', handleResize)
      if (timeout) clearTimeout(timeout)
      clearInterval(interval)
    }
  }, [])

  const timeSeriesResult = useItems(
    () =>
      query('timeSeries')
        .select({
          id: entity('timeSeries').property('id'),
          key: entity('timeSeries').property('key'),
          alertLevel: entity('timeSeries').property('alertLevel'),
          date: entity('timeSeries').property('date'),
          components: entity('timeSeries').property('components'),
          value: entity('timeSeries').property('value'),
          predictedValue: entity('timeSeries').property('predictedValue'),
        })
        .where(equals(entity('timeSeries').property('key'), string().value(timeSeries.key)))
        .order(ascending('date')),
    [timeSeries.key],
  )

  const items = useMemo(() => {
    if (selectedGeoLevel) {
      return selectedGeoLevel.items
    }

    return timeSeriesResult.items as TimeSeriesItem[] | null
  }, [selectedGeoLevel, timeSeriesResult.items, timeSeriesResult.isLoading])

  const components = useMemo(
    () => (items?.find(item => item.components)?.components || null) as Component[] | null,
    [items],
  )

  const handleStartChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => setStartString(event.target.value),
    [],
  )
  const handleEndChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => setEndString(event.target.value),
    [],
  )
  const handleBottomChartSelected = useCallback(
    (event: ChangeEvent<HTMLSelectElement>) => setBottomChartSelected(event.target.value),
    [],
  )

  const handlePeriodicitySelected = useCallback(
    (event: ChangeEvent<HTMLSelectElement>) => setPeriodicitySelected(event.target.value),
    [],
  )

  const start = useMemo(() => (startString ? parseDate(startString) : null), [startString])
  const end = useMemo(() => (endString ? parseDate(endString) : null), [endString])

  const bottomChartOptions = useMemo(() => {
    if (!components) return null
    const options: Array<{
      key: string
      label: string
    }> = [
      {
        key: 'default',
        label: 'Série temporal',
      },
      {
        key: 'holidayImpact',
        label: 'Feriados nos últimos anos',
      },
      {
        key: 'weekImpact',
        label: 'Dias da semana nas últimas semanas',
      },
      {
        key: 'monthImpact',
        label: 'Meses nos últimos anos',
      },
      {
        key: 'monthDaysImpact',
        label: 'Dias do mês nos últimos anos',
      },
      {
        key: 'dayOfYearImpact',
        label: 'Dias do ano nos últimos anos',
      },
    ]

    const trends = components.filter(component => component.type === 'polynomial')
    if (trends.length) {
      trends.forEach((_, index) =>
        options.push({
          key: `trend-${index}`,
          label: trends.length > 1 ? `Tendência ${index + 1}` : 'Tendência principal',
        }),
      )
    }

    const hasMonthEffect = components.some(component => component.name === 'month')
    if (hasMonthEffect) {
      options.push({
        key: 'month',
        label: 'Efeito de cada mês',
      })
    }

    const hasWeekdayEffect = components.some(component => component.name === 'weekday')
    if (hasWeekdayEffect) {
      options.push({
        key: 'weekday',
        label: 'Efeito de cada dia da semana',
      })
    }

    const hasHolidayEffect = components.some(component => component.name === 'holiday')
    if (hasHolidayEffect) {
      options.push({
        key: 'holiday',
        label: 'Efeito de cada feriado',
      })
    }

    const hasDayOfMonthEffect = components.some(component => component.name === 'dayOfMonth')
    if (hasDayOfMonthEffect) {
      options.push({
        key: 'dayOfMonth',
        label: 'Efeito de cada dia do mês',
      })
    }

    const harmonics = components.filter(component => component.type === 'harmonic')
    if (harmonics.length) {
      harmonics.forEach((_, index) =>
        options.push({
          key: `harmonic-${index}`,
          label: harmonics.length > 1 ? `Harmônica ${index + 1}` : 'Harmônica principal',
        }),
      )
    }

    return options
  }, [components])

  const stateEnum = useItems(
    () =>
      query('state')
        .select({
          id: entity('state').property('id'),
          name: entity('state').property('name'),
        })
        .order(ascending('name')),
    [],
    { cache: 60 * 60 * 24 * 7 },
  )

  const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const value = event.target.value
    if (value === '') {
      const escEvent = new KeyboardEvent('keydown', {
        key: 'Escape',
        code: 'Escape',
        keyCode: 27,
        which: 27,
        bubbles: true,
      })
      document.dispatchEvent(escEvent)
    } else {
      navigate(`/app/time-series/${timeSeriesKeyParam}/${value}`)
    }
  }

  return (
    <Wrapper>
      <div className='details'>
        <TimeSeriesMap
          timeSeries={timeSeries}
          geoLevel='state'
          selectedGeoItem={selectedGeoLevel}
          onClickGeoItem={setSelectedGeoLevel}
          startString={start ? startString : null}
          endString={end ? endString : null}
          optionSelected={optionSelected}
        />

        {optionSelected === 'activities' && (
          <TimeSeriesCnae
            stateAbbreviation={selectedGeoLevel?.abbreviation}
            stateName={
              selectedGeoLevel?.id
                ? (stateEnum?.items?.find(item => String(item.id) == stateKey)?.name as string)
                : ''
            }
            startString={start ? startString : undefined}
            endString={end ? endString : undefined}
          />
        )}

        {optionSelected === 'segments' && (
          <TimeSeriesSegment
            stateAbbreviation={selectedGeoLevel?.abbreviation}
            stateName={
              selectedGeoLevel?.id
                ? (stateEnum?.items?.find(item => String(item.id) == stateKey)?.name as string)
                : ''
            }
            startString={start ? startString : undefined}
            endString={end ? endString : undefined}
          />
        )}

        <select
          className='details-selector'
          onChange={e => setOptionSelected(e.target.value)}
          value={optionSelected}
        >
          <option value='states'>Estados</option>
          <option value='activities'>Principais atividades econômicas</option>
          <option value='segments'>Segmentos</option>
        </select>

        <select
          className='state-selector'
          onChange={e => handleSelectChange(e)}
          value={selectedGeoLevel?.id || ''}
        >
          <option value=''>Todos estados</option>
          {stateEnum.items &&
            stateEnum.items.map(state => (
              <option key={state.id as number} value={state.id as number}>
                {state.name}
              </option>
            ))}
        </select>

        <div className='between-inputs'>
          <input
            type='date'
            name='start'
            className='start-input'
            value={startString}
            onChange={handleStartChange}
          />
          <span>à</span>
          <input
            type='date'
            name='end'
            className='end-input'
            value={endString}
            onChange={handleEndChange}
          />
        </div>
      </div>
      {!isExpanded ? (
        <div className='bottom'>
          <header>
            <div>
              <select onChange={handleBottomChartSelected} value={bottomChartSelected}>
                {bottomChartOptions?.map(option => (
                  <option
                    key={option.key}
                    value={option.key}
                    selected={bottomChartSelected === option.key}
                  >
                    {option.label}
                  </option>
                ))}
              </select>

              {components && bottomChartSelected === 'default' ? (
                <select onChange={handlePeriodicitySelected} value={periodicitySelected}>
                  {periodicityOptions?.map(option => (
                    <option key={option.key} value={option.key}>
                      {option.label}
                    </option>
                  ))}
                </select>
              ) : null}

              {components && bottomChartSelected.startsWith('harmonic-') ? (
                <TimeSeriesHarmonicInfoLine
                  component={
                    components.filter(component => component.type === 'harmonic')[
                      Number(bottomChartSelected.split('-')[1])
                    ] as HarmonicComponent
                  }
                />
              ) : null}

              {bottomChartSelected === 'dayOfYearImpact' && (
                <ButtonHeaderContainer
                  onClick={() => setIsCompressed(() => !isCompressed)}
                  title={
                    isCompressed
                      ? 'Clique para comprimir a série temporal'
                      : 'Clique para descomprimir a série temporal'
                  }
                >
                  {isCompressed ? <CompressIcon /> : <DecompressIcon />}
                </ButtonHeaderContainer>
              )}
            </div>

            <ButtonHeaderContainer
              onClick={() => setIsExpanded(true)}
              title='Expandir a série temporal'
            >
              <ExpandArrowsIcon />
            </ButtonHeaderContainer>
          </header>
          <main>
            <Handle error={timeSeriesResult.error} isLoading={timeSeriesResult.isLoading}>
              {items && components ? (
                <React.Fragment>
                  {bottomChartSelected === 'default' ? (
                    <TimeSeriesDefaultBottomChartComponent
                      items={items}
                      components={components}
                      height={180}
                      periodicity={periodicitySelected}
                    />
                  ) : null}

                  {bottomChartSelected.startsWith('trend-') ? (
                    <TimeSeriesTrendBottomChartComponent
                      items={items}
                      component={
                        components.filter(component => component.type === 'polynomial')[
                          Number(bottomChartSelected.split('-')[1])
                        ] as PolynomialComponent
                      }
                      height={180}
                    />
                  ) : null}

                  {bottomChartSelected === 'month' ? (
                    <TimeSeriesCalendarEffectBottomChartComponent
                      items={items}
                      representation='month'
                      height={180}
                      components={components.filter(
                        component => component.name === bottomChartSelected,
                      )}
                    />
                  ) : null}

                  {bottomChartSelected === 'weekday' ? (
                    <TimeSeriesCalendarEffectBottomChartComponent
                      items={items}
                      representation='weekday'
                      height={180}
                      components={components.filter(
                        component => component.name === bottomChartSelected,
                      )}
                    />
                  ) : null}

                  {bottomChartSelected === 'holiday' ? (
                    <TimeSeriesCalendarEffectBottomChartComponent
                      items={items}
                      representation='holiday'
                      height={180}
                      components={components.filter(
                        component => component.name === bottomChartSelected,
                      )}
                    />
                  ) : null}

                  {bottomChartSelected === 'dayOfMonth' ? (
                    <TimeSeriesCalendarEffectBottomChartComponent
                      items={items}
                      representation='dayOfMonth'
                      height={180}
                      components={components.filter(
                        component => component.name === bottomChartSelected,
                      )}
                    />
                  ) : null}

                  {bottomChartSelected.startsWith('harmonic-') ? (
                    <TimeSeriesHarmonicBottomChartComponent
                      items={items}
                      component={
                        components.filter(component => component.type === 'harmonic')[
                          Number(bottomChartSelected.split('-')[1])
                        ] as HarmonicComponent
                      }
                      height={180}
                    />
                  ) : null}

                  {bottomChartSelected === 'holidayImpact' ? (
                    <TimeSeriesLatestImpactBottomChartComponent
                      items={items}
                      height={180}
                      representation='holiday'
                    />
                  ) : null}

                  {bottomChartSelected === 'weekImpact' ? (
                    <TimeSeriesLatestImpactBottomChartComponent
                      items={items}
                      height={180}
                      representation='weekday'
                    />
                  ) : null}

                  {bottomChartSelected === 'monthImpact' ? (
                    <TimeSeriesLatestImpactBottomChartComponent
                      items={items}
                      height={180}
                      representation='month'
                    />
                  ) : null}

                  {bottomChartSelected === 'monthDaysImpact' ? (
                    <TimeSeriesLatestImpactBottomChartComponent
                      items={items}
                      height={180}
                      representation='dayOfMonth'
                    />
                  ) : null}

                  {bottomChartSelected === 'dayOfYearImpact' ? (
                    <TimeSeriesLatestImpactBottomChartComponent
                      items={items}
                      height={180}
                      representation='dayOfYear'
                      isCompressed={isCompressed}
                    />
                  ) : null}
                </React.Fragment>
              ) : null}
            </Handle>
          </main>
        </div>
      ) : (
        <div className='bottom'>
          <main>
            <ArrowExpandUpLeftIcon width={40} height={40} fill='rgb(86, 86, 86)' />
          </main>
        </div>
      )}
      <Modal
        isOpen={isExpanded}
        onClose={() => setIsExpanded(false)}
        customStyles={{
          content: {
            maxWidth: '100%',
            margin: '25px',
            left: '0',
            top: '0',
            background: '#F8F8F8',
            borderRadius: '8px',
          },
        }}
      >
        <ModalWrapper ref={mainRef}>
          <header>
            <select onChange={handleBottomChartSelected} value={bottomChartSelected}>
              {bottomChartOptions?.map(option => (
                <option
                  key={option.key}
                  value={option.key}
                  selected={bottomChartSelected === option.key}
                >
                  {option.label}
                </option>
              ))}
            </select>

            {components && bottomChartSelected === 'default' ? (
              <select onChange={handlePeriodicitySelected} value={periodicitySelected}>
                {periodicityOptions?.map(option => (
                  <option key={option.key} value={option.key}>
                    {option.label}
                  </option>
                ))}
              </select>
            ) : null}

            {components && bottomChartSelected.startsWith('harmonic-') ? (
              <TimeSeriesHarmonicInfoLine
                component={
                  components.filter(component => component.type === 'harmonic')[
                    Number(bottomChartSelected.split('-')[1])
                  ] as HarmonicComponent
                }
              />
            ) : null}

            {bottomChartSelected === 'dayOfYearImpact' && (
              <ButtonHeaderContainer
                onClick={() => setIsCompressed(() => !isCompressed)}
                title={
                  isCompressed
                    ? 'Clique para comprimir a série temporal'
                    : 'Clique para descomprimir a série temporal'
                }
              >
                {isCompressed ? <CompressIcon /> : <DecompressIcon />}
              </ButtonHeaderContainer>
            )}
          </header>
          <main>
            <Handle error={timeSeriesResult.error} isLoading={timeSeriesResult.isLoading}>
              {items && components ? (
                <>
                  {bottomChartSelected === 'default' ? (
                    <TimeSeriesDefaultBottomChartComponent
                      items={items}
                      components={components}
                      height={mainHeight > 0 ? mainHeight - 110 : 180}
                      periodicity={periodicitySelected}
                    />
                  ) : null}

                  {bottomChartSelected.startsWith('trend-') ? (
                    <TimeSeriesTrendBottomChartComponent
                      items={items}
                      component={
                        components.filter(component => component.type === 'polynomial')[
                          Number(bottomChartSelected.split('-')[1])
                        ] as PolynomialComponent
                      }
                      height={mainHeight > 0 ? mainHeight - 110 : 180}
                    />
                  ) : null}

                  {bottomChartSelected === 'month' ? (
                    <TimeSeriesCalendarEffectBottomChartComponent
                      items={items}
                      representation='month'
                      height={mainHeight > 0 ? mainHeight - 110 : 180}
                      components={components.filter(
                        component => component.name === bottomChartSelected,
                      )}
                    />
                  ) : null}

                  {bottomChartSelected === 'weekday' ? (
                    <TimeSeriesCalendarEffectBottomChartComponent
                      items={items}
                      representation='weekday'
                      height={mainHeight > 0 ? mainHeight - 110 : 180}
                      components={components.filter(
                        component => component.name === bottomChartSelected,
                      )}
                    />
                  ) : null}

                  {bottomChartSelected === 'holiday' ? (
                    <TimeSeriesCalendarEffectBottomChartComponent
                      items={items}
                      representation='holiday'
                      height={mainHeight > 0 ? mainHeight - 110 : 180}
                      components={components.filter(
                        component => component.name === bottomChartSelected,
                      )}
                    />
                  ) : null}

                  {bottomChartSelected === 'dayOfMonth' ? (
                    <TimeSeriesCalendarEffectBottomChartComponent
                      items={items}
                      representation='dayOfMonth'
                      height={mainHeight > 0 ? mainHeight - 110 : 180}
                      components={components.filter(
                        component => component.name === bottomChartSelected,
                      )}
                    />
                  ) : null}

                  {bottomChartSelected.startsWith('harmonic-') ? (
                    <TimeSeriesHarmonicBottomChartComponent
                      items={items}
                      component={
                        components.filter(component => component.type === 'harmonic')[
                          Number(bottomChartSelected.split('-')[1])
                        ] as HarmonicComponent
                      }
                      height={mainHeight > 0 ? mainHeight - 110 : 180}
                    />
                  ) : null}

                  {bottomChartSelected === 'holidayImpact' ? (
                    <TimeSeriesLatestImpactBottomChartComponent
                      items={items}
                      height={mainHeight > 0 ? mainHeight - 110 : 180}
                      representation='holiday'
                    />
                  ) : null}

                  {bottomChartSelected === 'weekImpact' ? (
                    <TimeSeriesLatestImpactBottomChartComponent
                      items={items}
                      height={mainHeight > 0 ? mainHeight - 110 : 180}
                      representation='weekday'
                    />
                  ) : null}

                  {bottomChartSelected === 'monthImpact' ? (
                    <TimeSeriesLatestImpactBottomChartComponent
                      items={items}
                      height={mainHeight > 0 ? mainHeight - 110 : 180}
                      representation='month'
                    />
                  ) : null}

                  {bottomChartSelected === 'monthDaysImpact' ? (
                    <TimeSeriesLatestImpactBottomChartComponent
                      items={items}
                      height={mainHeight > 0 ? mainHeight - 110 : 180}
                      representation='dayOfMonth'
                    />
                  ) : null}

                  {bottomChartSelected === 'dayOfYearImpact' ? (
                    <TimeSeriesLatestImpactBottomChartComponent
                      items={items}
                      height={mainHeight > 0 ? mainHeight - 100 : 180}
                      representation='dayOfYear'
                      isCompressed={isCompressed}
                    />
                  ) : null}
                </>
              ) : null}
            </Handle>
          </main>
        </ModalWrapper>
      </Modal>
    </Wrapper>
  )
}

/* globals caches */
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { colors } from '~/design'
import { AppError } from '~/prix'
import { QueryBase } from '~/prix/query'
import { FilterHighlight } from '../legalEntityGeoprocessingMapLevels.data'
import MapMenuItem from './legalEntityGeoprocessingMapMenuItem.component'
import CustomizedMapMenu from './legalEntityGeoprocessingCustomizedMapMenu.component'
import DefinedOptionMapMenu from './legalEntityGeoprocessingDefinedOptionMapMenu.component'
import OriginalLink from '~/prix/react/components/link'
import {
  DefinedOption,
  allLegalEntityGeoProcessingMapMenuItems,
  customizedLegalEntityGeoProcessingMapMenuItems,
  legalEntityGeoprocessingMapMenuItems
} from './legalEntityGeoprocessingMapMenu.data'

const MenuCardsWrapper = styled.div`
  display: flex;
  flex-direction: column;
`
export const LegendLabelBackground = styled.div`
  flex: 1;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
`

export const Link = styled(OriginalLink)`
  text-decoration: none;
  color: ${colors.sebraeBlue};
  padding: 5px 7.5px 5px 7.5px;
  transition-duration: 0.3s;
  text-align: center;
  border-radius: 5px;

  font-weight: 600;
  font-size: 16px;
  cursor: pointer;

  span {
    margin-left: 5px;
  }

  &:hover {
    color: ${colors.oceanBlue};
      
    svg {
      fill : ${colors.oceanBlue};
    }
  }
`

const PresetWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding: 0 20px;

  .containers {
    display: flex;
    flex: 1;
    flex-direction: column;
    margin: 16px 8px 16px 8px;
  }
`

const PresetButton = styled.button`
  display: flex;
  text-decoration: none;
  text-align: center;
  justify-content: center;
  width: 100%;
  padding: 16px 18px;
  margin: 12px 0 0 0;
  border-radius: 12px;
  border: none;
  box-shadow: 0px 16px 15px 0px rgba(20, 46, 82, 0.20);

  background-color: #005EB8;
  color: #fff;

  font-size: 18px;
  font-weight: 700;

  transition-duration: 0.3s;

  /* transition-duration: all 0.5s ease-in-out; */
  cursor: pointer;

  &:hover {
    background-color: #0F438A;
    color: #FFF;
  }
`

interface MapMenu {
  handleOptionMapMenu: any
  onChangeFilter: (newFilter: FilterHighlight | null) => void
  onChangeHighlight: (newHighlight: FilterHighlight | null) => void
  filter: { type: string; name?: string; value?: string | boolean | null } | null
  highlight: FilterHighlight | null | undefined
  countSum: number | null
  highlightSum?: string | number | true | object | null
  isLoading: boolean
  isHeatmapEnabled: boolean
  loadingMessage?: QueryBase[]
  toggleMenuVisibility: () => void
  error?: AppError
  definedOption: DefinedOption | null
  isHighlightCensusEnabled: any
  isHighlightProfitsEnabled: any
  maxValue: number | null
  minValue: number | null
  minPercentColor: number | null
  maxPercentColor: number | null
  handleOutOfRangeEntities: (setOutOfRangeEntities: any) => void
  handleTotalEntities: (setTotalEntities: any) => void
}

export const scores = ['scoreOperationsManagement', 'scoreTransformation', 'scoreIndicatorsManagement', 'scoreInnovation', 'scoreMarketing', 'scoreSustainablePractices']
export const census = ['area', 'perCapta']
export const profits = ['profits', 'cost']

export default function MapMenu({
  handleOptionMapMenu,
  definedOption,
  onChangeFilter,
  filter,
  onChangeHighlight,
  highlight,
  countSum,
  highlightSum,
  isLoading,
  isHeatmapEnabled,
  isHighlightCensusEnabled,
  isHighlightProfitsEnabled,
  loadingMessage,
  toggleMenuVisibility,
  error,
  maxValue,
  minValue,
  maxPercentColor,
  minPercentColor,
  handleOutOfRangeEntities,
  handleTotalEntities,
}: MapMenu) {
  const [allItems, setAllItems] = useState<any | undefined>(undefined)
  const [items, setItems] = useState<any | undefined>(undefined)
  const [customItem, setCustomItem] = useState<any | undefined>(undefined)
  const [item, setItem] = useState<any | undefined>(undefined)

  useEffect(() => {
    setAllItems(allLegalEntityGeoProcessingMapMenuItems())
    setItems(legalEntityGeoprocessingMapMenuItems())
    setCustomItem(customizedLegalEntityGeoProcessingMapMenuItems)
  }, [])

  useEffect(() => {
    if (allItems && definedOption) {
      const definedItem = allItems.filter(
        (item: DefinedOption) => item.default === definedOption.default,
      )
      setItem(definedItem)
    }
  }, [allItems, definedOption])

  return (
    <>
      {definedOption === null && allItems && items && customItem ? (
        <MenuCardsWrapper>
          <MapMenuItem
            body={items}
            onChangeFilter={onChangeFilter}
            handleOptionMapMenu={handleOptionMapMenu}
            cardTitle='Filtros rápidos'
          />
          <PresetWrapper>
            <div className="containers">
              <PresetButton
                onClick={() => handleOptionMapMenu(customItem.default)}>
                <div>
                  <span>{customItem.title}</span>
                </div>
              </PresetButton>
            </div>
          </PresetWrapper>
        </MenuCardsWrapper>
      ) : null}

      {definedOption && definedOption.default !== 'customized' && item ? (
        <DefinedOptionMapMenu
          onChangeFilter={onChangeFilter}
          onChangeHighlight={onChangeHighlight}
          definedOption={item[0]}
          isLoading={isLoading}
          filter={filter}
          highlight={highlight}
          title={item[0].title}
          countSum={countSum}
          highlightSum={Number(highlightSum)}
          isHeatmapEnabled={isHeatmapEnabled}
          toggleMenuVisibility={toggleMenuVisibility}
          error={error}
          maxValue={maxValue ? maxValue : null}
          minValue={minValue ? minValue : null}
          maxPercentColor={minPercentColor ? maxPercentColor : null}
          minPercentColor={minPercentColor ? minPercentColor : null}
          handleOutOfRangeEntities={handleOutOfRangeEntities}
          handleTotalEntities={handleTotalEntities}
          isHighlightCensusEnabled={isHighlightCensusEnabled}
          isHighlightProfitsEnabled={isHighlightProfitsEnabled}
          loadingMessage={loadingMessage}
        />
      ) : null}

      {definedOption && definedOption.default === 'customized' ? (
        <CustomizedMapMenu
          onChangeFilter={onChangeFilter}
          filter={filter}
          onChangeHighlight={onChangeHighlight}
          highlight={highlight}
          countSum={countSum}
          highlightSum={highlightSum}
          isLoading={isLoading}
          isHeatmapEnabled={isHeatmapEnabled}
          isHighlightCensusEnabled={isHighlightCensusEnabled}
          isHighlightProfitsEnabled={isHighlightProfitsEnabled}
          loadingMessage={loadingMessage}
          toggleMenuVisibility={toggleMenuVisibility}
          error={error}
          maxValue={maxValue ? maxValue : null}
          minValue={minValue ? minValue : null}
          maxPercentColor={minPercentColor ? maxPercentColor : null}
          minPercentColor={minPercentColor ? minPercentColor : null}
          handleOutOfRangeEntities={handleOutOfRangeEntities}
          handleTotalEntities={handleTotalEntities}
        />
      ) : null}
    </>
  )
}
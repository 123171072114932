import {
  boolean,
  count,
  descending,
  entity,
  equals,
  isNull,
  number,
  query,
  string,
  sum,
  truthy,
} from '~/prix'

export default function disproportionateQuery({
  geoId = 30,
  stateAbbreviation,
  dataSourceId,
}: {
  geoId: number
  stateAbbreviation?: string | null
  dataSourceId?: number
}) {
  const legalEntitiesByCnaesQuery = query('disproportionateAttentionPublic')
    .select({
      cnaeId: entity('disproportionateAttentionPublic').property('cnaeId'),
      cnaeDescription: entity('disproportionateAttentionPublic').property('cnaeDescription'),
      publicCount: sum(entity('disproportionateAttentionPublic').property('count')),
    })
    [stateAbbreviation !== undefined ? 'join' : 'dummy']({
      current: entity('disproportionateAttentionPublic').property('stateId'),
      target: entity('state').property('id'),
      join: 'inner',
    })
    .where(
      ...[
        geoId === 30 && !stateAbbreviation
          ? equals(
              entity('disproportionateAttentionPublic').property('countryId'),
              number().value(geoId),
            )
          : stateAbbreviation
          ? equals(entity('state').property('abbreviation'), string().value(stateAbbreviation))
          : null,
      ].filter(truthy),
    )
    .order(descending('publicCount'))

  const attendanceQuery = query('disproportionateAttentionAttendances')
    .select({
      cnaeId: entity('disproportionateAttentionAttendances').property('cnaeId'),
      cnaeDescription: entity('disproportionateAttentionAttendances').property('cnaeDescription'),
      attendanceCount: sum(entity('disproportionateAttentionAttendances').property('count')),
    })
    [stateAbbreviation !== undefined ? 'join' : 'dummy']({
      current: entity('disproportionateAttentionAttendances').property('stateId'),
      target: entity('state').property('id'),
      join: 'inner',
    })
    .where(
      ...[
        dataSourceId && dataSourceId !== 0
          ? equals(
              entity('disproportionateAttentionAttendances').property('dataSourceId'),
              number().value(dataSourceId),
            )
          : null,

        geoId === 30 && !stateAbbreviation
          ? equals(
              entity('disproportionateAttentionAttendances').property('countryId'),
              number().value(geoId),
            )
          : stateAbbreviation
          ? equals(entity('state').property('abbreviation'), string().value(stateAbbreviation))
          : null,
      ].filter(truthy),
    )
    .order(descending('attendanceCount'))

  const legalEntityServedCountQuery = query('disproportionateAttentionPublic')
    .select({
      count: sum(entity('disproportionateAttentionPublic').property('count')),
    })
    .join({
      join: 'inner',
      current: entity('disproportionateAttentionPublic').property('stateId'),
      target: entity('state').property('id'),
    })
    .where(
      ...[
        stateAbbreviation
          ? equals(entity('state').property('abbreviation'), string().value(stateAbbreviation))
          : equals(
              entity('disproportionateAttentionPublic').property('countryId'),
              string().value('30'),
            ),
      ].filter(truthy),
    )
    .order(descending('count'))

  const portfolioEadQuery = query('legalEntity')
    .select({
      cnaeId: entity('legalEntity').property('mainCnaeId'),
      cnaeDescription: entity('cnae').property('description'),
      count: count(entity('legalEntity').property('mainCnaeId')),
    })
    .join({
      current: entity('legalEntity').property('id'),
      target: entity('legalEntityAttendance').property('legalEntityId'),
      join: 'inner',
    })
    .join({
      current: entity('legalEntityAttendance').property('id'),
      target: entity('legalEntityAttendanceDataSource').property('legalEntityAttendanceId'),
      join: 'inner',
    })
    .join({
      current: entity('legalEntity').property('id'),
      target: entity('courseRegisterIndividual').property('legalEntityId'),
      join: 'inner',
    })
    .join({
      current: entity('courseRegisterIndividual').property('courseProductId'),
      target: entity('courseProduct').property('id'),
      join: 'inner',
    })
    .join({
      current: entity('legalEntity').property('mainCnaeId'),
      target: entity('cnae').property('id'),
      join: 'inner',
    })
    .where(
      ...[
        isNull(entity('legalEntity').property('deletedAt')),
        equals(
          entity('legalEntityAttendanceDataSource').property('dataSourceId'),
          number().value(5),
        ),
        equals(entity('courseProduct').property('isActivePortfolio'), boolean().value(true)),
        stateAbbreviation
          ? equals(entity('legalEntity').property('state'), string().value(stateAbbreviation))
          : null,
      ].filter(truthy),
    )
    .order(descending('count'))

  return {
    publicQuery: legalEntitiesByCnaesQuery,
    attendanceQuery,
    legalEntityServedCountQuery,
    portfolioEadQuery,
  }
}

import { count, entity, equals, number, query, string } from '~/prix'
import asGeoJson from '~/prix/functions/asGeoJson'

export default function mesoRegionsQuery(by: 'mesoRegionId' | 'stateId', id: string) {
  switch (by) {
    case 'mesoRegionId':
      return query('mesoRegion')
        .select({
          id: entity('mesoRegion').property('id'),
          name: entity('mesoRegion').property('name'),
          boundary: asGeoJson(entity('mesoRegion').property('lowerQualityBoundary')),
          center: asGeoJson(entity('mesoRegion').property('center')),
          stateId: entity('state').property('id'),
        })
        .join({
          current: entity('mesoRegion').property('stateId'),
          target: entity('state').property('id'),
        })
        .where(equals(entity('mesoRegion').property('id'), string().value(id)))
        .limit(1)
    case 'stateId':
      return query('mesoRegion')
        .select({
          id: entity('mesoRegion').property('id'),
          name: entity('mesoRegion').property('name'),
          boundary: asGeoJson(entity('mesoRegion').property('lowerQualityBoundary')),
          center: asGeoJson(entity('mesoRegion').property('center')),
        })
        .where(equals(entity('mesoRegion').property('stateId'), string().value(id)))
        .limit(10000)
    default:
      throw new Error(`Invalid by: ${by} on mesoRegions query`)
  }
}

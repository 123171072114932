import { useMemo } from 'react'
import { FilterHighlight } from '~/packages/legalEntityGeoprocessing/map/legalEntityGeoprocessingMapLevels.data'
import useLegalEntityGeoprocessingStreetsMap from '~/packages/legalEntityGeoprocessing/map/legalEntityGeoprocessingStreetsMap.hook'
import countNeighborhoodLegalEntitiesQuery from '~/packages/neighborhood/countNeighborhoodLegalEntities.query'
import useItems from '~/prix/react/hooks/items'
import { DefinedOption } from '../legalEntityGeoprocessing/map/menu/legalEntityGeoprocessingMapMenu.data'
import { QueryBase } from '~/prix/query'

interface NeighborhoodLegalEntitiesOptions {
  id: string
  filter: FilterHighlight | null
  highlight: FilterHighlight | null
  childrenGeoLevel?: 'streets'
  isEnabled?: boolean
  definedOption: DefinedOption | null
}

const childrenGeoLevelOptions = {
  streets: 'Ruas',
}

export default function useNeighborhoodLegalEntities({
  id,
  filter,
  highlight,
  childrenGeoLevel = 'streets',
  isEnabled = true,
  definedOption,
}: NeighborhoodLegalEntitiesOptions) {
  const polygonsResult = useLegalEntityGeoprocessingStreetsMap({
    filter,
    highlight,
    isEnabled,
    childrenGeoLevel,
    id,
    by: 'neighborhoodId',
    definedOption,
  })

  const countResult = useItems(
    () =>
      isEnabled && definedOption
        ? (countNeighborhoodLegalEntitiesQuery({
          by: 'neighborhoodId',
          id,
          highlight,
          filter,
          definedOption,
        }) as QueryBase)
        : (null as never),
    [highlight, filter, childrenGeoLevel, id, definedOption],
    {
      cache: 60 * 60 * 12,
      autoLoad: isEnabled && definedOption !== null && polygonsResult?.isLoading === false,
    },
  )

  const { countSum, highlightSum } = useMemo(() => {
    if (
      definedOption &&
      polygonsResult.isLoading === false &&
      (definedOption.type === 'legalEntityAttendanceIndex' ||
        definedOption.type === 'legalEntityAttendance')
    ) {
      const legalEntityCount = polygonsResult.items
        ? polygonsResult.items.reduce((acc, item) => acc + (Number(item.count) ?? 0), 0)
        : null

      const attendanceCount = polygonsResult.items
        ? polygonsResult.items.reduce((acc, item) => acc + (Number(item.highlight) ?? 0), 0)
        : null

      return {
        countSum: legalEntityCount,
        highlightSum: attendanceCount,
      }
    }

    const countSum = countResult.items ? countResult.items[0]?.count : null
    const highlightSum = countResult.items ? countResult.items[0]?.highlight : null
    return {
      countSum,
      highlightSum,
    }
  }, [highlight, countResult.items, polygonsResult])

  return {
    ...polygonsResult,
    childrenGeoLevelOptions,
    childrenGeoLevel,
    countSum,
    highlightSum,
  }
}

import React, { useCallback, useEffect, useMemo, useState } from 'react'
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
  createSearchParams,
} from 'react-router-dom'
import useBreadcrumbs from './breadcrumbs.hook'
import LegalEntityGeoprocessingMap from './legalEntityGeoprocessingMap.screen'
import useAsync from 'react-use/lib/useAsync'
import pMap from 'p-map'
import canvasDrawToURL from '~/prix/utils/canvasDrawToURL'
import { contrastGradientColor } from '~/design'
import {
  FilterHighlight,
  GeoChildrenLegalEntitiesPossibilities,
  GeoLegalEntitiesPossibilities,
  geoQueries,
  Level,
} from './legalEntityGeoprocessingMapLevels.data'
import useAPI from '~/prix/react/hooks/api'
import { AppError, errors } from '~/prix'
import useItems from '~/prix/react/hooks/items'
import userAuthStateQuery from '~/packages/user/userAuthState.query'
import {
  DefinedOption,
  DefinedOptionMerged,
  allLegalEntityGeoProcessingMapMenuItems,
} from './menu/legalEntityGeoprocessingMapMenu.data'
import { useKeycloak } from '~/components/keycloak'

interface SearchParams {
  filterType?: string
  filterName?: string
  filterValue?: string
  filterAttendanceSource?: string
  filterEadOption?: string
  filterCourseProduct?: string
  highlightType?: string
  highlightName?: string
  highlightValue?: string
  highlightAttendanceSource?: string
  highlightEadOption?: string
  highlightCourseProduct?: string
  mapType?: string
  visualizationType?: string
  definedOption?: string
}

export const geoQueriesAux = {
  country: 'countries',
  macroRegion: 'macroRegions',
  state: 'states',
  mesoRegion: 'mesoRegions',
  microRegion: 'microRegions',
  city: 'cities',
  neighborhood: 'neighborhoods',
  street: 'streets',
  legalEntity: 'legalEntities',
}
const geoQueriesIdAux = {
  country: 'countryId',
  macroRegion: 'macroRegionId',
  state: 'stateId',
  mesoRegion: 'mesoRegionId',
  microRegion: 'microRegionId',
  city: 'cityId',
  neighborhood: 'cityId',
  street: 'streetId',
  legalEntity: 'legalEntityId',
}

export default function LegalEntityGeoprocessingMapFromURLScreen() {
  const { pathname: currentPathname } = useLocation()
  const navigate = useNavigate()
  const params = useParams()
  const [searchParams] = useSearchParams()
  const [level, setLevel] = useState<Level | null>(null)
  const [isHeatmapEnabled, setIsHeatmapEnabled] = useState<boolean | undefined>(false)
  const [error, setError] = useState<AppError>()

  const auth = useKeycloak()

  const temporaryCountryId = '30'

  const [items, setItems] = useState<any | undefined>(undefined)
  const [item, setItem] = useState<any | undefined>(undefined)
  const [definedOptionMerged, setDefinedOptionMerged] = useState<DefinedOptionMerged | null>(null)

  const { context, isLoading } = useAPI()
  const hasUserRoles = context.user
    ? !context.user?.roles.some(
        item => item === 'systemAdministrator' || item === 'administrator' || item === 'manager',
      )
    : false

  useEffect(() => {
    const error = errors.auth()
    if (auth.token === null && error) {
      setError(error)
    } else {
      setError(undefined)
    }
  }, [auth])

  const userGeoStateResult = useItems(
    () =>
      context.user?.stateAbbreviation
        ? userAuthStateQuery(context.user?.stateAbbreviation)
        : (null as never),
    [params.id, context.user, isLoading],
    {
      cache: 60 * 60 * 24 * 7,
      autoLoad: isLoading === false && error === undefined,
    },
  )

  const searchGeoStateOption = useItems(
    () =>
      params.by && hasUserRoles
        ? geoQueries[geoQueriesAux[params.by] as keyof typeof geoQueriesAux](
            geoQueriesIdAux[params.by as unknown as keyof typeof geoQueriesIdAux],
            params.id,
          )
        : (null as never),
    [params.id, params.by, hasUserRoles],
    {
      cache: 60 * 60 * 12,
      autoLoad: !!params.id && hasUserRoles === true && error === undefined,
    },
  )

  useEffect(() => {
    const stateId =
      level && level.geo !== 'state' && searchGeoStateOption.items
        ? searchGeoStateOption.items[0]?.stateId
        : level && level.geo === 'state' && searchGeoStateOption.items
        ? searchGeoStateOption.items[0]?.id
        : undefined

    if (
      error === undefined &&
      hasUserRoles === true &&
      stateId &&
      userGeoStateResult.items &&
      userGeoStateResult.items[0] &&
      Number(stateId.toString().length) === 2 &&
      userGeoStateResult.items[0]?.stateId !== stateId
    ) {
      const error = errors.permission()
      setError(error)
    }
  }, [level, userGeoStateResult, searchGeoStateOption, hasUserRoles, error])

  useEffect(() => {
    if (hasUserRoles === true && (params.by === 'country' || params.by === 'macroRegion')) {
      const error = errors.permission()
      setError(error)
    }

    if (params.by && params.id) {
      setLevel({
        geo: params.by as GeoLegalEntitiesPossibilities,
        id: params.id,
        childrenGeoLevel: params.childrenGeoLevel as
          | GeoChildrenLegalEntitiesPossibilities
          | undefined,
      })
      return
    }

    if (
      hasUserRoles === true &&
      userGeoStateResult &&
      userGeoStateResult.items &&
      !params.by &&
      !params.id
    ) {
      setLevel({
        geo: 'state',
        id: String(userGeoStateResult.items[0]?.stateId),
        childrenGeoLevel: undefined,
      })
      return
    }

    setLevel({
      geo: 'country',
      id: temporaryCountryId,
      childrenGeoLevel: params.childrenGeoLevel as
        | GeoChildrenLegalEntitiesPossibilities
        | undefined,
    })
  }, [params, hasUserRoles, userGeoStateResult.items])

  const breadcrumbsResult = useBreadcrumbs({
    geo: level?.geo ?? 'country',
    id: level?.id ?? null,
    roles: hasUserRoles,
  })

  const [validLevel, selectedLegalEntityId, title] = useMemo(() => {
    const lastGeoLevel = breadcrumbsResult.items
      ? breadcrumbsResult.items[breadcrumbsResult.items.length - 1] ?? {
          geo: 'country',
          id: null,
        }
      : null
    if (level?.geo === 'legalEntity' && !breadcrumbsResult.isLoading && breadcrumbsResult.items) {
      return [lastGeoLevel, level.id, undefined]
    }
    if (level?.geo === 'legalEntity') {
      return [null, level.id, undefined]
    }
    return [level, null, lastGeoLevel?.title]
  }, [level, breadcrumbsResult])

  const breadcrumbs = useMemo(
    () =>
      breadcrumbsResult.items && breadcrumbsResult.items.length
        ? breadcrumbsResult.items
        : [
            {
              ...(validLevel ?? { geo: 'country', id: null }),
              title: '',
              subTitle: '',
            },
          ],
    [breadcrumbsResult, validLevel],
  )

  const handleNavigate = useCallback(
    (newLevel: Level | null = null, newSearchParams: SearchParams = {}) => {
      const newLevelOrPrevious = newLevel ?? level

      let pathname = currentPathname
      if (newLevelOrPrevious && newLevelOrPrevious.geo === 'country' && !newLevelOrPrevious.id) {
        pathname = `/app/map${
          newLevelOrPrevious.childrenGeoLevel
            ? `/${newLevelOrPrevious.geo}/${temporaryCountryId}/${newLevelOrPrevious.childrenGeoLevel}`
            : ''
        }`
      } else if (newLevelOrPrevious) {
        pathname = `/app/map/${newLevelOrPrevious.geo}/${newLevelOrPrevious.id}${
          newLevelOrPrevious.childrenGeoLevel ? `/${newLevelOrPrevious.childrenGeoLevel}` : ''
        }`
      }

      const paramsObject = {
        mapType: searchParams.get('mapType') || '',
        visualizationType: searchParams.get('visualizationType') || '',
        filterType: searchParams.get('filterType') || '',
        filterName: searchParams.get('filterName') || '',
        filterValue: searchParams.get('filterValue') || '',
        filterAttendanceSource: searchParams.get('filterAttendanceSource') || '',
        filterEadOption: searchParams.get('filterEadOption') || '',
        filterCourseProduct: searchParams.get('filterCourseProduct') || '',
        highlightType: searchParams.get('highlightType') || '',
        highlightName: searchParams.get('highlightName') || '',
        highlightValue: searchParams.get('highlightValue') || '',
        definedOption: searchParams.get('definedOption') || '',
        highlightAttendanceSource: searchParams.get('highlightAttendanceSource') || '',
        highlightEadOption: searchParams.get('highlightEadOption') || '',
        highlightCourseProduct: searchParams.get('highlightCourseProduct') || '',
        ...newSearchParams,
      }

      Object.keys(paramsObject).forEach(key => {
        if (!paramsObject[key as keyof typeof paramsObject]) {
          delete paramsObject[key as keyof typeof paramsObject]
        }
      })

      // const definedOptionData = allLegalEntityGeoProcessingMapMenuItems().find(
      //   item => item.default === paramsObject.definedOption,
      // )
      // if (definedOptionData && definedOptionData.default !== 'customized') {
      //   paramsObject.highlightType = definedOptionData.filterType || ''
      //   paramsObject.highlightValue = definedOptionData.filterValue || ''
      // }

      const search = createSearchParams(paramsObject)
      navigate({
        pathname,
        search: search.toString(),
      })
    },
    [level, searchParams, currentPathname, hasUserRoles],
  )

  const handleChangeLevel = useCallback(
    ({ geo, id, childrenGeoLevel }: Level) => {
      handleNavigate({ geo, id, childrenGeoLevel })
    },
    [handleNavigate],
  )
  const handleChangeChildrenGeoLevel = useCallback(
    (childrenGeoLevel: GeoChildrenLegalEntitiesPossibilities | undefined) => {
      handleNavigate({ geo: level?.geo ?? 'country', id: level?.id ?? null, childrenGeoLevel })
    },
    [handleNavigate, level],
  )

  const definedOption = useMemo(() => {
    const definedOption = searchParams.get('definedOption')
    const filterType = searchParams.get('filterType')
    const filterName = searchParams.get('filterName')
    const filterValue = searchParams.get('filterValue')

    return definedOption
      ? ({
          default: definedOption,
          filterType,
          filterName,
          filterValue,
        } as any)
      : null
  }, [searchParams, handleNavigate])

  const handleDefinedOptionMapMenu = useCallback(
    (type: any) => {
      handleNavigate(undefined, {
        definedOption: type ?? '',
      })
    },
    [searchParams, handleNavigate],
  )

  // Filter
  const filter = useMemo(() => {
    const filterType = searchParams.get('filterType')
    const filterName = searchParams.get('filterName')
    const filterValue = searchParams.get('filterValue')
    const filterAttendanceSource = searchParams.get('filterAttendanceSource')
    const filterEadOption = searchParams.get('filterEadOption')
    const filterCourseProduct = searchParams.get('filterCourseProduct')

    return filterType
      ? ({
          type: filterType,
          name: filterName,
          value: filterValue,
          attendanceSource: filterAttendanceSource,
          eadOption: filterEadOption,
          courseProduct: filterCourseProduct,
        } as FilterHighlight)
      : null
  }, [searchParams])

  const handleFilterChange = useCallback(
    (filter: FilterHighlight | null) => {
      handleNavigate(undefined, {
        filterType: filter && filter.type ? filter.type : '',
        filterName: filter && filter.name ? filter.name : '',
        filterValue: filter && filter.value ? String(filter.value) : '',
        filterEadOption: filter && filter.eadOption ? String(filter.eadOption) : '',
        filterAttendanceSource:
          filter && filter.attendanceSource ? String(filter.attendanceSource) : '',
        filterCourseProduct: filter && filter.courseProduct ? String(filter.courseProduct) : '',
      })
    },
    [searchParams, handleNavigate],
  )

  // Highlight
  const highlight = useMemo(() => {
    const highlightType = searchParams.get('highlightType')
    const highlightName = searchParams.get('highlightName')
    const highlightValue = searchParams.get('highlightValue')
    const highlightAttendanceSource = searchParams.get('highlightAttendanceSource')
    const highlightEadOption = searchParams.get('highlightEadOption')
    const highlightCourseProduct = searchParams.get('highlightCourseProduct')

    return highlightType
      ? ({
          type: highlightType,
          name: highlightName,
          value: highlightValue,
          attendanceSource: highlightAttendanceSource,
          eadOption: highlightEadOption,
          courseProduct: highlightCourseProduct,
        } as FilterHighlight)
      : null
  }, [searchParams])

  const handleHighlightChange = useCallback(
    (highlight: FilterHighlight | null) => {
      handleNavigate(undefined, {
        highlightType: highlight && highlight.type ? highlight.type : '',
        highlightName: highlight && highlight.name ? highlight.name : '',
        highlightValue: highlight && highlight.value ? String(highlight.value) : '',
        highlightAttendanceSource:
          highlight && highlight.attendanceSource ? String(highlight.attendanceSource) : '',
        highlightEadOption: highlight && highlight.eadOption ? String(highlight.eadOption) : '',
        highlightCourseProduct:
          highlight && highlight.courseProduct ? String(highlight.courseProduct) : '',
      })
    },
    [searchParams, handleNavigate],
  )

  // Map type
  const mapTypeId = (searchParams.get('mapType') as 'satellite' | 'map') || 'satellite'
  const handleMapTypeIdChange = useCallback(
    (mapTypeId: 'satellite' | 'map') => {
      handleNavigate(undefined, {
        mapType: mapTypeId,
      })
    },
    [searchParams, handleNavigate],
  )

  // Visualization type
  const visualizationTypeId =
    (searchParams.get('visualizationType') as 'chloroplethic' | 'heatmap') || 'chloroplethic'

  const handleVisualizationTypeIdChange = useCallback(
    (visualizationTypeId: 'chloroplethic' | 'heatmap') => {
      handleNavigate(undefined, {
        visualizationType: visualizationTypeId,
        highlightType:
          highlight && highlight.type && visualizationTypeId === 'chloroplethic'
            ? highlight.type
            : '',
        highlightName:
          highlight && highlight.name && visualizationTypeId === 'chloroplethic'
            ? highlight.name
            : '',
        highlightValue:
          highlight && highlight.value && visualizationTypeId === 'chloroplethic'
            ? String(highlight.value)
            : '',
      })
    },
    [searchParams, handleNavigate],
  )

  const paramsWithoutShapesNoData = useMemo(() => {
    const isHighlightCensus =
      (searchParams.get('highlightType') === 'area' &&
        searchParams.get('highlightValue') === 'censusUpdated') ||
      (searchParams.get('highlightType') === 'perCapta' &&
        searchParams.get('highlightValue') === 'censusUpdated')
    const isHighlightProfits =
      searchParams.get('highlightType') === 'profits' ||
      searchParams.get('highlightType') === 'cost'
    const isHighlightScores =
      searchParams.get('highlightType') === 'scoreOperationsManagement' ||
      searchParams.get('highlightType') === 'scoreTransformation' ||
      searchParams.get('highlightType') === 'scoreIndicatorsManagement' ||
      searchParams.get('highlightType') === 'scoreInnovation' ||
      searchParams.get('highlightType') === 'scoreMarketing' ||
      searchParams.get('highlightType') === 'scoreSustainablePractices'

    return isHighlightCensus || isHighlightProfits || isHighlightScores
  }, [searchParams])

  const isHighlightCensus =
    (searchParams.get('highlightType') === 'area' &&
      searchParams.get('highlightValue') === 'censusUpdated') ||
    (searchParams.get('highlightType') === 'perCapta' &&
      searchParams.get('highlightValue') === 'censusUpdated')

  //Canvas
  const arcXY =
    isHighlightCensus && level && level.geo === 'city'
      ? null
      : level && (level.geo === 'street' || level.geo === 'legalEntity')
      ? 30
      : 15
  const scale =
    isHighlightCensus && level && level.geo === 'city'
      ? null
      : level && (level.geo === 'street' || level.geo === 'legalEntity')
      ? 24
      : 12
  const heightAndWidth = level && (level.geo === 'street' || level.geo === 'legalEntity') ? 60 : 30
  const strokeLineWidth = level && (level.geo === 'street' || level.geo === 'legalEntity') ? 2 : 3
  const markerVariationsCount = 11
  const markerVariations = useAsync(
    () =>
      pMap(Array.from(Array(markerVariationsCount)), (value, index) =>
        canvasDrawToURL(
          context => {
            context.fillStyle =
              (isHeatmapEnabled === true &&
                (mapTypeId !== 'map' || (level && level.geo === 'street'))) ||
              (index === 0 && paramsWithoutShapesNoData)
                ? '#FFF'
                : (isHeatmapEnabled === true && mapTypeId === 'map') ||
                  (index === 0 && paramsWithoutShapesNoData)
                ? '#8c8c8c'
                : contrastGradientColor(index / markerVariationsCount).hex('rgb')

            context.arc(arcXY, arcXY, scale, 0, 2 * Math.PI)
            context.globalAlpha =
              (isHeatmapEnabled === true &&
                (mapTypeId !== 'map' ||
                  (level && level.geo === 'street') ||
                  (level && level.geo === 'legalEntity'))) ||
              (index === 0 && paramsWithoutShapesNoData)
                ? 0.65
                : 1
            context.fill()
            context.strokeStyle =
              isHeatmapEnabled === true && mapTypeId === 'map' && level && level.geo === 'street'
                ? '#8c8c8c'
                : '#fff'
            context.lineWidth = strokeLineWidth
            context.stroke()
          },
          { width: heightAndWidth, height: heightAndWidth },
        ),
      ),
    [isHeatmapEnabled, markerVariationsCount, level],
  )

  const getMarkerVariationForValue = useCallback(
    value =>
      markerVariations.value && markerVariations.loading === false
        ? markerVariations.value[
            Math.min(
              Math.max(0, Math.floor(value * (markerVariationsCount - 1))),
              markerVariationsCount - 1,
            )
          ]
        : null,
    [markerVariations.value, markerVariations.loading, markerVariationsCount],
  )

  useEffect(() => {
    setItems(allLegalEntityGeoProcessingMapMenuItems())
  }, [])

  useEffect(() => {
    if (items && definedOption) {
      const definedItem = items.filter((item: any) => item.default === definedOption.default)
      setItem(definedItem)
    }

    if (definedOption === null) {
      setItem(null)
    }
  }, [items, definedOption])

  //merge: item (URL data) + definedOption (data.ts)
  useEffect(() => {
    if (item && item[0].default && definedOption && item[0].default === definedOption.default) {
      const merge = Object.assign(item[0], definedOption)
      setDefinedOptionMerged(merge)
    }

    if (definedOption === null) {
      setDefinedOptionMerged(null)
    }
  }, [item, definedOption])

  return (
    <LegalEntityGeoprocessingMap
      handleOptionMapMenu={handleDefinedOptionMapMenu}
      definedOption={definedOptionMerged}
      title={title}
      breadcrumbs={breadcrumbs}
      selectedLegalEntityId={selectedLegalEntityId}
      mapTypeId={mapTypeId}
      onMapTypeIdChange={handleMapTypeIdChange}
      onVisualizationTypeIdChange={handleVisualizationTypeIdChange}
      visualizationTypeIdUrl={visualizationTypeId}
      filter={filter}
      onFilterChange={handleFilterChange}
      highlight={highlight}
      onHighlightChange={handleHighlightChange}
      level={validLevel}
      onLevelChange={handleChangeLevel}
      onChildrenGeoLevelChange={handleChangeChildrenGeoLevel}
      markerVariationForValue={getMarkerVariationForValue}
      markerVariationLoading={markerVariations.loading}
      setHeatmapEnabledUrl={setIsHeatmapEnabled}
      userGeoState={userGeoStateResult}
      hasUserRoles={hasUserRoles}
      error={error}
    />
  )
}

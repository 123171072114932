import { defineAction, number, object, string } from '~/prix'
import agentLocationMatchRecommendedAction from './agentLocationMatchRecommended.action.json'

const agentLocationMatchRecommendedDefinition = defineAction({
  ...agentLocationMatchRecommendedAction,
  input: object({
    cpf: string(),
    longitude: number(),
    latitude: number(),
    limit: number().isOptional(),
  }),
  output: object({}),
})

export default agentLocationMatchRecommendedDefinition

import { useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import { contrastGradientColor } from '~/design'
import {
  FilterHighlight,
  GeoChildrenLegalEntitiesPossibilities,
  GeoItem,
  GeoLegalEntitiesPossibilities,
  geoLevelToGeoprocessingColumn,
  geoQueries,
  Level,
} from '~/packages/legalEntityGeoprocessing/map/legalEntityGeoprocessingMapLevels.data'
import { formatAsPercentage } from '~/prix'
import useGoogleMaps from '~/prix/react/hooks/googleMaps'
import useItems from '~/prix/react/hooks/items'
import {
  GeoJsonLineString,
  GeoJsonMultiPolygon,
  GeoJsonPoint,
  GeoJsonMultiLineString,
} from '~/prix/types/geoJson'
import { DefinedOption } from './menu/legalEntityGeoprocessingMapMenu.data'
import { GeoJSONObject } from '@turf/helpers'

export default function useLegalEntityGeoprocessingStreetsMap({
  childrenGeoLevel,
  id,
  by,
  filter,
  highlight,
  isEnabled,
  definedOption,
}: {
  childrenGeoLevel: 'streets'
  by: 'neighborhoodId'
  id: string | null
  filter: FilterHighlight | null
  highlight: FilterHighlight | null
  isEnabled: boolean
  definedOption: DefinedOption | null
}) {
  const { google } = useGoogleMaps()
  const [items, setItems] = useState<Array<GeoItem> | null>(null)
  const [lastStreets, setLastStreets] = useState(null)
  const [streets, setStreets] = useState(null)
  const params = useParams()
  const [level, setLevel] = useState<Level | null>(null)

  const streetsResult = useItems(
    () =>
      isEnabled
        ? ((geoQueries[childrenGeoLevel] as any)(by, id!) as ReturnType<
            (typeof geoQueries)['streets']
          >)
        : (null as never),
    [childrenGeoLevel, id, by, isEnabled],
    { cache: 60 * 60 * 12, autoLoad: isEnabled },
  )

  useEffect(() => {
    if (
      (isEnabled && lastStreets === null && streetsResult.items !== null) ||
      (lastStreets !== null && streetsResult.items !== lastStreets)
    ) {
      setStreets(streetsResult.items)
      setLastStreets(streetsResult.items)
    }

    if (isEnabled === false) {
      setStreets(null)
    }
  }, [lastStreets, streetsResult?.items, isEnabled])

  useEffect(() => {
    if (params.by && params.id) {
      setLevel({
        geo: params.by as GeoLegalEntitiesPossibilities,
        id: params.id,
        childrenGeoLevel: params.childrenGeoLevel as
          | GeoChildrenLegalEntitiesPossibilities
          | undefined,
      })
      return
    }

    setLevel({
      geo: 'country',
      id: '30',
      childrenGeoLevel: params.childrenGeoLevel as GeoChildrenLegalEntitiesPossibilities,
    })
  }, [params])

  const isHighlightMode =
    (highlight?.type &&
      highlight?.value !== undefined &&
      highlight?.value !== null &&
      highlight?.value !== '') ||
    definedOption?.type === 'legalEntityAttendanceIndex'

  const definedQuery = definedOption ? definedOption.query : null

  const legalEntitiesGeoprocessingGroupedByStreet = useItems(
    () =>
      isEnabled && definedQuery
        ? definedQuery({
            groupColumn: geoLevelToGeoprocessingColumn[childrenGeoLevel],
            idColumn: by,
            id,
            filter,
            highlight,
          })
        : (null as never),
    [childrenGeoLevel, by, id, filter?.value, highlight?.value, isEnabled, definedQuery],
    {
      cache: 60 * 60 * 24,
      autoLoad: isEnabled && definedQuery !== null,
    },
  )

  const maxValue = useMemo(() => {
    if (
      legalEntitiesGeoprocessingGroupedByStreet.items &&
      definedOption?.type !== 'legalEntityAttendanceIndex'
    ) {
      const result = Math.max(
        0,
        ...legalEntitiesGeoprocessingGroupedByStreet.items.map(item => Number(item.count)),
      )
      return result
    }

    if (
      legalEntitiesGeoprocessingGroupedByStreet.items &&
      definedOption?.type === 'legalEntityAttendanceIndex'
    ) {
      const result = Math.max(
        0,
        ...legalEntitiesGeoprocessingGroupedByStreet.items.map(
          item => Number(item.highlight) / Number(item.count),
        ),
      )
      return result
    }

    if (legalEntitiesGeoprocessingGroupedByStreet.items && !isHighlightMode) {
      return Math.max(
        0,
        ...legalEntitiesGeoprocessingGroupedByStreet.items.map(item => {
          if (typeof item.count === 'number') {
            return Number(item.count)
          }
          return 0
        }),
      )
    }
    if (legalEntitiesGeoprocessingGroupedByStreet.items && isHighlightMode) {
      return Math.max(
        0,
        ...legalEntitiesGeoprocessingGroupedByStreet.items.map(item => {
          if (typeof item.count === 'number') {
            return Number(item.highlight / item.count)
          }
          return 0
        }),
      )
    }
    if (!legalEntitiesGeoprocessingGroupedByStreet.items) {
      return -Infinity
    }
  }, [legalEntitiesGeoprocessingGroupedByStreet.items])

  const minValue = useMemo(() => {
    if (legalEntitiesGeoprocessingGroupedByStreet.items && !isHighlightMode) {
      return Math.min(
        Infinity,
        ...legalEntitiesGeoprocessingGroupedByStreet.items.map(item => {
          if (typeof item.count === 'number') {
            return Number(item.count)
          }
          return Infinity
        }),
      )
    }
    if (legalEntitiesGeoprocessingGroupedByStreet.items && isHighlightMode) {
      return Math.min(
        Infinity,
        ...legalEntitiesGeoprocessingGroupedByStreet.items.map(item => {
          if (typeof item.count === 'number' && item.count > 0) {
            return Number(item.highlight / item.count)
          }
          return Infinity
        }),
      )
    }
    return Infinity
  }, [legalEntitiesGeoprocessingGroupedByStreet.items, isHighlightMode])

  const legalEntitiesGeoprocessingGroupedByStreetMap = useMemo(() => {
    const items = legalEntitiesGeoprocessingGroupedByStreet?.items
    if (!items) {
      return null
    }
    type Item = (typeof items)[0]
    return items.reduce((acc, next) => {
      acc.set(next.geoId as string | number, next)
      return acc
    }, new Map<string | number, Item>())
  }, [legalEntitiesGeoprocessingGroupedByStreet?.items])

  const bounds = useMemo(() => {
    const items = streets
    if (!items || !isEnabled) {
      return null
    }
    let south = Infinity
    let west = Infinity
    let north = -Infinity
    let east = -Infinity

    for (const item of items) {
      const multiPolygon = item.boundary as GeoJsonMultiPolygon
      const point = item.center as GeoJsonPoint
      const lineString = item.path as GeoJsonLineString
      const collection = item.collection as GeoJsonMultiLineString | any

      if (multiPolygon && multiPolygon.type === 'MultiPolygon') {
        for (const boundary of multiPolygon.coordinates) {
          const coordinates = boundary[0]
          for (const [longitude, latitude] of coordinates) {
            if (south > latitude) {
              south = latitude
            }
            if (north < latitude) {
              north = latitude
            }
            if (west > longitude) {
              west = longitude
            }
            if (east < longitude) {
              east = longitude
            }
          }
        }
      }
      if (point && point.type === 'Point') {
        const [longitude, latitude] = point.coordinates
        if (south > latitude) {
          south = latitude
        }
        if (north < latitude) {
          north = latitude
        }
        if (west > longitude) {
          west = longitude
        }
        if (east < longitude) {
          east = longitude
        }
      }

      if (lineString && lineString.type === 'LineString') {
        const coordinates = lineString.coordinates
        for (const [longitude, latitude] of coordinates) {
          if (south > latitude) {
            south = latitude
          }
          if (north < latitude) {
            north = latitude
          }
          if (west > longitude) {
            west = longitude
          }
          if (east < longitude) {
            east = longitude
          }
        }
      }

      if (collection && collection.type === 'MultiLineString') {
        for (const lineString of collection.coordinates) {
          for (const [longitude, latitude] of lineString) {
            if (south > latitude) {
              south = latitude
            }
            if (north < latitude) {
              north = latitude
            }
            if (west > longitude) {
              west = longitude
            }
            if (east < longitude) {
              east = longitude
            }
          }
        }
      }
    }

    if (!isFinite(south) || !isFinite(north) || !isFinite(east) || !isFinite(west)) {
      return null
    }

    return {
      south,
      west,
      north,
      east,
    }
  }, [streets, isEnabled])

  const googleBounds = useMemo(() => {
    if (!google || !bounds) {
      return null
    }
    const southWest = new google.maps.LatLng(bounds.south, bounds.west)
    const northEast = new google.maps.LatLng(bounds.north, bounds.east)
    return new google.maps.LatLngBounds(southWest, northEast)
  }, [google, bounds?.east, bounds?.west, bounds?.north, bounds?.south])

  useEffect(() => {
    if (
      !isEnabled ||
      !google ||
      !streets ||
      !legalEntitiesGeoprocessingGroupedByStreetMap ||
      !isFinite(maxValue)
    ) {
      return
    }
    setItems(
      streets.map(street => {
        const legalEntity = legalEntitiesGeoprocessingGroupedByStreetMap.get(street.id as string)
        const countValue = legalEntity?.count ?? 0
        const highlightValue = isHighlightMode ? ((legalEntity?.highlight || 0) as number) : null
        const percent =
          highlightValue !== null && countValue !== 0 ? highlightValue / countValue : countValue
        const centerGeoJson = street.center as { coordinates: number[] } | null

        const percentColor =
          highlightValue !== null ? percent : maxValue !== 0 ? countValue / maxValue : 0

        const percentColorForIndex =
          highlightValue && maxValue ? highlightValue / countValue / maxValue : 0

        const color = contrastGradientColor(
          definedOption?.type !== 'legalEntityAttendanceIndex'
            ? percentColor
            : percentColorForIndex,
        )

        // const color = contrastGradientColor(percentColor)
        //Porcentagens de valor mínimo e máximo para legenda de destaques
        const maxLegendValue =
          maxValue && isHighlightMode ? `${formatAsPercentage(maxValue)}` : maxValue

        const minLegendValue =
          (minValue && isHighlightMode) || (minValue === 0 && isHighlightMode)
            ? `${formatAsPercentage(minValue)}`
            : minValue

        return {
          id: street.id,
          key: street.wayId,
          name: street.name,
          multiPolygon: street.boundary,
          path: street.collection[0],
          center: centerGeoJson
            ? new google.maps.LatLng(centerGeoJson.coordinates[1], centerGeoJson.coordinates[0])
            : null,
          color: color.hex('rgb'),
          borderColor: color.darken(0.2).hex('rgb'),
          percentColor:
            definedOption?.type !== 'legalEntityAttendanceIndex'
              ? percentColor
              : percentColorForIndex,
          count: countValue,
          highlight: highlightValue,
          levelKey: childrenGeoLevel,
          collection: street.collection,
          legendValues: highlight?.value
            ? [{ maxValue: 100, minValue: 0 }]
            : [
                {
                  maxValue: maxLegendValue,
                  minValue: minLegendValue,
                },
              ],
        }
      }) as unknown as typeof items,
    )
  }, [
    legalEntitiesGeoprocessingGroupedByStreetMap,
    streetsResult?.items,
    streetsResult.isLoading,
    legalEntitiesGeoprocessingGroupedByStreet.isLoading,
    streets,
    google,
    maxValue,
    isEnabled,
  ])

  return {
    items: isEnabled && definedOption ? items : null,
    bounds: googleBounds,
    isLoading: streetsResult.isLoading || legalEntitiesGeoprocessingGroupedByStreet.isLoading,
    error:
      legalEntitiesGeoprocessingGroupedByStreet.error ||
      legalEntitiesGeoprocessingGroupedByStreet.error,
    queries: isEnabled
      ? [streetsResult.query, legalEntitiesGeoprocessingGroupedByStreet.query]
      : [],
  }
}

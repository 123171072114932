import { initializeApp } from 'firebase/app'
import { getToken } from 'firebase/messaging'
import { getMessaging } from "firebase/messaging/sw"
import { Dispatch, SetStateAction } from 'react'
import env from '~/config.json'

export const app = initializeApp(env.services.firebaseCredentials)
export const messaging = getMessaging(app)

export const getTokens = async (setTokenFound: Dispatch<SetStateAction<string | null>>) => {
  return getToken(messaging, { vapidKey: env.services.VAPID_KEY }).then((currentToken) => {
    if (currentToken) {
      setTokenFound(currentToken)
    } else {
      console.log('No registration token available. Request permission to generate one.')
      setTokenFound(null)
    }
  }).catch((err) => {
    console.log('An error occurred while retrieving token. ', err)
  })
}
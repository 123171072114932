import { count, entity, equals, number, query, string } from '~/prix'
import asGeoJson from '~/prix/functions/asGeoJson'

export default function statesQuery(
  by: 'macroRegionId' | 'countryId' | 'countryIsoCode' | 'stateId' | null,
  id: string | null,
) {
  switch (by) {
    case 'macroRegionId':
      return query('state')
        .select({
          id: entity('state').property('id'),
          name: entity('state').property('name'),
          boundary: asGeoJson(entity('state').property('lowerQualityBoundary')),
          center: asGeoJson(entity('state').property('center')),
        })
        .where(equals(entity('state').property('macroRegionId'), string().value(id!)))
        .limit(10000)
    case 'countryId':
      return query('state')
        .select({
          id: entity('state').property('id'),
          name: entity('state').property('name'),
          boundary: asGeoJson(entity('state').property('lowerQualityBoundary')),
          center: asGeoJson(entity('state').property('center')),
        })
        .join({
          current: entity('state').property('macroRegionId'),
          target: entity('macroRegion').property('id'),
        })
        .where(equals(entity('macroRegion').property('countryId'), string().value(id!)))
        .limit(10000)
    case 'stateId':
      return query('state')
        .select({
          id: entity('state').property('id'),
          name: entity('state').property('name'),
          boundary: asGeoJson(entity('state').property('lowerQualityBoundary')),
          center: asGeoJson(entity('state').property('center')),
        })
        .where(equals(entity('state').property('id'), string().value(id!)))
        .limit(1)
    case 'countryIsoCode':
      return query('state')
        .select({
          id: entity('state').property('id'),
          name: entity('state').property('name'),
          boundary: asGeoJson(entity('state').property('lowerQualityBoundary')),
          center: asGeoJson(entity('state').property('center')),
        })
        .join({
          current: entity('state').property('macroRegionId'),
          target: entity('macroRegion').property('id'),
        })
        .join({
          current: entity('macroRegion').property('countryId'),
          target: entity('country').property('id'),
        })
        .where(equals(entity('country').property('iso'), string().value(id!)))
        .limit(10000)
    default:
      return query('state')
        .select({
          id: entity('state').property('id'),
          name: entity('state').property('name'),
          boundary: asGeoJson(entity('state').property('lowerQualityBoundary')),
          center: asGeoJson(entity('state').property('center')),
        })
        .limit(10000)
  }
}

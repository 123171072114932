import { count, entity, equals, number, query, string } from '~/prix'
import asGeoJson from '~/prix/functions/asGeoJson'

export default function macroRegionsQuery(
  by: 'macroRegionId' | 'countryId' | 'countryIsoCode' | null,
  id: string | null,
) {
  switch (by) {
    case 'macroRegionId':
      return query('macroRegion')
        .select({
          id: entity('macroRegion').property('id'),
          name: entity('macroRegion').property('name'),
          boundary: asGeoJson(entity('macroRegion').property('lowerQualityBoundary')),
          center: asGeoJson(entity('macroRegion').property('center')),
        })
        .where(equals(entity('macroRegion').property('id'), string().value(id!)))
        .limit(1)
    case 'countryId':
      return query('macroRegion')
        .select({
          id: entity('macroRegion').property('id'),
          name: entity('macroRegion').property('name'),
          boundary: asGeoJson(entity('macroRegion').property('lowerQualityBoundary')),
          center: asGeoJson(entity('macroRegion').property('center')),
        })
        .where(equals(entity('macroRegion').property('countryId'), string().value(id!)))
        .limit(10000)
    case 'countryIsoCode':
      return query('macroRegion')
        .select({
          id: entity('macroRegion').property('id'),
          name: entity('macroRegion').property('name'),
          boundary: asGeoJson(entity('macroRegion').property('lowerQualityBoundary')),
          center: asGeoJson(entity('macroRegion').property('center')),
        })
        .join({
          current: entity('macroRegion').property('countryId'),
          target: entity('country').property('id'),
        })
        .where(equals(entity('country').property('iso'), string().value(id!)))
        .limit(10000)
    default:
      return query('macroRegion')
        .select({
          id: entity('macroRegion').property('id'),
          name: entity('macroRegion').property('name'),
          boundary: asGeoJson(entity('macroRegion').property('lowerQualityBoundary')),
          center: asGeoJson(entity('macroRegion').property('center')),
        })
        .limit(1000)
  }
}

import { format } from 'date-fns'
import {
  boolean,
  date,
  descending,
  entity,
  equals,
  less,
  lessOrEqual,
  max,
  number,
  query,
  string,
  truthy,
} from '~/prix'

export default function attendanceLastDatesQuery({
  stateAbbreviation,
}: {
  stateAbbreviation?: string
}) {
  const now = format(new Date(), 'yyyy-MM-dd')

  const lastAttendanceDate = query('legalEntityAttendance')
    .select({
      dataSourceId: entity('legalEntityAttendanceDataSource').property('dataSourceId'),
      startDate: max(entity('legalEntityAttendance').property('startDate')),
    })
    .join({
      current: entity('legalEntityAttendance').property('id'),
      target: entity('legalEntityAttendanceDataSource').property('legalEntityAttendanceId'),
      join: 'inner',
    })
    [stateAbbreviation !== undefined ? 'join' : 'dummy']({
      current: entity('legalEntityAttendance').property('stateId'),
      target: entity('state').property('id'),
      join: 'inner',
    })
    .where(
      ...[
        stateAbbreviation && stateAbbreviation !== undefined
          ? equals(entity('state').property('abbreviation'), string().value(stateAbbreviation))
          : null,
        lessOrEqual(entity('legalEntityAttendance').property('startDate'), date().value(`${now}`)),
      ].filter(truthy),
    )
    .order(descending('startDate'))

  const lastRegisterDate = query('courseRegisterIndividual')
    .select({
      isActivePortfolio: entity('courseProduct').property(`isActivePortfolio`),
      registerDate: max(entity('courseRegisterIndividual').property('registerDate')),
    })
    .join({
      current: entity('courseRegisterIndividual').property('courseProductId'),
      target: entity('courseProduct').property('id'),
      join: 'inner',
    })
    [stateAbbreviation !== undefined ? 'join' : 'dummy']({
      current: entity('courseRegisterIndividual').property('legalEntityId'),
      target: entity('legalEntity').property('id'),
      join: 'inner',
    })
    [stateAbbreviation !== undefined ? 'join' : 'dummy']({
      current: entity('legalEntity').property('id'),
      target: entity('legalEntityAttendance').property('legalEntityId'),
      join: 'inner',
    })
    [stateAbbreviation !== undefined ? 'join' : 'dummy']({
      current: entity('legalEntityAttendance').property('stateId'),
      target: entity('state').property('id'),
      join: 'inner',
    })
    .where(
      ...[
        stateAbbreviation && stateAbbreviation !== undefined
          ? equals(entity('state').property('abbreviation'), string().value(stateAbbreviation))
          : null,
        lessOrEqual(
          entity('courseRegisterIndividual').property('registerDate'),
          date().value(`${now}`),
        ),
      ].filter(truthy),
    )
    .order(descending('registerDate'))

  return {
    lastAttendanceDate,
    lastRegisterDate,
  }
}

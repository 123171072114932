import { useState } from 'react'
import { Bounce, Theme, toast, ToastOptions } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

type ToastType = 'success' | 'warning' | 'error' | 'info' | 'default'

const toastConfig = {
  position: "top-right" as 'top-right',
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  theme: "colored" as Theme,
  transition: Bounce,
}

export function emitMessage(
  message: string,
  type: ToastType,
  toastOptions: Exclude<ToastOptions, 'type'> = {},
) {
  toast(message, {
    ...toastConfig,
    ...toastOptions,
    type,
  })
}

export function toastSuccess(message: string, toastOptions: Exclude<ToastOptions, 'type'> = {}) {
  emitMessage(message, 'success', toastOptions)
}

export function toastWarning(message: string, toastOptions: Exclude<ToastOptions, 'type'> = {}) {
  emitMessage(message, 'warning', toastOptions)
}

export function toastError(message: string, toastOptions: Exclude<ToastOptions, 'type'> = {}) {
  emitMessage(message, 'error', toastOptions)
}

export function toastInfo(message: string, toastOptions: Exclude<ToastOptions, 'type'> = {}) {
  emitMessage(message, 'info', toastOptions)
}

export function toastDefault(message: string, toastOptions: Exclude<ToastOptions, 'type'> = {}) {
  emitMessage(message, 'default', toastOptions)
}
import { boolean, defineAction, object } from '~/prix'
import systemImportGoogleDriveDatasetsAction from './systemImportGoogleDriveDatasets.action.json' assert { type: 'json' }

const systemImportGoogleDriveDatasetsDefinition = defineAction({
  ...systemImportGoogleDriveDatasetsAction,
  input: object({
    force: boolean().isOptional(),
  }),
  output: object({}),
})

export default systemImportGoogleDriveDatasetsDefinition

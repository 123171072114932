/* globals caches */
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import useMedia from 'react-use/lib/useMedia'
import BackIcon from '~/components/icons/arrows/24px_minimal-left.svg'
import {
  AppError,
  entity,
  enumerated,
  formatAsBrNumber,
  formatAsPercentage,
  like,
  query,
  string,
  truthy,
} from '~/prix'
import { Link, census, profits, scores } from './legalEntityGeoprocessingMapMenu.component'
import { colors } from '~/design'
import unaccent from '~/prix/functions/unaccent'
import SelectFromQuery from '~/prix/react/components/form/inputs/selectFromQuery'
import {
  FilterHighlight,
  GeoChildrenLegalEntitiesPossibilities,
  GeoLegalEntitiesPossibilities,
  Level,
  optionsBooleanValue,
  optionsSingleStringValue,
} from '../legalEntityGeoprocessingMapLevels.data'
import AdditionalInformation from '../additionalInformation/additionalInformation.component'
import styled from 'styled-components'
import { DefinedOption } from './legalEntityGeoprocessingMapMenu.data'
import TopCard from '~/components/topCard'
import {
  LineHr,
  LoadingMessageWrapper,
  SectionTitle,
  Wrapper,
} from './legalEntityGeoprocessingCustomizedMapMenu.component'
import LegendContainer from '../legend/mapLegend.component'
import LoadingQueriesMessage from '~/prix/react/components/loadingQueriesMessage'
import { useParams } from 'react-router'
import AlertIcon from '~/components/icons/ui/16px_alert.svg'
import useItems from '~/prix/react/hooks/items'
import eachSourceAttendanceDatesQuery from '~/packages/legalEntityAttendance/attendanceDates/eachSourceAttendanceDates.query'
import { format, parse, subMonths, subYears } from 'date-fns'
import { isNullOrUndefined } from '~/prix/utils/empty'
import { KeysAndLabels } from '~/prix/types/enumerated'
import AttendanceField from '~/packages/legalEntityAttendance/attendanceField'
import EnumeratedField from '~/prix/react/components/form/field/enumeratedField'
import { useSearchParams } from 'react-router-dom'

const AlertInfo = styled.div`
  display: flex;
  flex-direction: row;
  background-color: #f8fbff;
  color: #000;
  border: 1px solid #e7f2ff;
  border-radius: 4px;
  justify-content: center;
  padding: 10px;
  gap: 10px;

  .icon-wrapper {
    margin-top: 2px;
    flex-direction: column;
  }
  .span-wrapper {
    margin: auto 0;
    flex-direction: column;
    color: #212529;
    font-size: 13px;
  }
`

const Button = styled.button`
  margin-top: 15px;
  text-decoration: none;
  background-color: ${colors.sebraeBlue};
  border: 1px solid;
  color: #fff;
  padding: 10px;
  transition-duration: 0.5s;
  text-align: center;
  border-radius: 5px;
  font-weight: 500;
  font-size: 16px;
  cursor: pointer;

  &:hover {
    background-color: ${colors.oceanBlue};
    color: #fff;
  }
`

const attendancePeriod = {
  lastMonth: 'No último mês',
  lastYear: 'No último ano',
}

interface DefinedOptionMapMenuProps {
  isHeatmapEnabled: boolean
  title: string
  error?: AppError
  definedOption: DefinedOption
  countSum: number | null
  highlightSum: number | null
  onChangeFilter: (newFilter: FilterHighlight | null) => void
  onChangeHighlight: (newHighlight: FilterHighlight | null) => void
  filter: FilterHighlight | null
  highlight?: FilterHighlight | null
  isLoading: boolean
  toggleMenuVisibility: () => void
  maxValue: number | null
  minValue: number | null
  maxPercentColor: number | null
  minPercentColor: number | null
  handleOutOfRangeEntities: (entities: any) => void
  handleTotalEntities: (entities: any) => void
  isHighlightCensusEnabled: boolean
  isHighlightProfitsEnabled: boolean
  loadingMessage?: any
}

const AlertMessage = ({ type }: { type: string }) => {
  let text =
    type === 'census'
      ? 'Informações baseadas nos dados da última atualização do IBGE.'
      : type === 'isMei'
      ? 'Informações baseadas nos dados das últimas atualizações das fontes SEBRAE e Receita Federal.'
      : null

  return (
    <AlertInfo>
      <div className='icon-wrapper'>
        <AlertIcon fill={'#f1c40f'} width={18} height={18} />
      </div>
      <div className='span-wrapper'>{text}</div>
    </AlertInfo>
  )
}

export default function DefinedOptionMapMenu({
  onChangeFilter,
  onChangeHighlight,
  definedOption,
  isLoading,
  filter,
  highlight,
  title,
  countSum,
  highlightSum,
  isHeatmapEnabled,
  toggleMenuVisibility,
  maxValue,
  minValue,
  maxPercentColor,
  minPercentColor,
  handleOutOfRangeEntities,
  handleTotalEntities,
  isHighlightCensusEnabled,
  isHighlightProfitsEnabled,
  loadingMessage,
  error,
}: DefinedOptionMapMenuProps) {
  const isMobile = useMedia('(max-width: 768px)')
  const [attendanceSourceActive, setAttendanceSourceActive] = useState<string | undefined>(
    undefined,
  )
  const [attendanceSourceHighlightActive, setAttendanceSourceHighlightActive] = useState<
    string | undefined
  >(undefined)
  const [level, setLevel] = useState<Level | null>(null)
  const [additionalInformation, setAdditionalInformation] = useState<any>(undefined)
  const params = useParams()
  const [searchParams] = useSearchParams()

  useEffect(() => {
    if (params.by && params.id) {
      setLevel({
        geo: params.by as GeoLegalEntitiesPossibilities,
        id: params.id,
        childrenGeoLevel: params.childrenGeoLevel as
          | GeoChildrenLegalEntitiesPossibilities
          | undefined,
      })
      return
    }

    setLevel({
      geo: 'country',
      id: '30',
      childrenGeoLevel: params.childrenGeoLevel as
        | GeoChildrenLegalEntitiesPossibilities
        | undefined,
    })
  }, [params, definedOption])

  const eachSourceAttendanceDates = useItems(() => eachSourceAttendanceDatesQuery(), [isLoading], {
    cache: 60 * 60 * 8,
    autoLoad: isLoading === false,
  })

  const dataSourceAttendanceDatesFormatted: any = eachSourceAttendanceDates.items?.find(
    (item: any) => item.dataSourceId == definedOption.source,
  )

  const maxAttendanceDate =
    dataSourceAttendanceDatesFormatted &&
    new Date(dataSourceAttendanceDatesFormatted.maxAttendanceDate).toLocaleDateString('pt-BR')

  const lastMonthDate =
    dataSourceAttendanceDatesFormatted && dataSourceAttendanceDatesFormatted.maxAttendanceDate
      ? subMonths(parse(maxAttendanceDate, 'dd/MM/yyyy', new Date()).getTime(), 1)
      : null

  const lastYearDate =
    dataSourceAttendanceDatesFormatted && dataSourceAttendanceDatesFormatted.maxAttendanceDate
      ? subYears(parse(maxAttendanceDate, 'dd/MM/yyyy', new Date()).getTime(), 1)
      : null

  const filterPeriodDates: KeysAndLabels<string> = useMemo(
    () =>
      dataSourceAttendanceDatesFormatted?.maxAttendanceDate && lastMonthDate && lastYearDate
        ? [
            {
              value: 'lastMonth',
              label: 'No último mês mais recente',
              period: `Dados de ${format(
                new Date(lastMonthDate),
                'dd/MM/yyyy',
              )} à ${maxAttendanceDate}`,
            },
            {
              value: 'lastYear',
              label: 'No último ano mais recente',
              period: `Dados de ${format(
                new Date(lastYearDate),
                'dd/MM/yyyy',
              )} à ${maxAttendanceDate}`,
            },
          ]
        : {},
    [dataSourceAttendanceDatesFormatted, lastMonthDate, lastYearDate, maxAttendanceDate],
  )

  const handleOption = useCallback(
    (
      value: string | null,
      mainOption: string,
      type: string,
      name?: string | undefined | null,
      attendanceSource?: string,
    ) => {
      if (mainOption === 'filter' && value === null) {
        onChangeFilter({ type, name: name ?? '', attendanceSource: attendanceSource ?? '' })
        setAttendanceSourceActive(attendanceSource ? attendanceSource : '')
      }

      if (
        type &&
        mainOption === 'filter' &&
        value !== null &&
        optionsSingleStringValue.includes(type)
      ) {
        onChangeFilter({
          type,
          value,
          name: name ?? '',
          attendanceSource: attendanceSource ?? '',
        })
      }
      if (type && mainOption === 'filter' && optionsBooleanValue.includes(type)) {
        onChangeFilter({ type, value: type })
      }
      if (type === null && mainOption === 'filter') {
        onChangeFilter(null)
        setAttendanceSourceActive(undefined)
      }

      // highlight
      if (mainOption === 'highlight') {
        onChangeHighlight({ type, name: name ?? '', attendanceSource: attendanceSource ?? '' })
        setAttendanceSourceHighlightActive(attendanceSource ? attendanceSource : '')
      }
      if (
        type &&
        mainOption === 'highlight' &&
        value !== null &&
        optionsSingleStringValue.includes(type)
      ) {
        onChangeHighlight({
          type,
          value,
          name: name ?? '',
          attendanceSource: attendanceSource ?? '',
        })
      }
      if (type && mainOption === 'highlight' && optionsBooleanValue.includes(type)) {
        onChangeHighlight({
          type,
          value: type,
          name: name ?? '',
          attendanceSource: attendanceSource ?? '',
        })
      }
      if (type === null && mainOption === 'highlight') {
        onChangeHighlight(null)
        setAttendanceSourceHighlightActive(undefined)
      }
    },
    [onChangeFilter, onChangeHighlight],
  )

  useEffect(() => {
    const definedOptionParam = searchParams.get('definedOption')

    if (
      definedOption.highlightType !== null &&
      definedOption.filterType &&
      definedOption.default == definedOptionParam
    ) {
      handleOption(
        definedOption.filterValue,
        'highlight',
        definedOption.filterType,
        definedOption.filterName,
      )
    }
  }, [
    definedOption.highlightType,
    definedOption.filterValue,
    definedOption.filterType,
    definedOption.filterName,
  ])

  const cnaeQueryFactory = useCallback((input: string) => {
    const inputWithWildcards = `%${input.replace(/\s/g, '%')}%`

    const cnaeNestSeparator = '>'
    return query('cnaeSection')
      .select({
        id: entity('cnaeSection').property('cnaes'),
        name: entity('cnaeSection').property('description'),
        subtitle: string().value('Seção'),
      })
      .where(
        like(
          unaccent(entity('cnaeSection').property('description')),
          unaccent(string().value(inputWithWildcards)),
        ),
      )
      .union(
        query('cnaeDivision')
          .select({
            id: entity('cnaeDivision').property('ibgeCode'),
            name: entity('cnaeDivision').property('description'),
            subtitle: string().value(`Seção ${cnaeNestSeparator} Divisão`),
          })
          .where(
            like(
              unaccent(entity('cnaeDivision').property('description')),
              unaccent(string().value(inputWithWildcards)),
            ),
          ),
        query('cnaeGroup')
          .select({
            id: entity('cnaeGroup').property('ibgeCode'),
            name: entity('cnaeGroup').property('description'),
            subtitle: string().value(
              `Seção ${cnaeNestSeparator} Divisão ${cnaeNestSeparator} Grupo`,
            ),
          })
          .where(
            ...[
              !isNaN(Number(input))
                ? like(entity('cnaeGroup').property('ibgeCode'), string().value(inputWithWildcards))
                : like(
                    unaccent(entity('cnaeGroup').property('description')),
                    unaccent(string().value(inputWithWildcards)),
                  ),
            ].filter(truthy),
          ),
        query('cnaeClass')
          .select({
            id: entity('cnaeClass').property('ibgeCode'),
            name: entity('cnaeClass').property('description'),
            subtitle: string().value(
              `Seção ${cnaeNestSeparator} Divisão ${cnaeNestSeparator} Grupo ${cnaeNestSeparator} Classe`,
            ),
          })
          .where(
            ...[
              !isNaN(Number(input))
                ? like(entity('cnaeClass').property('ibgeCode'), string().value(inputWithWildcards))
                : like(
                    unaccent(entity('cnaeClass').property('description')),
                    unaccent(string().value(inputWithWildcards)),
                  ),
            ].filter(truthy),
          ),
      )
      .limit(21)
  }, [])

  const legalNatureQueryFactory = useCallback(
    (input: string) =>
      query('legalNature')
        .select({
          id: entity('legalNature').property('id'),
          name: entity('legalNature').property('description'),
        })
        .where(
          like(
            unaccent(entity('legalNature').property('description')),
            unaccent(string().value(`%${input.replace(/\s/g, '%')}%`)),
          ),
        )
        .limit(10),
    [],
  )

  const isHighlightCensus =
    highlight && census.includes(highlight?.type) && highlight?.value === 'censusUpdated'
  const isHighlightProfits =
    highlight && profits.includes(highlight?.type) && highlight?.value === 'profitsData'
  const isHighlightScores =
    highlight && scores.includes(highlight?.type) && highlight?.value === 'profitsData'
  const isHighlightIndicators = isHighlightProfits || isHighlightScores

  const censusMessage =
    highlight?.type === 'area'
      ? ' empresas por km²'
      : highlight?.type === 'perCapta'
      ? ' empresas per capita'
      : null

  const noCensusData =
    isHighlightCensus && (highlightSum === null || highlightSum === undefined || highlightSum === 0)
  const noDataHighlightMessage = isHighlightCensus
    ? 'Sem dados do IBGE para este nível'
    : isHighlightProfits || isHighlightScores
    ? 'Sem dados do Radar Ali e Brasil Mais para este nível'
    : ''

  const value =
    isHighlightCensus && countSum !== null && highlightSum !== null && countSum !== 0
      ? formatAsBrNumber(countSum / Number(highlightSum))
      : highlight &&
        !isNullOrUndefined(highlightSum) &&
        countSum !== null &&
        !isHighlightProfits &&
        !isHighlightScores
      ? `${countSum !== 0 ? formatAsPercentage(Number(highlightSum) / countSum) : 0}%`
      : highlight && !isNullOrUndefined(highlightSum) && (isHighlightProfits || isHighlightScores)
      ? formatAsBrNumber(Number(highlightSum))
      : countSum !== null && !isHighlightCensus && !isHighlightIndicators
      ? formatAsBrNumber(countSum, 0)
      : null

  const isLoadingMemo = useMemo(() => {
    const isLoadingDefault = isLoading && countSum === 0
    const isLoadingInfinity = isLoading === false && Number(countSum) === -Infinity

    return isLoadingDefault ? isLoadingDefault : isLoadingInfinity ? isLoadingInfinity : null
  }, [isLoading, countSum, highlight, loadingMessage, highlightSum])

  const result = useMemo(() => {
    const onlyTheseLevels =
      level &&
      (level.geo === 'city' ||
        level.geo === 'neighborhood' ||
        level.geo === 'street' ||
        level.geo === 'clusteredLegalEntity' ||
        level.geo === 'legalEntity')

    const noDataHighlight =
      highlightSum === null &&
      onlyTheseLevels &&
      (isHighlightCensus || isHighlightProfits || isHighlightScores)
        ? noDataHighlightMessage
        : null
    const minValueHighlight = value === '' && highlight !== null ? '0%' : null
    const emptyValueNullableHighlight =
      value === '' && highlight === null && highlightSum ? Number(value) : null
    const isProfitsValue = isHighlightProfits && noDataHighlight === null ? `R$ ${value}` : null
    const isScoresValue = isHighlightScores && noDataHighlight === null ? `Média: ${value}` : null

    return isLoading === false
      ? noDataHighlight
        ? noDataHighlight
        : minValueHighlight
        ? minValueHighlight
        : emptyValueNullableHighlight
        ? emptyValueNullableHighlight
        : isProfitsValue
        ? isProfitsValue
        : isScoresValue
        ? isScoresValue
        : value
      : ''
  }, [
    level,
    isHighlightCensusEnabled,
    isHighlightCensus,
    noCensusData,
    isHighlightProfitsEnabled,
    isHighlightIndicators,
    value,
    highlight,
    isHighlightProfits,
    isHighlightScores,
    highlightSum,
    noDataHighlightMessage,
    isLoading,
  ])

  const resultSuffix = useMemo(() => {
    const highlightDefaultSuffix =
      highlight &&
      highlightSum !== null &&
      highlight.type !== 'perCapta' &&
      highlight.type !== 'area' &&
      !isHighlightProfits &&
      !isHighlightScores
        ? ' das empresas'
        : null
    const highlightScoresSuffix =
      isHighlightScores && highlightSum !== null && value ? ' de 5' : null
    const highlightCensusSuffix =
      highlight !== null && isHighlightCensus ? ` ${censusMessage}` : null
    const legalEntitySuffix =
      !highlight || (highlight && highlight.value === null)
        ? Number(value) === 1
          ? ' empresa'
          : ' empresas'
        : null
    const definedOptionIndexSuffix =
      definedOption && definedOption.type === 'legalEntityAttendanceIndex' ? '%' : null

    return (
      definedOptionIndexSuffix ||
      highlightDefaultSuffix ||
      highlightScoresSuffix ||
      highlightCensusSuffix ||
      legalEntitySuffix
    )
  }, [
    highlight,
    highlightSum,
    isHighlightProfits,
    isHighlightScores,
    censusMessage,
    noCensusData,
    value,
  ])

  const hideAdditionalInformationCondition = useMemo(() => {
    const highlightPerCapta =
      highlight &&
      highlight.type === 'perCapta' &&
      highlight.value === 'censusUpdated' &&
      level &&
      level.geo !== 'city'
        ? true
        : false
    const highlightArea =
      highlight &&
      highlight.type === 'area' &&
      highlight.value === 'censusUpdated' &&
      level &&
      level.geo !== 'city'
        ? true
        : false
    const highlightValue =
      highlight && highlight.type !== 'perCapta' && highlight.type !== 'area' && highlight.value
        ? true
        : false
    const highlightSumNullable =
      highlight &&
      (highlight.type === 'perCapta' || highlight.type === 'area') &&
      highlight.value === 'censusUpdated' &&
      highlightSum === null &&
      level &&
      level.geo === 'city'
        ? true
        : false
    const definedOptionIndexValue = definedOption.type === 'legalEntityAttendanceIndex'

    return (
      highlightPerCapta ||
      highlightArea ||
      highlightValue ||
      highlightSumNullable ||
      definedOptionIndexValue
    )
  }, [highlight, highlightSum, isHighlightScores, isHighlightProfits, level, value, definedOption])

  const indexValue =
    highlightSum !== null && highlightSum !== undefined && countSum !== null
      ? countSum !== 0
        ? highlightSum / countSum > 1
          ? formatAsPercentage(highlightSum / countSum)
          : highlightSum / countSum < 0.001
          ? '< 0.001%'
          : ((highlightSum / countSum) * 100).toFixed(2).replace('.', ',') + '%'
        : '0%'
      : countSum !== null && highlightSum === null
      ? formatAsBrNumber(countSum, 0)
      : null

  const totalSelection = useMemo(() => {
    if (isLoadingMemo || isLoading) {
      handleTotalEntities('Carregando...')
      return 'Carregando...'
    }

    if ((!isMobile && result === null) || resultSuffix === null) {
      let result = 'Sem dados disponíveis'
      handleTotalEntities(result)
      return result
    }

    if (
      definedOption.type === 'legalEntityAttendanceIndex' ||
      definedOption.filterType === 'isMei'
    ) {
      let result = `${formatAsBrNumber(Number(highlightSum), 0)} de ${formatAsBrNumber(
        Number(countSum),
        0,
      )} empresas (${indexValue})`

      handleTotalEntities(result)
      return result
    }

    if (
      definedOption.type !== 'legalEntityAttendanceIndex' &&
      definedOption.filterType !== 'isMei'
    ) {
      handleTotalEntities(result! + (resultSuffix ?? ''))
      return result! + (resultSuffix ?? '')
    }
  }, [
    result,
    resultSuffix,
    isMobile,
    definedOption,
    isLoadingMemo,
    isLoading,
    highlightSum,
    countSum,
  ])

  return (
    <>
      <Wrapper>
        {isMobile ? (
          <TopCard title={title} />
        ) : (
          <div className='button-wrapper'>
            <Link href='/app/map'>
              <BackIcon width={10} height={10} />
              <span>Voltar</span>
            </Link>
          </div>
        )}

        <div className='containers'>
          {definedOption.filterValue === 'censusUpdated' && !isMobile ? (
            <AlertMessage type='census' />
          ) : definedOption.filterType === 'isMei' && !isMobile ? (
            <AlertMessage type='isMei' />
          ) : null}

          {isMobile && definedOption.highlightType ? (
            <fieldset
              className='section-container preset-mobile'
              style={{ borderTopRightRadius: '12px', borderTopLeftRadius: '12px' }}
            >
              {!error ? (
                <div className='item-container results-container'>
                  {isLoadingMemo || isLoading ? (
                    isMobile && loadingMessage !== undefined ? (
                      <>
                        <label>Total da seleção</label>
                        <output>
                          <LoadingMessageWrapper>
                            <LoadingQueriesMessage queries={loadingMessage} />
                          </LoadingMessageWrapper>
                        </output>
                      </>
                    ) : (
                      <output>{totalSelection}</output>
                    )
                  ) : result === null ? (
                    <>
                      <label>Total da seleção</label>
                      <output>Sem dados disponíveis</output>
                    </>
                  ) : (
                    <>
                      <label>Total da seleção</label>
                      <output>
                        {result}
                        {resultSuffix}
                      </output>
                    </>
                  )}

                  {!hideAdditionalInformationCondition ? (
                    <AdditionalInformation
                      isLoadingMainTotal={isLoading}
                      handleAdditionalInfo={setAdditionalInformation}
                    />
                  ) : null}

                  {definedOption.type === 'legalEntityAttendanceIndex' ||
                  definedOption.type === 'legalEntityAttendance'
                    ? handleOutOfRangeEntities(null)
                    : handleOutOfRangeEntities(additionalInformation)}

                  {!isLoading && isMobile && (
                    <Button onClick={toggleMenuVisibility}>Ver Mapa</Button>
                  )}
                </div>
              ) : null}

              {(isHighlightScores || isHighlightProfits) && !isLoading && (
                <div className='alert-info' role='alert'>
                  Informações baseadas em empresas que já foram atendidas pelo Brasil Mais ou Radar
                  ALI.
                  <AlertIcon fill={'#f1c40f'} width={18} height={18} />
                </div>
              )}
            </fieldset>
          ) : null}

          {!definedOption.highlightType ? (
            <div>
              <SectionTitle>
                <span>Filtros</span>
              </SectionTitle>
              <fieldset className='section-container'>
                {definedOption.searchType === 'lastMonthAndYearPeriod' ? (
                  <div className='item-container results-container'>
                    <div className='item-container'>
                      <div style={{ marginTop: '2px' }}></div>
                      <label>Período</label>
                      <div style={{ marginTop: '4px' }}>
                        {Array.isArray(filterPeriodDates) ? (
                          <AttendanceField
                            fieldSchema={enumerated(filterPeriodDates)}
                            value={filter?.value || filterPeriodDates[1].value}
                            onDirectChange={(value: any) =>
                              handleOption(
                                value,
                                'filter',
                                'attendance',
                                undefined,
                                attendanceSourceActive,
                              )
                            }
                          />
                        ) : (
                          <EnumeratedField
                            fieldSchema={enumerated(attendancePeriod)}
                            value={filter?.value || Object.keys(attendancePeriod)[1]}
                            onDirectChange={(value: any) =>
                              handleOption(
                                value,
                                'filter',
                                'attendance',
                                undefined,
                                attendanceSourceActive,
                              )
                            }
                          />
                        )}
                      </div>
                    </div>
                  </div>
                ) : null}

                {definedOption.searchType === 'cnae' ? (
                  <div className='item-container results-container'>
                    <div className='item-container'>
                      <label>Filtrar por CNAE</label>
                      <div style={{ marginTop: '5px' }}>
                        <SelectFromQuery
                          queryFactory={cnaeQueryFactory}
                          idProperty='id'
                          labelProperty='name'
                          preLoad
                          value={
                            filter && filter.value
                              ? { id: filter.value, name: filter.name }
                              : highlight && highlight?.value
                              ? { id: highlight.value, name: highlight.name! }
                              : undefined
                          }
                          onDirectChange={value =>
                            handleOption(value?.id, 'filter', 'cnaes', value?.name)
                          }
                        />
                      </div>
                    </div>
                  </div>
                ) : null}

                {definedOption.searchType === 'legalNature' ? (
                  <div className='item-container results-container'>
                    <div className='item-container'>
                      <label>Filtrar por natureza jurídica</label>
                      <div style={{ marginTop: '5px' }}>
                        <SelectFromQuery
                          queryFactory={legalNatureQueryFactory}
                          idProperty='id'
                          labelProperty='name'
                          preLoad
                          value={
                            filter && filter.value
                              ? { id: filter.value, name: filter.name }
                              : undefined
                          }
                          onDirectChange={value =>
                            handleOption(value?.id, 'filter', 'legalNatureId', value?.name)
                          }
                        />
                      </div>
                    </div>
                  </div>
                ) : null}

                {!error && isMobile && (
                  <div>
                    <LineHr />
                  </div>
                )}

                {!error ? (
                  <div className='item-container results-container'>
                    {isLoadingMemo && isLoading ? (
                      isMobile && loadingMessage !== undefined ? (
                        <>
                          <label>Total da seleção</label>
                          <output>
                            <LoadingMessageWrapper>
                              <LoadingQueriesMessage queries={loadingMessage} />
                            </LoadingMessageWrapper>
                          </output>
                        </>
                      ) : (
                        <output>{totalSelection}</output>
                      )
                    ) : null}

                    {isMobile && totalSelection === null ? (
                      <>
                        <label>Total da seleção</label>
                        <output>Sem dados disponíveis</output>
                      </>
                    ) : null}

                    {isMobile && totalSelection !== null ? (
                      <>
                        <label>Total da seleção</label>
                        <output>{totalSelection}</output>
                      </>
                    ) : null}

                    {!hideAdditionalInformationCondition ? (
                      <div style={{ paddingTop: '4px' }}>
                        <AdditionalInformation
                          isLoadingMainTotal={isLoading}
                          handleAdditionalInfo={setAdditionalInformation}
                        />
                      </div>
                    ) : null}

                    {definedOption.type === 'legalEntityAttendanceIndex' ||
                    definedOption.type === 'legalEntityAttendance'
                      ? handleOutOfRangeEntities(null)
                      : handleOutOfRangeEntities(additionalInformation)}

                    {!isLoading && isMobile && (
                      <Button onClick={toggleMenuVisibility}>Ver Mapa</Button>
                    )}
                  </div>
                ) : null}

                {(isHighlightScores || isHighlightProfits) && !isLoading && (
                  <div className='alert-info' role='alert'>
                    Informações baseadas em empresas que já foram atendidas pelo Brasil Mais ou
                    Radar ALI.
                    <AlertIcon fill={'#f1c40f'} width={18} height={18} />
                  </div>
                )}
              </fieldset>
            </div>
          ) : null}
        </div>

        {definedOption.filterValue === 'censusUpdated' && isMobile ? (
          <div style={{ paddingLeft: '40px', paddingRight: '40px' }}>
            <AlertMessage type='census' />
          </div>
        ) : definedOption.filterType === 'isMei' && isMobile ? (
          <div style={{ paddingLeft: '40px', paddingRight: '40px' }}>
            <AlertMessage type='isMei' />
          </div>
        ) : null}

        {isHighlightCensusEnabled && !error && isHighlightProfitsEnabled && (
          <LegendContainer
            isHeatmapEnabled={
              isHeatmapEnabled || searchParams.get('visualizationType') === 'heatmap'
            }
            maxValue={maxValue ? maxValue : null}
            minValue={minValue ? minValue : null}
            isLoadingLegend={isLoading}
            maxPercentColor={minPercentColor ? maxPercentColor : null}
            minPercentColor={minPercentColor ? minPercentColor : null}
            highlight={highlight}
            suffix={
              definedOption.highlightType === 'meiPercentage' ? `% das empresas` : resultSuffix
            }
          />
        )}
      </Wrapper>
    </>
  )
}

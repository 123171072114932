import React, { useEffect, useMemo, useRef, useState } from 'react'
import Logo from '~/meta/sebraeLogo.svg'
import { colors } from '~/design'
import styled from 'styled-components'
import UserIcon from '~/components/icons/users/48px_circle-08.svg'
import Dropdown from './userButton'
import Link from '~/prix/react/components/link'
import NotificationIcon from '~/meta/notificationBell.svg'
import useMedia from 'react-use/lib/useMedia'
import { getMessaging, onMessage } from 'firebase/messaging'
import useActionFunction from '~/prix/react/hooks/actionFunction'
import registerNewDeviceDefinition from '~/packages/notifications/registerNewDevice/registerNewDevice.definition'
import Modal, { ModalStyle } from '~/prix/react/components/modal'
import getNotificationsDefinition from '~/packages/notifications/getNotifications/getNotifications.definition'
import LeftArrowIcon from '~/meta/left-angle-arrow.svg'
import readNotificationDefinition from '~/packages/notifications/readNotification/readNotification.definition'
import useAPI from '~/prix/react/hooks/api'
import useNotificationContext from '~/packages/notifications/notificationContext'
import useDevice from '~/prix/react/hooks/device'
import NotificationsComponent from './notifications.component'
import { useKeycloak } from './keycloak'

const Navbar = styled.nav`
  z-index: 1000;
  background: ${colors.headerBlue};
  height: 60px;
  display: flex;
  padding-right: 24px;
  padding-left: 24px;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 768px) {
    justify-content: flex-end;
  }

  .navbar-container {
    display: flex;
    text-align: center;
  }

  .nav-menu {
    display: flex;
    justify-content: flex-end;
    text-align: center;
  }

  .nav-item {
    margin-left: 5px;
    margin-right: 5px;
    display: flex;
    align-items: center;
  }

  .nav-links,
  .nav-detail {
    color: #fff;
    display: flex;
    align-items: center;
    text-decoration: none;
    padding: 0.5rem 1rem;
    height: 100%;
  }

  .nav-links:hover {
    cursor: default;
    transition: all 0.2s ease-out;
    cursor: pointer;
  }

  .menu-icon {
    display: none;
  }

  .notifications {
    > svg {
      cursor: pointer;
    }
  }

  @media screen and (max-width: 960px) {
    .NavbarItems {
      position: relative;
    }

    .nav-links,
    .nav-detail {
      display: none;
    }
  }

  @media (max-width: 768px) {
    padding: 0 15px;
  }
`

const LogoWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;

  h1 {
    color: #fff;
    font-size: 19px;
    font-weight: 700;
    flex: 1;
  }
`

const NavbarLogo = styled.a`
  order: 0;
  flex-grow: 0;
  color: #fff;
  justify-self: start;
  margin-left: 0;
  cursor: pointer;

  svg {
    fill: #fff;
  }
`

const UserButton = styled.button`
  display: block;
  margin-left: 5px;
  align-items: center;
  background-color: transparent;
  border: none;
  color: ${({ theme }) => theme.colors.primary};
  cursor: pointer;

  &:hover {
    color: ${({ theme }) => theme.colors.secondary};
  }
`

const NotificationAlert = styled.div<{ unreadNotifications: number }>`
  display: flex;
  width: ${({ unreadNotifications }) => (unreadNotifications < 10 ? '20px' : '24px')};
  height: ${({ unreadNotifications }) => (unreadNotifications < 10 ? '20px' : '24px')};
  padding: 3px 3px 4px 3px;
  justify-content: center;
  align-items: center;

  position: relative;
  top: -8px;
  right: -8px;

  border-radius: 50%;
  border: 1px solid #005eb8;
  background: #b22400;
  animation: pulse 1.5s infinite;
  -webkit-animation: pulse 1.5s infinite;

  @keyframes pulse {
    0% {
      -moz-transform: scale(0.9);
      -ms-transform: scale(0.9);
      -webkit-transform: scale(0.9);
      transform: scale(0.9);
    }
    70% {
      -moz-transform: scale(1);
      -ms-transform: scale(1);
      -webkit-transform: scale(1);
      transform: scale(1);
      box-shadow: 0 0 0 50px rgba(255, 0, 0, 0);
    }
    100% {
      -moz-transform: scale(0.9);
      -ms-transform: scale(0.9);
      -webkit-transform: scale(0.9);
      transform: scale(0.9);
      box-shadow: 0 0 0 0 rgba(255, 0, 0, 0);
    }
  }

  span {
    color: #fff;
    font-weight: 700;
    font-size: 12px;
  }
`

const NotificationMenuTop = styled.p`
  position: absolute;
  top: 31px;
  right: 82px;
  height: 15px;
  width: 15px;
  border-radius: 6px 0px 0px 0px;
  transform: rotate(45deg);
  background: #fff;
  z-index: 1001;

  @media (max-width: 768px) {
    right: 77px;
  }
`

const NotificationMenuWrapper = styled.div`
  position: absolute;
  top: 54px;
  right: 12px;
  width: 300px;
  background: #fff;
  border-radius: 4px;
  z-index: 1000;
  box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.2);
`

const NotificationTitleContainer = styled.div`
  background: #fff;
  padding: 15px;
  position: relative;
  text-align: center;
  color: ${colors.sebraeBlue};
  border-radius: 10px 10px 0 0;
  border: 0px;
  border-style: solid;
  border-bottom-width: 1px;
  border-image: linear-gradient(to right, white 0%, #cedae0 40%, #cedae0 60%, white 100%) 1 20%;
  overflow: hidden;

  p {
    padding: 0;
    margin: 0;
    font-size: 18px;
    font-style: italic;
    font-weight: 700;
  }
`

const NotificationContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  min-height: 100px;
  position: relative;
`

const NotificationLink = styled.a`
  width: 100%;
  text-align: center;
  font-size: 12px;
  padding: 8px;
  color: ${colors.sebraeBlue};
  cursor: pointer;
`

const NoNotificationWarning = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: 24px;
  gap: 12px;

  p {
    text-align: center;
    font-size: 15px;
    font-weight: 700;
    margin: 0;
  }

  span {
    text-align: center;
  }
`

const ModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 10px;
  height: 100%;
  border-radius: 12px;
  overflow: hidden;
`

const NotificationModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
  border-radius: 12px;
`

const NotificationListTitle = styled.div`
  display: flex;
  background: #0f438a;
  padding: 0 25px;
  width: 100%;
  gap: 10px;
  border-radius: 12px 12px 0 0;

  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  button {
    height: 3rem;
    width: 3rem;
    padding: 8px;
    align-items: center;
    gap: 4px;
    flex-shrink: 0;
    border: none;
    border-radius: 100px;
    background: #005eb8;
    cursor: pointer;

    svg {
      margin-top: 2px;
      fill: #fff;
    }
  }

  h3 {
    color: #fff;
    font-weight: 400;
    line-height: 23.3px;
    font-size: 2rem;
  }

  div {
    min-width: 3rem;
  }
`

const NotificationModalListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background-color: #fff;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 8px;
    margin: 0;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #888;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: #555;
  }
`

interface payloadProps {
  title: string
  body: string
}

export interface NotificationProps {
  id: string
  url: string
  label: string | null
  payload: payloadProps
  readAt: string | null
  createdAt: string | Date
}

export default function Header() {
  const isMobile = useMedia('(max-width: 768px)')
  const dropdownRef = useRef<HTMLLIElement | null>(null)
  const auth = useKeycloak()

  const { context } = useAPI()
  const {
    notifications,
    setNotifications,
    isDeviceUpdated,
    setIsDeviceUpdated,
    countUnreadNotifications,
    setCountUnreadNotifications,
    userToken,
  } = useNotificationContext()
  const { value: deviceInfo } = useDevice()

  const email = context.user?.email
  const roles = context.user?.roles
  const uf = context.user?.stateAbbreviation
  const deviceIdentifier = deviceInfo?.identifier

  const [showMenu, setShowMenu] = useState(false)
  const notificationRef = useRef<any | null>(null)
  const [showNotification, setShowNotification] = useState<boolean>(false)
  const [isNotificationModalOpen, setIsNotificationModalOpen] = useState<boolean>(false)

  const { callAction: callActionRegisterNewDevice } = useActionFunction(registerNewDeviceDefinition)
  const { callAction: callActionGetNotifications } = useActionFunction(getNotificationsDefinition)
  const { callAction: callActionMarkAsRead } = useActionFunction(readNotificationDefinition)

  var modalStyle: ModalStyle | undefined = undefined
  switch (true) {
    case location.pathname === '/app':
      modalStyle = 'homeScreen'
      break
    case location.pathname === '/app/options':
      modalStyle = 'biggerTopCardScreen'
      break
    case location.pathname.startsWith('/app/time-series'):
      modalStyle = 'default'
      break
    default:
      modalStyle = 'halfScreen'
      break
  }

  const messaging = getMessaging()
  onMessage(messaging, (payload: any) => {
    if (!email) return

    const { resultPromise } = callActionGetNotifications({
      userEmail: email,
    })

    resultPromise.then((res: any) => {
      setNotifications(res)
      new Notification(payload.notification.title, {
        body: payload.notification.body,
        icon: '/favicon.ico',
      })
    })
  })

  useMemo(() => {
    if (
      !email ||
      !userToken ||
      Notification.permission !== 'granted' ||
      !deviceIdentifier ||
      !roles ||
      !uf
    )
      return

    if (!isDeviceUpdated && location.pathname === '/app') {
      callActionRegisterNewDevice({
        device: deviceIdentifier,
        email: email,
        userToken: userToken,
        roles: roles,
        uf: uf,
      })

      setIsDeviceUpdated(true)
    }

    const { resultPromise } = callActionGetNotifications({
      userEmail: email,
    })

    resultPromise.then((res: any) => {
      setNotifications(res)
    })
  }, [email, deviceIdentifier, showNotification, userToken, messaging, countUnreadNotifications])

  useEffect(() => {
    if (Notification.permission !== 'granted') return

    if (notifications && notifications.length > 0) {
      const countUnreadNotifications = notifications.reduce((count, notification) => {
        return notification.readAt === null ? count + 1 : count
      }, 0)

      setCountUnreadNotifications(countUnreadNotifications)
    }
  }, [notifications])

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setShowMenu(false)
      }

      if (notificationRef.current && !notificationRef.current.contains(event.target as Node)) {
        setShowNotification(false)
      }
    }

    document.addEventListener('click', handleClickOutside)

    return () => {
      document.removeEventListener('click', handleClickOutside)
    }
  }, [])

  const toggleMenu = () => {
    setShowMenu(!showMenu)
  }

  const markAllAsRead = () => {
    if (countUnreadNotifications > 0 && email) {
      callActionMarkAsRead({
        userEmail: email,
      })

      setCountUnreadNotifications(0)
      const updatedNotifications = notifications.map(notification => ({
        ...notification,
        readAt: String(new Date()),
      }))

      setNotifications(updatedNotifications)
    }
  }

  return (
    <>
      <Navbar>
        {!isMobile && (
          <LogoWrapper>
            <Link href='/app'>
              <NavbarLogo>
                <Logo height={75} width={75} />
              </NavbarLogo>
            </Link>{' '}
          </LogoWrapper>
        )}
        <div>
          <ul className={'nav-menu'}>
            <li className='nav-item'>
              <Link href='/' className='nav-links'>
                Sobre a plataforma
              </Link>
            </li>
            <li className='nav-item'>
              <Link href='https://prix.tech/#Contact' className='nav-links' remote newTab>
                Fale conosco
              </Link>
            </li>
            {auth.isAuthenticated ? (
              <>
                <li className='nav-item'>
                  <div className='nav-detail'>|</div>
                </li>

                <li
                  className='nav-item notifications'
                  ref={notificationRef}
                  onClick={() => setShowNotification(!showNotification)}
                >
                  {countUnreadNotifications > 0 && (
                    <NotificationAlert unreadNotifications={countUnreadNotifications}>
                      <span>
                        {countUnreadNotifications > 99 ? '99+' : countUnreadNotifications}
                      </span>
                    </NotificationAlert>
                  )}
                  <NotificationIcon height={18} width={18} fill='#fff' />
                  {showNotification && (
                    <React.Fragment>
                      <NotificationMenuTop />
                      <NotificationMenuWrapper onClick={e => e.stopPropagation()}>
                        <NotificationTitleContainer>
                          <p>Notificações</p>
                        </NotificationTitleContainer>
                        <NotificationContentWrapper>
                          {Notification.permission !== 'granted' ? (
                            <NoNotificationWarning>
                              <p>As permissões de notificações estão bloqueadas pelo navegador.</p>
                              <span>
                                Para receber notificações, habilite as permissões de notificações
                                nas configurações do seu navegador.
                              </span>
                            </NoNotificationWarning>
                          ) : notifications && notifications.length > 0 ? (
                            <>
                              {notifications
                                .slice(0, 3)
                                .map((notification: NotificationProps, index: number) => (
                                  <NotificationsComponent
                                    isModal={false}
                                    notification={notification}
                                    setRead={markAllAsRead}
                                    key={index}
                                  />
                                ))}
                              <NotificationLink
                                onClick={() => {
                                  setShowNotification(false)
                                  setIsNotificationModalOpen(true)
                                }}
                              >
                                Ver mais
                              </NotificationLink>
                            </>
                          ) : (
                            <NoNotificationWarning>
                              Não há nenhuma notificação no momento.
                            </NoNotificationWarning>
                          )}
                        </NotificationContentWrapper>
                      </NotificationMenuWrapper>
                    </React.Fragment>
                  )}
                </li>
                <li className='nav-item' ref={dropdownRef}>
                  <UserButton onClick={toggleMenu}>
                    <UserIcon height={28} width={28} fill='#fff' />
                  </UserButton>
                  {showMenu && <Dropdown onClose={toggleMenu} />}
                </li>
              </>
            ) : null}
          </ul>
        </div>
      </Navbar>
      <Modal
        isOpen={isNotificationModalOpen}
        onClose={() => {
          setIsNotificationModalOpen(false)
          markAllAsRead()
        }}
        hideCloseButton
        modalStyle={modalStyle}
        customStyles={{
          content: {
            background: 'transparent',
          },
        }}
        isNotification
      >
        <ModalWrapper>
          <NotificationModalContainer>
            <NotificationListTitle>
              <button
                onClick={() => {
                  setIsNotificationModalOpen(false)
                  markAllAsRead()
                }}
              >
                <LeftArrowIcon />
              </button>
              <h3>Notificações</h3>
              <div></div>
            </NotificationListTitle>
            <NotificationModalListWrapper>
              {notifications &&
                notifications.map((notification: NotificationProps, index: number) => (
                  <NotificationsComponent
                    isModal={true}
                    notification={notification}
                    setRead={markAllAsRead}
                    key={index}
                  />
                ))}
            </NotificationModalListWrapper>
          </NotificationModalContainer>
        </ModalWrapper>
      </Modal>
    </>
  )
}

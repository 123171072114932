import { SerializableQuery, truthy } from '~/prix'
import legalEntitiesGeoprocessingQuery from '../levels/legalEntitiesGeoprocessing.query'
import legalEntityAttendanceRadarAliQuery from '../definedQueries/legalEntityAttendanceRadarAli.query'
import legalEntityAttendanceRadarAliIndexQuery from '../definedQueries/legalEntityAttendanceRadarAliIndex.query'
import legalEntityAttendanceAllCoursesEadQuery from '../definedQueries/legalEntityAttendanceAllCoursesEad.query'
import legalEntityAttendanceAppSebraeQuery from '../definedQueries/legalEntityAttendanceAppSebrae.query'
import legalEntityAttendanceAppSebraeIndexQuery from '../definedQueries/legalEntityAttendanceAppSebraeIndex.query'
import legalEntityAttendancePortfolioEadQuery from '../definedQueries/legalEntityAttendancePortfolioEad.query'
import legalEntityAttendanceAllCoursesEadIndexQuery from '../definedQueries/legalEntityAttendanceAllCoursesEadIndex.query'
import legalEntityAttendancePortfolioEadIndexQuery from '../definedQueries/legalEntityAttendancePortfolioEadIndex.query'

/**
 * @param default auxiliar para o merge com as informações providas da URL e identificação do customized (utilizar nome padrão e único)
 * @param type auxiliar para diferenciar formatos por exemplo index que altera scripts para alterar valores do menu e colorização
 * @param searchType auxiliar para definir o tipo de busca (cnae | legalNature | lastMonthAndYearPeriod)
 * @param genericType auxiliar utilizado até então para queries
 * @param menu definir em qual menu o item aparecerá
 */

export interface DefinedOption {
  title: string
  subtitle?: string
  default: string
  type?: string
  genericType?: string
  query: () => SerializableQuery<string, string, {}>
  searchType?: string
  highlightType?: string
  source?: number
  positionSubmenu?: number
  menu?: string
  filterName: string | null
  filterType: string | null
  filterValue: string | null
}

export interface DefinedOptionMerged extends DefinedOption {
  filterName: string | null
  filterType: string | null
  filterValue: string | null
}

export const sourceAux = [
  'Sem fonte',
  'Receita Federal Brasileira',
  'SEBRAE Na Sua Empresa',
  'Radar ALI',
  'Brasil Mais',
  'Todos os cursos (EaD)',
  'Cursos do portfólio (EaD)',
  'SEBRAE Na Sua Empresa (App)',
]

export const allLegalEntityGeoProcessingMapMenuItems = () =>
  [
    {
      title: 'Mapa de empresas ativas',
      default: 'customized',
      type: 'legalEntity',
      query: legalEntitiesGeoprocessingQuery,
    },
    // {
    //   title: 'Empresas de uma atividade econômica específica',
    //   default: 'legalEntityByCnae',
    //   type: 'legalEntity',
    //   query: legalEntitiesGeoprocessingQuery,
    //   searchType: 'cnae',
    // },
    {
      title: 'Porcentagem de MEIs',
      default: 'legalEntityMeiPercentage',
      type: 'legalEntity',
      query: legalEntitiesGeoprocessingQuery,
      highlightType: 'meiPercentage',
      filterName: null,
      filterType: 'isMei',
      filterValue: 'isMei',
    },
    {
      title: 'Quantidade de empresas por km²',
      default: 'legalEntityByKm2',
      type: 'legalEntity',
      query: legalEntitiesGeoprocessingQuery,
      highlightType: 'km2',
      filterName: null,
      filterType: 'area',
      filterValue: 'censusUpdated',
    },
    {
      title: 'Quantidade de empresas per capita',
      default: 'legalEntityPerCapita',
      type: 'legalEntity',
      query: legalEntitiesGeoprocessingQuery,
      highlightType: 'perCapita',
      filterName: null,
      filterType: 'perCapta',
      filterValue: 'censusUpdated',
    },

    {
      title: 'Índice de cobertura SEBRAE Na Sua Empresa (App)',
      default: 'legalEntityAttendanceAppSebraeIndex',
      type: 'legalEntityAttendanceIndex',
      genericType: 'attendanceIndex',
      query: legalEntityAttendanceAppSebraeIndexQuery,
      searchType: 'lastMonthAndYearPeriod',
      source: 6,
      positionSubmenu: 7,
      menu: 'Índice de cobertura',
    },
    {
      title: 'Índice de cobertura Radar ALI',
      default: 'legalEntityAttendanceRadarAliIndex',
      type: 'legalEntityAttendanceIndex',
      genericType: 'attendanceIndex',
      query: legalEntityAttendanceRadarAliIndexQuery,
      searchType: 'lastMonthAndYearPeriod',
      source: 3,
      positionSubmenu: 3,
      menu: 'Índice de cobertura',
    },
    {
      title: 'Índice de cobertura EAD (Todos os cursos)',
      default: 'legalEntityAttendanceAllCoursesEadIndexQuery',
      type: 'legalEntityAttendanceIndex',
      genericType: 'attendanceIndex',
      query: legalEntityAttendanceAllCoursesEadIndexQuery,
      searchType: 'lastMonthAndYearPeriod',
      source: 5,
      positionSubmenu: 5,
      menu: 'Índice de cobertura',
    },
    {
      title: 'Índice de cobertura EAD (Cursos do portfólio)',
      default: 'legalEntityAttendancePortfolioEadIndexQuery',
      type: 'legalEntityAttendanceIndex',
      genericType: 'attendanceIndex',
      query: legalEntityAttendancePortfolioEadIndexQuery,
      searchType: 'lastMonthAndYearPeriod',
      source: 5,
      positionSubmenu: 6,
      menu: 'Índice de cobertura',
    },

    {
      title: 'Empresas atendidas SEBRAE Na Sua Empresa (App)',
      default: 'legalEntityAttendanceAppSebraeQuery',
      type: 'legalEntityAttendance',
      query: legalEntityAttendanceAppSebraeQuery,
      searchType: 'lastMonthAndYearPeriod',
      source: 6,
      positionSubmenu: 7,
      menu: 'Empresas atendidas',
    },
    {
      title: 'Empresas atendidas Radar ALI',
      default: 'legalEntityAttendanceRadarAliLastYear',
      type: 'legalEntityAttendance',
      query: legalEntityAttendanceRadarAliQuery,
      searchType: 'lastMonthAndYearPeriod',
      source: 3,
      positionSubmenu: 3,
      menu: 'Empresas atendidas',
    },
    {
      title: 'Empresas atendidas EAD (Todos os cursos)',
      default: 'legalEntityAttendanceAllCoursesEadQuery',
      type: 'legalEntityAttendance',
      query: legalEntityAttendanceAllCoursesEadQuery,
      searchType: 'lastMonthAndYearPeriod',
      source: 5,
      positionSubmenu: 5,
      menu: 'Empresas atendidas',
    },
    {
      title: 'Empresas atendidas EAD (Cursos do portfólio)',
      default: 'legalEntityAttendancePortfolioEadQuery',
      type: 'legalEntityAttendance',
      query: legalEntityAttendancePortfolioEadQuery,
      searchType: 'lastMonthAndYearPeriod',
      source: 5,
      positionSubmenu: 6,
      menu: 'Empresas atendidas',
    },
  ].filter(truthy)

export const customizedLegalEntityGeoProcessingMapMenuItems = {
  title: 'Busca customizada',
  default: 'customized',
  type: 'legalEntity',
  query: legalEntitiesGeoprocessingQuery,
}

export const legalEntityGeoprocessingMapMenuItems = () =>
  [
    // {
    //   title: 'Empresas de uma atividade econômica específica',
    //   default: 'legalEntityByCnae',
    //   type: 'legalEntity',
    //   query: legalEntitiesGeoprocessingQuery,
    //   searchType: 'cnae',
    // },
    {
      title: 'Porcentagem de MEIs',
      default: 'legalEntityMeiPercentage',
      type: 'legalEntity',
      query: legalEntitiesGeoprocessingQuery,
      highlightType: 'meiPercentage',
      filterName: null,
      filterType: 'isMei',
      filterValue: null,
    },
    {
      title: 'Quantidade de empresas por km²',
      default: 'legalEntityByKm2',
      type: 'legalEntity',
      query: legalEntitiesGeoprocessingQuery,
      highlightType: 'km2',
      filterName: null,
      filterType: 'area',
      filterValue: 'censusUpdated',
    },
    {
      title: 'Quantidade de empresas per capita',
      default: 'legalEntityPerCapita',
      type: 'legalEntity',
      query: legalEntitiesGeoprocessingQuery,
      highlightType: 'perCapita',
      filterName: null,
      filterType: 'perCapta',
      filterValue: 'censusUpdated',
    },

    {
      title: 'Índice de cobertura SEBRAE Na Sua Empresa (App)',
      default: 'legalEntityAttendanceAppSebraeIndex',
      type: 'legalEntityAttendanceIndex',
      genericType: 'attendanceIndex',
      query: legalEntityAttendanceAppSebraeIndexQuery,
      searchType: 'lastMonthAndYearPeriod',
      source: 6,
      positionSubmenu: 7,
      menu: 'Índice de cobertura',
    },
    {
      title: 'Índice de cobertura EAD (Cursos do portfólio)',
      default: 'legalEntityAttendancePortfolioEadIndexQuery',
      type: 'legalEntityAttendanceIndex',
      genericType: 'attendanceIndex',
      query: legalEntityAttendancePortfolioEadIndexQuery,
      searchType: 'lastMonthAndYearPeriod',
      source: 5,
      positionSubmenu: 6,
      menu: 'Índice de cobertura',
    },
    {
      title: 'Índice de cobertura EAD (Todos os cursos)',
      default: 'legalEntityAttendanceAllCoursesEadIndexQuery',
      type: 'legalEntityAttendanceIndex',
      genericType: 'attendanceIndex',
      query: legalEntityAttendanceAllCoursesEadIndexQuery,
      searchType: 'lastMonthAndYearPeriod',
      source: 5,
      positionSubmenu: 5,
      menu: 'Índice de cobertura',
    },
    {
      title: 'Índice de cobertura Radar ALI',
      default: 'legalEntityAttendanceRadarAliIndex',
      type: 'legalEntityAttendanceIndex',
      genericType: 'attendanceIndex',
      query: legalEntityAttendanceRadarAliIndexQuery,
      searchType: 'lastMonthAndYearPeriod',
      source: 3,
      positionSubmenu: 3,
      menu: 'Índice de cobertura',
    },

    {
      title: 'Empresas atendidas SEBRAE Na Sua Empresa (App)',
      default: 'legalEntityAttendanceAppSebraeQuery',
      type: 'legalEntityAttendance',
      query: legalEntityAttendanceAppSebraeQuery,
      searchType: 'lastMonthAndYearPeriod',
      source: 6,
      positionSubmenu: 7,
      menu: 'Empresas atendidas',
    },
    {
      title: 'Empresas atendidas EAD (Cursos do Portfólio)',
      default: 'legalEntityAttendancePortfolioEadQuery',
      type: 'legalEntityAttendance',
      query: legalEntityAttendancePortfolioEadQuery,
      searchType: 'lastMonthAndYearPeriod',
      source: 5,
      positionSubmenu: 6,
      menu: 'Empresas atendidas',
    },
    {
      title: 'Empresas atendidas EAD (Todos os cursos)',
      default: 'legalEntityAttendanceAllCoursesEadQuery',
      type: 'legalEntityAttendance',
      query: legalEntityAttendanceAllCoursesEadQuery,
      searchType: 'lastMonthAndYearPeriod',
      source: 5,
      positionSubmenu: 5,
      menu: 'Empresas atendidas',
    },
    {
      title: 'Empresas atendidas Radar ALI',
      default: 'legalEntityAttendanceRadarAliLastYear',
      type: 'legalEntityAttendance',
      query: legalEntityAttendanceRadarAliQuery,
      searchType: 'lastMonthAndYearPeriod',
      source: 3,
      positionSubmenu: 3,
      menu: 'Empresas atendidas',
    },
  ].filter(truthy)

import React, { useMemo } from 'react'
import Handle from '~/prix/react/components/handle'
import { formatAsBrIntegerNumber, formatAsPercentage } from '~/prix/utils/types/number'
import styled from 'styled-components'
import useItems from '~/prix/react/hooks/items'
import mostUsedStatesQuery from '../queries/mostUsedStates.query'

interface DashboardGridItemMostUsedStatesListProps {
  id: number,
  name: string,
  total_legal_entity?: number,
  total_attendance?: number,
  percentage?: number,
}

export const Items = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  box-shadow: 0px 32px 30px rgba(20, 46, 82, 0.20);
  padding: 4px 12px 0px 12px;
  border-radius: 0 0 12px 12px;
  height: 100%;
  min-height: 119px;

  .bottom {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 0px 10px 0px;
    a {
      color: #005EB8;

      &:hover {
        color: #0F438A;
      }
    }
    border: 1px solid transparent;
  }
`

export const ListRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-shrink: 0;
  border-bottom: 1px solid #B6BFC8;
  padding: 4px 0px 4px 0px;
`

export const Header = styled.div`
  display: flex;
  align-items: center;

  span {
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: bold;
    color: #617385;
  }

  padding: 8px 0px 8px 0px;
`

export const ColumnData = styled.div`
  padding: 8px;
`

export default function DashboardGridItemMostUsedStateList({
  userGeoStateAbbreviation,
  dataSourceId,
}: {
  userGeoStateAbbreviation?: string | null
  dataSourceId?: number
}) {

  const listQuery = useItems(
    () => mostUsedStatesQuery(
      {
        stateAbbreviation: userGeoStateAbbreviation ? userGeoStateAbbreviation : undefined,
        dataSourceId: Number(dataSourceId),
      }
    ),
    [userGeoStateAbbreviation, dataSourceId],
    { cache: 60 * 60 * 24 },
  )

  const allStatesData = listQuery.items?.map((state) => ({
    ...state,
    difference: state.totalAttendances / state.totalLegalEntity,
  }))

  const stateDataOrdered = allStatesData?.sort(
    (a, b) => b.difference - a.difference
  )

  const stateData = stateDataOrdered?.slice(0, 5)

  const definedOption = useMemo(() => {
    if (dataSourceId === 2) {
      return 'legalEntityAttendanceInYourCompanyIndex'
    }

    if (dataSourceId === 3) {
      return 'legalEntityAttendanceRadarAliIndex'
    }

    if (dataSourceId === 5) {
      return 'legalEntityAttendanceAllCoursesEadIndexQuery'
    }

    if (dataSourceId === 6) {
      return 'legalEntityAttendanceAppSebraeIndex'
    }
  }, [dataSourceId])

  const ListItem = ({ name, total_attendance, total_legal_entity, percentage }: DashboardGridItemMostUsedStatesListProps) => (
    <ListRow>
      <ColumnData style={{ width: '27.5%' }}>{name}</ColumnData>
      <ColumnData style={{ width: '25%', textAlign: 'center' }}>{formatAsBrIntegerNumber(Number(total_attendance))}</ColumnData>
      <ColumnData style={{ width: '25%', textAlign: 'center' }}>{formatAsBrIntegerNumber(Number(total_legal_entity))}</ColumnData>
      <ColumnData style={{ width: '22.5%', textAlign: 'center' }}>{formatAsPercentage(Number(percentage))}%</ColumnData>
    </ListRow>
  )

  const currentData = stateData?.map((item: any) => ({
    name: item.geoName,
    total_attendance: item.totalAttendances,
    total_legal_entity: item.totalLegalEntity,
    percentage: item.difference,
  }))

  return (
    <Handle isLoading={listQuery.isLoading} error={listQuery.error} style={{ minHeight: '100px' }}>
      <Items>
        <ListRow>
          <Header style={{ width: '27.5%' }}><span>Estado</span></Header>
          <Header style={{ width: '25%', justifyContent: 'center', textAlign: 'center' }}><span>Empresas atendidas</span></Header>
          <Header style={{ width: '25%', justifyContent: 'center', textAlign: 'center' }}><span>Empresas geocodificadas</span></Header>
          <Header style={{ width: '22.5%', justifyContent: 'center', textAlign: 'center' }}><span>Porcentagem</span></Header>
        </ListRow>
        {currentData?.map((item, index) => (
          <ListItem key={index} {...item} />
        ))}
        <div className='bottom'>
          <a href={`/app/map/country/30?definedOption=${definedOption}`}>Ver mais</a>
        </div>
      </Items>
    </Handle>
  )
}
import { entity, query, count, distinct, equals, truthy, string, number, boolean, isNull, notNull } from '~/prix'

// Matriculas
export default function courseRegisterIndividualQuery({
  stateAbbreviation,
  stateId,
  dataSourceId,
  isLastYearQuery,
}: {
  stateAbbreviation?: string,
  stateId?: string,
  dataSourceId?: number
  isLastYearQuery?: boolean
}) {
  return query('legalEntity')
    .select({
      total: count(distinct(entity('courseRegisterIndividual').property('id'))),
      totalLegalEntity: count(distinct(entity('legalEntityGeoprocessing').property('legalEntityId'))),
    })
    .join({
      current: entity('legalEntity').property('id'),
      target: entity('legalEntityGeoprocessing').property('legalEntityId'),
      join: 'inner',
    })
    .join({
      current: entity('legalEntity').property('id'),
      target: entity('courseRegisterIndividual').property('legalEntityId'),
      join: 'inner',
    })
    .join({
      current: entity('courseRegisterIndividual').property('courseProductId'),
      target: entity('courseProduct').property('id'),
      join: 'inner',
    })
    .join({
      current: entity('legalEntity').property('id'),
      target: entity('legalEntityAttendance').property('legalEntityId'),
      join: 'left',
    })
  [isLastYearQuery === true ? 'join' : 'dummy']({
    current: entity('legalEntity').property('id'),
    target: entity('legalEntityAttendanceLastYear').property('legalEntityId'),
    join: 'left',
  })
  [stateAbbreviation !== undefined ? 'join' : 'dummy']({
    current: entity('legalEntityAttendance').property('stateId'),
    target: entity('state').property('id'),
    join: 'inner',
  })
    .where(
      ...[
        isNull(entity('legalEntity').property('deletedAt')),
        notNull(entity('legalEntityGeoprocessing').property('stateId')),
        stateAbbreviation && stateAbbreviation !== undefined
          ? equals(entity('state').property('abbreviation'), string().value(stateAbbreviation))
          : null,

        stateId && stateId !== undefined
          ? equals(entity('legalEntityAttendance').property('stateId'), string().value(stateId))
          : null,

        dataSourceId && dataSourceId === 1
          ? equals(
            entity('courseProduct').property('isActivePortfolio'),
            boolean().value(true),
          )
          : null,

        dataSourceId && dataSourceId === 5 && isLastYearQuery === true
          ? equals(
            entity('legalEntityAttendanceLastYear').property('dataSourceId'),
            number().value(5),
          ) : null,

        dataSourceId && dataSourceId === 1 && isLastYearQuery === true
          ? equals(
            entity('legalEntityAttendanceLastYear').property('isActivePortfolio'),
            boolean().value(true),
          ) : null,
      ].filter(truthy),
    )
    .limit(1)
}
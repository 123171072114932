import React from 'react'
import styled from 'styled-components'

const Wrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  margin-bottom: 5px;

  .control {
    display: flex;
    flex: 1;

    .react-datepicker-wrapper {
      width: 100%;
      input {
        height: 38px;
        border-radius: 6px;
        border: 1px solid #ccc;
        padding: 0.8rem 1rem 0.8rem 2rem;
        margin: 4px 0px 2px 0px;
        width: 100%;
      }

      .react-datepicker__input-container {
        position: relative;
        display: flex;
        width: 100%;
        align-items: center;
      }
    }
  }

  label {
    font-size: 1em;

    &:not(:last-child) {
      margin-bottom: 0.3em;
    }
  }

  label strong.required {
    color: #c0392b;
  }
`

export default function FieldWrapper({ isRequired, label, children, id }: any) {
  return (
    <Wrapper className='field' title={isRequired ? 'Obrigatório' : ''}>
      {label ? (
        <label htmlFor={id} className='label'>
          {label}
          {isRequired ? <strong className='required'> *</strong> : null}
        </label>
      ) : (
        label
      )}
      <div className='control'>{children}</div>
    </Wrapper>
  )
}

export function BooleanFieldWrapper({ tests, label, children, id }: any) {
  const isRequired = tests.find((item: any) => item.name === 'required')

  return (
    <Wrapper className='field' title={isRequired ? 'Obrigatório' : ''}>
      {label ? (
        <label htmlFor={id} className='label checkbox'>
          {children} {label}
          {isRequired ? <strong className='required'> *</strong> : null}
        </label>
      ) : (
        children
      )}
    </Wrapper>
  )
}

import { defineAction, object, string, number } from '~/prix'
import legalEntityLocationMatchRecommendedAction from './legalEntityLocationMatchRecommended.action.json'

const agentLocationMatchRecommendedDefinition = defineAction({
  ...legalEntityLocationMatchRecommendedAction,
  input: object({
    cpf: string(),
    cnpj: string(),
    limit: number().isOptional(),
  }),
  output: object({}),
})

export default agentLocationMatchRecommendedDefinition

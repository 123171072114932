import { boolean, defineAction, object } from '~/prix'
import sincronizeAppSebraeAction from './sincronizeAppSebrae.action.json'

const sincronizeAppSebraeDefinition = defineAction({
  ...sincronizeAppSebraeAction,
  input: object({}),
  output: object({
    ok: boolean(),
  }),
})

export default sincronizeAppSebraeDefinition

import {
  entity,
  query,
  count,
  distinct,
  equals,
  truthy,
  string,
  number,
  isNull,
  notNull,
  ternary,
  boolean,
} from '~/prix'

export default function attendanceQuery({
  stateAbbreviation,
  stateId,
  dataSourceId,
  isLastYearQuery,
}: {
  stateAbbreviation?: string
  stateId?: string
  dataSourceId?: number
  isLastYearQuery?: boolean
}) {
  const dataSources = [3, 6]

  return query('legalEntityAttendance')
    .select({
      total: count(entity('legalEntityAttendance').property('id')),
      totalLegalEntity:
        dataSourceId && dataSourceId !== 0 // 0 é a Geral
          ? count(
              distinct(
                ternary(
                  equals(
                    entity('legalEntity').property('registrationStatus'),
                    string().value('active'),
                  ),
                  entity('legalEntity').property('id'),
                  number().value(null as unknown as number),
                ),
              ),
            )
          : count(distinct(entity('legalEntityAttendance').property('legalEntityId'))),
    })
    .join({
      current: entity('legalEntityAttendance').property('id'),
      target: entity('legalEntityAttendanceDataSource').property('legalEntityAttendanceId'),
      join: 'inner',
    })
    .join({
      current: entity('legalEntityAttendanceDataSource').property('dataSourceId'),
      target: entity('dataSource').property('id'),
      join: 'inner',
    })
    [dataSourceId && dataSourceId !== 0 ? 'join' : 'dummy']({
      current: entity('legalEntityAttendance').property('legalEntityId'),
      target: entity('legalEntity').property('id'),
      join: 'left',
    })
    [dataSourceId && dataSourceId !== 0 ? 'join' : 'dummy']({
      current: entity('legalEntityAttendance').property('legalEntityId'),
      target: entity('legalEntityGeoprocessing').property('legalEntityId'),
      join: 'inner',
    })
    [isLastYearQuery === true ? 'join' : 'dummy']({
      current: entity('legalEntityAttendance').property('legalEntityId'),
      target: entity('legalEntityAttendanceLastYear').property('legalEntityId'),
      join: 'inner',
    })
    [stateAbbreviation !== undefined ? 'join' : 'dummy']({
      current: entity('legalEntityAttendance').property('stateId'),
      target: entity('state').property('id'),
      join: 'inner',
    })
    .where(
      ...[
        dataSourceId && dataSourceId !== 0
          ? isNull(entity('legalEntity').property('deletedAt'))
          : null,

        // equals(entity('dataSource').property('isActive'), boolean().value(true)),

        stateAbbreviation && stateAbbreviation !== undefined
          ? equals(entity('state').property('abbreviation'), string().value(stateAbbreviation))
          : null,

        stateId && stateId !== undefined
          ? equals(entity('legalEntityAttendance').property('stateId'), string().value(stateId))
          : null,

        dataSourceId && dataSources.includes(dataSourceId)
          ? equals(
              entity(`legalEntityAttendanceDataSource`).property(`dataSourceId`),
              number().value(dataSourceId),
            )
          : null,
        dataSourceId && dataSourceId !== 0
          ? notNull(entity('legalEntityGeoprocessing').property('stateId'))
          : null,
        dataSourceId && dataSources.includes(dataSourceId) && isLastYearQuery === true
          ? equals(
              entity('legalEntityAttendanceLastYear').property('dataSourceId'),
              number().value(dataSourceId),
            )
          : null,
      ].filter(truthy),
    )
    .limit(1)
}

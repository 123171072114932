import { count, entity, equals, number, query, string } from '~/prix'
import asGeoJson from '~/prix/functions/asGeoJson'

export default function citiesQuery(
  by: 'stateId' | 'mesoRegionId' | 'microRegionId' | 'cityId',
  id: string,
) {
  switch (by) {
    case 'stateId':
      return query('city')
        .select({
          id: entity('city').property('id'),
          name: entity('city').property('name'),
          boundary: asGeoJson(entity('city').property('lowerQualityBoundary')),
          center: asGeoJson(entity('city').property('center')),
        })
        .join({
          current: entity('city').property('microRegionId'),
          target: entity('microRegion').property('id'),
        })
        .join({
          current: entity('microRegion').property('mesoRegionId'),
          target: entity('mesoRegion').property('id'),
        })
        .where(equals(entity('mesoRegion').property('stateId'), string().value(id)))
        .limit(10000)
    case 'microRegionId':
      return query('city')
        .select({
          id: entity('city').property('id'),
          name: entity('city').property('name'),
          boundary: asGeoJson(entity('city').property('lowerQualityBoundary')),
          center: asGeoJson(entity('city').property('center')),
        })
        .where(equals(entity('city').property('microRegionId'), string().value(id)))
        .limit(10000)
    case 'mesoRegionId':
      return query('city')
        .select({
          id: entity('city').property('id'),
          name: entity('city').property('name'),
          boundary: asGeoJson(entity('city').property('lowerQualityBoundary')),
          center: asGeoJson(entity('city').property('center')),
        })
        .join({
          current: entity('city').property('microRegionId'),
          target: entity('microRegion').property('id'),
        })
        .join({
          current: entity('microRegion').property('mesoRegionId'),
          target: entity('mesoRegion').property('id'),
        })
        .where(equals(entity('mesoRegion').property('id'), string().value(id)))
        .limit(10000)
    case 'cityId':
      return query('city')
        .select({
          id: entity('city').property('id'),
          name: entity('city').property('name'),
          boundary: asGeoJson(entity('city').property('lowerQualityBoundary')),
          center: asGeoJson(entity('city').property('center')),
          stateId: entity('state').property('id'),
        })
        .join({
          current: entity('city').property('microRegionId'),
          target: entity('microRegion').property('id'),
        })
        .join({
          current: entity('microRegion').property('mesoRegionId'),
          target: entity('mesoRegion').property('id'),
        })
        .join({
          current: entity('mesoRegion').property('stateId'),
          target: entity('state').property('id'),
        })
        .where(equals(entity('city').property('id'), string().value(id)))
        .limit(1)
    default:
      throw new Error(`Invalid by: ${by} on cities query`)
  }
}

import React, { createContext, useContext, useEffect, useState } from 'react'
import { NotificationProps } from '~/components/header'
import { getTokens } from '~/utils/server/firebase/config'

interface NotificationsContextValue {
  notifications: NotificationProps[]
  setNotifications: React.Dispatch<React.SetStateAction<NotificationProps[]>>
  isDeviceUpdated: boolean
  setIsDeviceUpdated: React.Dispatch<React.SetStateAction<boolean>>
  countUnreadNotifications: number
  setCountUnreadNotifications: React.Dispatch<React.SetStateAction<number>>
  userToken: string | null
}

const NotificationsContext = createContext<NotificationsContextValue | null>(null)

export const NotificationsProvider: React.FC = ({ children }) => {
  const [notifications, setNotifications] = useState<NotificationProps[]>([])
  const [isDeviceUpdated, setIsDeviceUpdated] = useState<boolean>(false)
  const [countUnreadNotifications, setCountUnreadNotifications] = useState<number>(0)
  const [userToken, setUserToken] = useState<string | null>(null)

  useEffect(() => {
    if (Notification.permission !== 'granted') {
      // Init FCM
      navigator.serviceWorker
        .register("./firebase-messaging-sw.js")
        .then(function (registration) {
          console.info("Service worker registration successful")
        })
        .catch(function (err) {
          console.info("Service worker registration failed, error:", err)
        })
    }

    getTokens(setUserToken)
  }, [])

  return (
    <NotificationsContext.Provider value={{
      notifications,
      setNotifications,
      isDeviceUpdated,
      setIsDeviceUpdated,
      countUnreadNotifications,
      setCountUnreadNotifications,
      userToken,
    }}>
      {children}
    </NotificationsContext.Provider>
  )
}

export default function useNotificationContext(): NotificationsContextValue {
  const notificationsContext = useContext(NotificationsContext)
  if (notificationsContext === null) {
    throw new Error('useNotificationContext must be used within a NotificationsProvider')
  }

  return notificationsContext
}
import {
  boolean,
  count,
  descending,
  distinctOn,
  entity,
  equals,
  likeAny,
  notNull,
  number,
  query,
  string,
  truthy,
} from '~/prix'
import asGeoJson from '~/prix/functions/asGeoJson'
import { FilterHighlight, optionsBooleanValue } from '../map/legalEntityGeoprocessingMapLevels.data'
import { DefinedOption } from '../map/menu/legalEntityGeoprocessingMapMenu.data'

interface mapHeatmapQueryOptions {
  filter: FilterHighlight | null
  availableGeoLevel: string
  mapLevel: string
  parentId: string | null
  definedOption?: DefinedOption | null
}

export default function mapHeatmapQuery({
  filter,
  availableGeoLevel,
  mapLevel,
  parentId,
  definedOption,
}: mapHeatmapQueryOptions) {

  const hasFilter = filter !== null && filter.value !== null
  const generatedQuery = query('legalEntityGeoprocessing')
    .select({
      location: distinctOn(
        asGeoJson(
          entity('legalEntityGeoprocessing').property(
            `${availableGeoLevel}LevelHeatmapCoordinates`,
          ),
        ),
      ),
      aggregator: entity('legalEntityGeoprocessing').property(
        `${availableGeoLevel}LevelHeatmapCoordinates`,
      ),
      weight: count(entity('legalEntityGeoprocessing').property('legalEntityId')),
    })
    .where(
      ...[
        notNull(
          entity('legalEntityGeoprocessing').property(
            `${availableGeoLevel}LevelHeatmapCoordinates`,
          ),
        ),
        parentId
          ? equals(
            entity('legalEntityGeoprocessing').property(`${mapLevel}Id`),
            string().value(parentId),
          )
          : null,
        filter?.type === 'size' && filter?.value
          ? equals(
            entity('legalEntity').property(`${filter?.type}`),
            string().value(`${filter?.value!}`),
          )
          : null,
        filter?.type === 'cnae' && filter?.value
          ? likeAny(
            entity('legalEntity').property('cnaes'),
            filter?.value
              .toString()
              .split(',')
              .map(cnae_id => string().value(`%,${cnae_id!}%`)),
            { sensitive: true },
          )
          : null,
        filter?.type === 'legalNature' && filter?.value
          ? equals(
            entity('legalEntity').property('legalNatureId'),
            string().value(`${filter?.value!}`),
          )
          : null,


        filter?.type === 'attendance' && filter?.value && filter?.attendanceSource ||
          (definedOption?.type === 'legalEntityAttendance' &&
            definedOption?.source &&
            definedOption?.default !== 'legalEntityAttendancePortfolioEadQuery' &&
            definedOption?.genericType !== 'attendanceIndex')
          ? equals(
            entity(
              `legalEntityAttendance${filter === null || filter?.value === 'lastYear' ? 'LastYear' : 'LastMonth'
              }`,
            ).property(`dataSourceId`),
            number().value(Number(filter?.attendanceSource ? filter?.attendanceSource : definedOption?.source)),
          )
          : null,

        filter?.type === 'attendance' && filter?.value && filter.eadOption === 'portfolio' && !filter?.courseProduct ||
          (definedOption?.default === 'legalEntityAttendancePortfolioEadQuery')
          ? equals(
            entity(
              `legalEntityAttendance${filter === null || filter?.value === 'lastYear' ? 'LastYear' : 'LastMonth'}`,
            ).property('isActivePortfolio'),
            boolean().value(true),
          )
          : null,

        optionsBooleanValue.includes(`${filter?.type}`)
          ? equals(entity('legalEntity').property(`${filter?.type}`), boolean().value(true))
          : null,
      ].filter(truthy),
    )
    .order(descending('weight'))
    .limit(15000)

  if (hasFilter || definedOption) {
    return generatedQuery
      .join({
        current: entity('legalEntityGeoprocessing').property('legalEntityId'),
        target: entity('legalEntity').property('id'),
        join: 'inner',
      })
    [
      (filter?.type === 'attendance' && filter?.value === 'lastYear') ||
        (definedOption?.type === 'legalEntityAttendance' && filter === null)
        ? 'join'
        : 'dummy'
    ]({
      current: entity('legalEntityGeoprocessing').property('legalEntityId'),
      target: entity(`legalEntityAttendanceLastYear`).property('legalEntityId'),
      join: 'left',
    })
    [
      (filter?.type === 'attendance' && filter?.value === 'lastMonth')
        ? 'join'
        : 'dummy'
    ]({
      current: entity('legalEntityGeoprocessing').property('legalEntityId'),
      target: entity(`legalEntityAttendanceLastMonth`).property('legalEntityId'),
      join: 'left',
    })
  }
  return generatedQuery
}



import React, { useState } from 'react'
import styled from 'styled-components'
import { institutionalColors } from '~/design'
import ArrowUpIcon from '~/components/icons/arrows/48px_triangle-up-19.svg'
import ArrowDownIcon from '~/components/icons/arrows/48px_triangle-down-20.svg'

const PresetWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding: 0 20px;

  .containers {
    display: flex;
    flex: 1;
    flex-direction: column;
    margin: 16px 8px 16px 8px;
  }

  button {
    margin-top: 26px;
    padding: 16px 0;
  }
`

const SectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
  color: ${institutionalColors.graySebrae30};
  background: #fff;

  padding: 16px 12px;
  margin: 0 2px;
  border: solid 1px rgba(221, 221, 221, 1);
  border-radius: 0px 0px 8px 8px;
  box-shadow: 0px 32px 30px 0px rgba(20, 46, 82, 0.2);

  .sources {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    border-radius: 4px;
    cursor: pointer;

    svg {
      margin-left: 12px;
      fill: ${institutionalColors.graySebrae30};
    }

    h3 {
      margin-left: 8px;
    }

    .icon-wrapper {
      display: flex;
      flex: 1;
      justify-content: end;
      margin-right: 10px;
    }

    .border {
      border-left: 2px solid transparent;
      border-radius: 12px;
      width: 4px;
      height: 40px;

      &.active {
        border: 2px solid #0f438a;
      }
    }

    &:hover {
      color: #0f438a;

      .border {
        border: 2px solid #0f438a;
      }

      svg {
        fill: #0f438a;
      }
    }
  }
`

const ButtonStyled = styled.button`
  display: flex;
  text-decoration: none;
  text-align: center;
  justify-content: center;
  width: 100%;
  padding: 16px 18px;
  margin: 12px 0 0 0;
  border-radius: 12px;
  border: none;
  box-shadow: 0px 16px 15px 0px rgba(20, 46, 82, 0.2);

  background-color: #005eb8;
  color: #fff;

  font-size: 18px;
  font-weight: 700;

  transition-duration: 0.3s;

  cursor: pointer;

  &:hover {
    background-color: #0f438a;
    color: #fff;
  }
`

export const SectionTitle = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 8px 8px 0px 0px;
  background: #0f438a;
  height: 52px;
  padding: 0 12px;
  margin: 0 2px;

  span {
    flex: 1;
    color: #fff;
    text-align: center;
    font-style: italic;
    font-weight: 700;
    line-height: normal;
    font-size: 19px;
  }

  svg {
    fill: #fff;
  }
`

export default function DashboardSources({
  dataSource,
  setDataSource,
  title,
  toggleMenuVisibility,
  tabIndicators,
}: {
  dataSource: string
  setDataSource: React.Dispatch<React.SetStateAction<string>>
  title: string
  toggleMenuVisibility: () => void
  tabIndicators?: any
}) {
  const [selectedDataSource, setSelectedDataSource] = useState(dataSource)
  const handleSourceClick = (source: string) => {
    setSelectedDataSource(source)
  }

  const handleSubmit = (source: string) => {
    setDataSource(source)
    toggleMenuVisibility()
  }

  const dataSources = [
    'Geral',
    'SEBRAE Na Sua Empresa',
    'Radar ALI',
    'EaD',
    'Portfólio Ead',
    'SEBRAE Na Sua Empresa (App)',
  ]

  return (
    <PresetWrapper>
      <div className='containers'>
        <SectionTitle>
          <span>Fonte de Dados</span>
        </SectionTitle>
        <SectionContainer>
          {dataSources.map(source => (
            <div
              key={source}
              className={`sources ${selectedDataSource === source ? 'active' : ''}`}
              onClick={() => handleSourceClick(source)}
            >
              <div className={`border ${selectedDataSource === source ? 'active' : ''}`} />
              <h3>{source}</h3>
              <div className='icon-wrapper'>
                {tabIndicators?.appSebrae.condition === true &&
                source === 'SEBRAE Na Sua Empresa (App)' ? (
                  <ArrowUpIcon height={18} width={18} fill={'#14EBAC'} />
                ) : tabIndicators?.appSebrae.condition === false &&
                  source === 'SEBRAE Na Sua Empresa (App)' ? (
                  <ArrowDownIcon height={18} width={18} fill={'#EB1453'} />
                ) : null}
                {tabIndicators?.portfolio.condition === true && source === 'Portfólio Ead' ? (
                  <ArrowUpIcon height={18} width={18} fill={'#14EBAC'} />
                ) : tabIndicators?.portfolio.condition === false && source === 'Portfólio Ead' ? (
                  <ArrowDownIcon height={18} width={18} fill={'#EB1453'} />
                ) : null}
                {tabIndicators?.ead.condition === true && source === 'EaD' ? (
                  <ArrowUpIcon height={18} width={18} fill={'#14EBAC'} />
                ) : tabIndicators?.ead.condition === false && source === 'EaD' ? (
                  <ArrowDownIcon height={18} width={18} fill={'#EB1453'} />
                ) : null}
                {tabIndicators?.radarAli.condition === true && source === 'Radar ALI' ? (
                  <ArrowUpIcon height={18} width={18} fill={'#14EBAC'} />
                ) : tabIndicators?.radarAli.condition === false && source === 'Radar ALI' ? (
                  <ArrowDownIcon height={18} width={18} fill={'#EB1453'} />
                ) : null}
              </div>
            </div>
          ))}
        </SectionContainer>
        <ButtonStyled onClick={() => handleSubmit(selectedDataSource)}>
          Efetivar seleção
        </ButtonStyled>
      </div>
    </PresetWrapper>
  )
}

import React, { useMemo } from 'react'
import Handle from '~/prix/react/components/handle'
import styled from 'styled-components'
import useItems from '~/prix/react/hooks/items'
import { QueryBase } from '~/prix/query'
import disproportionateQuery from '../queries/disproportinate.query'
import DashboardBarChart from '../components/dashboardBarChart.component'

const AttentionList = styled.div`
  padding: 0 17px 30px 17px;
`

export default function DashboardGridItemDisproportionateComponent({
  userGeoStateAbbreviation,
  dataSourceId,
  isEnabled = true,
}: {
  userGeoStateAbbreviation?: string | null
  dataSourceId?: number
  isEnabled?: boolean
}) {
  const { publicQuery, attendanceQuery, legalEntityServedCountQuery, portfolioEadQuery } =
    disproportionateQuery({
      geoId: 30,
      stateAbbreviation: userGeoStateAbbreviation,
      dataSourceId: Number(dataSourceId),
    })

  const publicResult = useItems(
    () => (isEnabled ? (publicQuery as QueryBase) : (null as never)),
    [isEnabled, dataSourceId],
    {
      cache: 60 * 60 * 24,
      autoLoad: true,
    },
  )

  const attendanceResult = useItems(
    () => (isEnabled ? (attendanceQuery as QueryBase) : (null as never)),
    [isEnabled, dataSourceId],
    {
      cache: 60 * 60 * 24,
      autoLoad: true,
    },
  )

  const legalEntityServedCountResult = useItems(
    () => (isEnabled ? (legalEntityServedCountQuery as QueryBase) : (null as never)),
    [isEnabled, dataSourceId],
    {
      cache: 60 * 60 * 24,
      autoLoad: true,
    },
  )

  const portfolioEadResult = useItems(
    () => (isEnabled ? (portfolioEadQuery as QueryBase) : (null as never)),
    [isEnabled, dataSourceId],
    {
      cache: 60 * 60 * 24,
      autoLoad: true,
    },
  )

  const mergedData = useMemo(() => {
    if (!publicResult.items || !attendanceResult.items || !legalEntityServedCountResult.items) {
      return []
    }

    return publicResult.items.map(publicItem => {
      const attendanceItem = attendanceResult.items?.find(
        attendance => attendance.cnaeId === publicItem.cnaeId,
      )
      const portfolioEadItem = portfolioEadResult.items?.find(
        portfolioEad => portfolioEad.cnaeId === publicItem.cnaeId,
      )

      if ((attendanceItem || portfolioEadItem) && dataSourceId) {
        const publicRate =
          publicItem.publicCount &&
          legalEntityServedCountResult?.items &&
          legalEntityServedCountResult.items.length > 0
            ? parseInt(publicItem.publicCount) /
              parseInt(legalEntityServedCountResult.items[0].count)
            : undefined

        const attendanceRate =
          dataSourceId !== 1
            ? attendanceItem?.attendanceCount &&
              legalEntityServedCountResult?.items &&
              legalEntityServedCountResult.items.length > 0
              ? parseInt(attendanceItem?.attendanceCount) /
                parseInt(legalEntityServedCountResult.items[0].count)
              : undefined
            : dataSourceId === 1
            ? portfolioEadItem?.count &&
              legalEntityServedCountResult?.items &&
              legalEntityServedCountResult.items.length > 0
              ? parseInt(portfolioEadItem.count) /
                parseInt(legalEntityServedCountResult.items[0].count)
              : undefined
            : undefined

        return {
          ...publicItem,
          ...attendanceItem,
          ...portfolioEadItem,
          publicRate,
          attendanceRate,
        }
      }

      return publicItem
    })
  }, [
    publicResult.items,
    attendanceResult.items,
    legalEntityServedCountResult.items,
    portfolioEadResult.items,
    dataSourceId,
  ])

  const exclusiveAttendanceItems =
    mergedData.length > 0 && dataSourceId !== 1
      ? attendanceResult.items?.filter(
          attendance =>
            !publicResult.items?.some(publicItem => publicItem.cnaeId === attendance.cnaeId),
        )
      : undefined
  const exclusivePortfolioEadItems =
    mergedData.length > 0 && dataSourceId === 1
      ? portfolioEadResult.items?.filter(
          portfolioEad =>
            !publicResult.items?.some(publicItem => publicItem.cnaeId === portfolioEad.cnaeId),
        )
      : undefined

  const extendedData =
    mergedData.length > 0 && exclusiveAttendanceItems && dataSourceId !== 1
      ? mergedData?.concat(exclusiveAttendanceItems)
      : mergedData.length > 0 && exclusivePortfolioEadItems && dataSourceId === 1
      ? mergedData?.concat(exclusivePortfolioEadItems)
      : undefined

  const limitedData = extendedData
    ? extendedData.filter(item => item.attendanceRate)?.slice(0, 3)
    : undefined

  return (
    <Handle
      isLoading={
        publicResult.isLoading ||
        attendanceResult.isLoading ||
        legalEntityServedCountResult.isLoading
      }
      error={attendanceResult.error}
      style={{ minHeight: '100px' }}
    >
      <AttentionList>
        {limitedData?.map((item, index) => (
          <DashboardBarChart
            title={`${item?.cnaeDescription} (${item?.cnaeId})`}
            key={index}
            items={[
              {
                title: 'Dentre todas as empresas',
                percentage: Number(item.publicRate),
              },
              {
                title: 'Dentre as empresas atendidas pelo SEBRAE',
                percentage: Number(item.attendanceRate),
              },
            ]}
          />
        ))}
      </AttentionList>
    </Handle>
  )
}

import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { colors, institutionalColors } from '~/design'
import UpIcon from '~/components/icons/arrows/16px_minimal-up.svg'
import DownIcon from '~/components/icons/arrows/16px_minimal-down.svg'
import EnumeratedField from '~/prix/react/components/form/field/enumeratedField'
import { enumerated } from '~/prix'
import useActionFunction from '~/prix/react/hooks/actionFunction'
import updateTimeSeriesNotificationSettingsDefinition from '../updateTimeSeriesNotificationSettings/updateTimeSeriesNotificationSettings.definition'
import { NotificationSettingsProps } from './notificationSettings.screen'
import { emitMessage } from '~/components/toast'
import Loader from 'react-spinners/PulseLoader'

const SettingsContainer = styled.form`
  display: flex;
  flex-direction: column;
  cursor: default;
`

const HeaderCard = styled.div`
  display: flex;
  border-radius: 8px 8px 0px 0px;
  background: ${colors.oceanBlue};
  margin: 0;
  padding: 20px;
  /* cursor: pointer; */

  .header-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 3px;
    width: 100%;
    overflow: hidden;

    h2 {
      color: #fff;
      line-height: 22px;
      font-weight: 600;
      font-size: 18px;
      margin: 0;
    }

    svg {
      fill: #fff;
    }
  }
`

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
  color: ${institutionalColors.graySebrae30};
  background: #fff;
  min-height: calc(100vh - 250px);

  padding: 20px;
  gap: 20px;
  border: solid 1px rgba(221, 221, 221, 1);
  border-radius: 0px 0px 8px 8px;
  box-shadow: 0px 32px 30px 0px rgba(20, 46, 82, 0.2);
`

const SectionDiv = styled.div``
const SectionTitle = styled.h3`
  margin: 0;
`

const InputContainer = styled.div`
  display: flex;
  gap: 20px;
`

const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 12px;
  gap: 2px;
  width: 50%;
`

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-top: 20px;
`

const SubmitButton = styled.button`
  background-color: #005eb8;
  box-shadow: 0px 2px 4px 0px #142e5233;
  border: none;
  border-radius: 4px;
  padding: 4px 16px;

  font-weight: 700;
  font-size: 16px;
  line-height: 23px;
  color: #fff;
  cursor: pointer;

  &:hover {
    background-color: #0f438a;
    box-shadow: 0px 4px 8px 0px #142e5233;
  }

  &:active {
    background-color: #58c4da;
    box-shadow: 0px 4px 8px 0px #142e5233;
  }
`

const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`

const geoLevelAux = {
  country: 'Apenas país',
  countryAndStates: 'País e todos os estados',
  states: 'Todos os estados',
  state: 'Apenas estado de origem',
  off: 'Não receber notificações',
}

const geoLevelAuxStateOnly = {
  state: 'Apenas estado de origem',
  off: 'Não receber notificações',
}

const alertLabels = {
  all: 'Todos os alertas',
  outbreak: 'Surto',
  above: 'Acima',
  below: 'Abaixo',
  emergent: 'Emergente',
  urgent: 'Urgente',
}

const sections = [
  { title: 'SEBRAE Na Sua Empresa (App)', key: 'sebraeApp' },
  { title: 'Todos os cursos - EAD', key: 'allCourses' },
  { title: 'Cursos do portfólio - EAD', key: 'portfolioCourses' },
  { title: 'SEBRAE Na Sua Empresa', key: 'sse' },
  { title: 'Radar ALI', key: 'ali' },
  { title: 'Abertura de empresas', key: 'businessOpening' },
  { title: 'Fechamento de empresas', key: 'businessClosing' },
]

export default function TimeSeriesSettings({
  roles,
  notificationSettings,
  isLoading,
}: {
  roles: string[] | undefined
  notificationSettings: NotificationSettingsProps | undefined
  isLoading: boolean
}) {
  const [notificationAlerts, setNotificationAlerts] = useState<{
    sebraeApp: string[]
    allCourses: string[]
    portfolioCourses: string[]
    sse: string[]
    ali: string[]
    businessOpening: string[]
    businessClosing: string[]
  }>({
    sebraeApp: [],
    allCourses: [],
    portfolioCourses: [],
    sse: [],
    ali: [],
    businessOpening: [],
    businessClosing: [],
  })
  const [notificationLevels, setNotificationLevels] = useState({
    sebraeApp: 'off',
    allCourses: 'off',
    portfolioCourses: 'off',
    sse: 'off',
    ali: 'off',
    businessOpening: 'off',
    businessClosing: 'off',
  })
  const geoLevelEnum = ['agent', 'stateManager'].some(role => roles?.includes(role))
    ? geoLevelAuxStateOnly
    : geoLevelAux
  const { callAction: callActionUpdateTimeSeriesNotificationSettings } = useActionFunction(
    updateTimeSeriesNotificationSettingsDefinition,
  )

  useEffect(() => {
    if (notificationSettings) {
      setNotificationLevels({
        sebraeApp: notificationSettings.tsSebraeApp,
        allCourses: notificationSettings.tsAllCourses,
        portfolioCourses: notificationSettings.tsPortfolioCourses,
        sse: notificationSettings.tsSse,
        ali: notificationSettings.tsAli,
        businessOpening: notificationSettings.tsBusinessOpening,
        businessClosing: notificationSettings.tsBusinessClosing,
      })

      setNotificationAlerts({
        sebraeApp: notificationSettings.tsSebraeAppAlerts
          ? notificationSettings.tsSebraeAppAlerts.split(',')
          : [],
        allCourses: notificationSettings.tsAllCoursesAlerts
          ? notificationSettings.tsAllCoursesAlerts.split(',')
          : [],
        portfolioCourses: notificationSettings.tsPortfolioCoursesAlerts
          ? notificationSettings.tsPortfolioCoursesAlerts.split(',')
          : [],
        sse: notificationSettings.tsSseAlerts ? notificationSettings.tsSseAlerts.split(',') : [],
        ali: notificationSettings.tsAliAlerts ? notificationSettings.tsAliAlerts.split(',') : [],
        businessOpening: notificationSettings.tsBusinessOpeningAlerts
          ? notificationSettings.tsBusinessOpeningAlerts.split(',')
          : [],
        businessClosing: notificationSettings.tsBusinessClosingAlerts
          ? notificationSettings.tsBusinessClosingAlerts.split(',')
          : [],
      })
    }
  }, [notificationSettings])

  const updateNotificationLevel = (field: string, value: any) => {
    setNotificationLevels(prevState => ({
      ...prevState,
      [field]: value,
    }))

    if (value === null) {
      updateAlertLabels(field, [])
    }
  }

  const updateAlertLabels = (field: string, value: any) => {
    if (value && value.includes('all')) {
      setNotificationAlerts(prevState => ({
        ...prevState,
        [field]: ['outbreak', 'above', 'below', 'emergent', 'urgent'],
      }))

      return
    }

    setNotificationAlerts(prevState => ({
      ...prevState,
      [field]: value,
    }))
  }

  const handleSubmit = async (event: { preventDefault: () => void; target: any }) => {
    event.preventDefault()
    let hasError = false

    for (let section of sections) {
      if (
        (notificationLevels[section.key as keyof typeof notificationLevels] !== 'off' ||
          notificationLevels[section.key as keyof typeof notificationLevels] !== null) &&
        !notificationAlerts[section.key as keyof typeof notificationAlerts]
      ) {
        emitMessage(`Selecione pelo menos um nível de alerta para ${section.title}`, 'error')
        hasError = true
        break
      }
    }

    if (!hasError) {
      try {
        emitMessage('Salvando as alterações...', 'info')
        const updatedNotificationLevels = {
          sebraeApp: notificationLevels.sebraeApp || 'off',
          allCourses: notificationLevels.allCourses || 'off',
          portfolioCourses: notificationLevels.portfolioCourses || 'off',
          sse: notificationLevels.sse || 'off',
          ali: notificationLevels.ali || 'off',
          businessOpening: notificationLevels.businessOpening || 'off',
          businessClosing: notificationLevels.businessClosing || 'off',
        }
        await callActionUpdateTimeSeriesNotificationSettings({
          notificationAlerts,
          notificationLevels: updatedNotificationLevels,
        }).resultPromise.then(() => {
          emitMessage('Alterações salvas com sucesso!', 'success')
        })
      } catch (error) {
        emitMessage('Erro ao salvar as alterações', 'error')
      }
    }
  }

  const Section = ({
    title,
    notificationLevel,
    alertLevel,
    updateNotificationLevel,
    updateAlertLabels,
  }: {
    title: string
    notificationLevel: string
    alertLevel: string[]
    updateNotificationLevel: (value: any) => void
    updateAlertLabels: (value: any) => void
  }) => (
    <SectionDiv>
      <SectionTitle>{title}</SectionTitle>
      <InputContainer>
        <InputWrapper>
          <EnumeratedField
            meta={{ label: 'Nível de notificação' }}
            fieldSchema={enumerated(geoLevelEnum)}
            value={notificationLevel}
            onDirectChange={value => updateNotificationLevel(value)}
          />
        </InputWrapper>
        <InputWrapper>
          <EnumeratedField
            meta={{
              label: 'Nível de alerta',
              placeholder: 'Selecione...',
              isDisabled: notificationLevel === 'off' || notificationLevel === null,
              isRequired: notificationLevel === 'off' || notificationLevel === null ? false : true,
            }}
            isMultiple
            fieldSchema={enumerated(alertLabels)}
            value={alertLevel}
            onDirectChange={value => updateAlertLabels(value)}
          />
        </InputWrapper>
      </InputContainer>
    </SectionDiv>
  )

  return (
    <SettingsContainer onSubmit={handleSubmit}>
      <HeaderCard>
        <div className='header-title'>
          <h2>Séries temporais</h2>
          {/* {true ? <UpIcon width={18} height={18} /> : <DownIcon width={18} height={18} />} */}
        </div>
      </HeaderCard>
      {isLoading === false ? (
        <>
          <ContentContainer>
            <React.Fragment>
              {sections.map((section, index) => (
                <Section
                  key={index}
                  title={section.title}
                  notificationLevel={
                    notificationLevels[section.key as keyof typeof notificationLevels]
                  }
                  alertLevel={notificationAlerts[section.key as keyof typeof notificationAlerts]}
                  updateNotificationLevel={value => updateNotificationLevel(section.key, value)}
                  updateAlertLabels={value => updateAlertLabels(section.key, value)}
                />
              ))}
            </React.Fragment>

            <ButtonWrapper>
              <SubmitButton type='submit'>Salvar</SubmitButton>
            </ButtonWrapper>
          </ContentContainer>
        </>
      ) : (
        <ContentContainer>
          <LoaderContainer>
            <Loader color={colors.oceanBlue} />
          </LoaderContainer>
        </ContentContainer>
      )}
    </SettingsContainer>
  )
}

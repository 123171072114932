import React, { useMemo } from 'react'
import { useParams } from 'react-router'
import { entity, query, notNull } from '~/prix'
import AppLayout from '~/components/appLayout'
import Handle from '~/prix/react/components/handle'
import MenuItemCard from '~/prix/react/components/menu/menuItemCard'
import { alertColors, alertLabels, AlertLevel, timeSeriesInfo } from './timeSeries.data'
import TimeSeries from './timeSeries.component'
import Badge from '~/prix/react/components/badge'
import useItems from '~/prix/react/hooks/items'

export default function TimeSeriesScreen() {
  const [menuVisibility, setMenuVisibility] = React.useState(true)
  const params = useParams()

  const timeSeriesResult = useItems(
    () =>
      query('timeSeries')
        .select({
          key: entity('timeSeries').property('key'),
          alertLevel: entity('timeSeries').property('alertLevel'),
          date: entity('timeSeries').property('date'),
        })
        .where(notNull(entity('timeSeries').property('components'))),
    [],
  )

  const timeSeries = useMemo(() => {
    if (!timeSeriesResult.items) return []
    return timeSeriesResult.items
      .filter(item => timeSeriesInfo[item.key as keyof typeof timeSeriesInfo])
      .map(item => {
        const typedItem = item as {
          key: string
          alertLevel: AlertLevel
          date: string
          order: number
        }
        return {
          ...typedItem,
          ...timeSeriesInfo[typedItem.key as keyof typeof timeSeriesInfo],
        }
      })
      .sort((a, b) => a.order - b.order)
  }, [timeSeriesResult.items])

  const selectedTimeSeries = useMemo(
    () => timeSeries.find(timeSeries => timeSeries.key === params.timeSeriesKey) ?? null,
    [timeSeriesResult.items, params.timeSeriesKey],
  )

  return (
    <AppLayout
      title='Séries temporais'
      dockActive='timeSeries'
      initialMenuVisibility={menuVisibility}
      menu={
        <Handle error={timeSeriesResult.error} isLoading={timeSeriesResult.isLoading}>
          {timeSeries.map(current => (
            <MenuItemCard
              key={current.key}
              href={`/app/time-series/${current.key}`}
              title={current.title}
              label={current.label}
              isActive={current.key === params.timeSeriesKey}
              childrenOnRight={
                current.alertLevel ? (
                  <Badge background={alertColors[current.alertLevel as keyof typeof alertColors]}>
                    {alertLabels[current.alertLevel as keyof typeof alertColors]}
                  </Badge>
                ) : null
              }
              menuVisibility={setMenuVisibility}
            />
          ))}
        </Handle>
      }
    >
      {selectedTimeSeries && (
        <TimeSeries
          timeSeries={{
            ...selectedTimeSeries,
          }}
        />
      )}
    </AppLayout>
  )
}

import React, { useMemo, useRef, useState } from 'react'
import styled from 'styled-components'
import Handle from '~/prix/react/components/handle'
import { formatAsBrDecimalNumber, formatAsBrIntegerNumber } from '~/prix/utils/types/number'
import monthAttendanceLastYearQuery from '../queries/monthAttendanceLastYear.query'
import useItems from '~/prix/react/hooks/items'
import { format, sub } from 'date-fns'
import { LineChart, Line, XAxis, Tooltip, Legend, ResponsiveContainer } from 'recharts'
import ArrowUpIcon from '~/components/icons/arrows/48px_triangle-up-19.svg'
import ArrowDownIcon from '~/components/icons/arrows/48px_triangle-down-20.svg'
import InfoIcon from '~/meta/info.svg'

export const Items = styled.div`
  display: flex;
  flex-direction: column;
  box-shadow: 0px 32px 30px rgba(20, 46, 82, 0.2);
  padding: 12px;
  border-radius: 0 0 12px 12px;
  height: 100%;
  justify-content: center;
  min-height: 119px;
`

export const Item = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
  justify-content: space-between;
  align-items: center;

  > span {
    width: 50%;
  }

  @media (min-width: 1025px) {
    align-items: center;
    gap: 20px;
    padding: 0 12px;
  }

  .first {
    color: #fff;
    font-weight: 700;
    font-size: 33px;
  }

  .second {
    color: #fff;
    font-size: 13px;
    font-weight: 400;

    @media (max-width: 1160px) {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
`

const Bottom = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  margin-top: 10px;
  margin-left: 10px;
`

const BottomColumn = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  width: 50%;

  .center {
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .description {
      line-height: 1;
      font-size: 15px;
    }

    .value {
      font-size: 28px;
      font-weight: bold;
    }
  }
`

const BottomRow = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  margin-top: 5px;
  margin-bottom: 10px;

  .ball-wrapper {
    display: flex;
    flex: 1;
    flex-direction: column;
    max-width: 15px;
    justify-content: center;
    margin-right: 8px;

    .ball {
      width: 10px;
      height: 10px;
      background-color: #005eb8;
      flex-direction: column;
      border-radius: 50%;
      display: inline-block;
      margin-right: 3px;
    }
  }

  div {
    display: flex;
    flex-direction: column;

    .first-wrapper {
      display: flex;
      flex-direction: row;
      flex: 1;
      height: 15px;
      width: 100%;
      min-width: 100%;
      align-items: end;

      span {
        font-size: 15px;
      }

      .tooltip {
        position: absolute;
        top: 100%;
        left: 12.5%;
        transform: translateX(-50%);
        background-color: #fff;
        color: #000;
        padding: 5px;
        border-radius: 5px;
        display: none;
        width: 380px;
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
        z-index: 999;
        padding: 15px 5px 15px 5px;

        .ball {
          width: 10px;
          height: 10px;
          background-color: #4285f4;
          border-radius: 50%;
          display: inline-block;
          margin-right: 3px;
          margin-left: 10px;
        }
      }

      .info-icon-container {
        position: relative;
        display: inline-block;
      }

      .info-icon-container:hover .tooltip {
        display: block;
      }
    }

    .second-wrapper {
      display: flex;
      flex-direction: row;
      flex: 1;
      height: 35px;
      min-width: 100%;

      span {
        font-size: 28px;
        font-weight: bold;
      }
    }
  }
`

export default function DashboardGridItemMonthAttendanceLastYear({
  userGeoStateAbbreviation,
  dataSourceId,
  isPortfolioEad,
  lastDates,
  activeDataSourceId,
  tabIndicators,
  isLoadingIndicatorsData,
}: {
  userGeoStateAbbreviation?: string | null
  dataSourceId: number
  isPortfolioEad?: boolean
  lastDates?: any
  activeDataSourceId?: any
  tabIndicators?: any
  isLoadingIndicatorsData?: boolean
}) {
  const chartRef = useRef<HTMLDivElement | null>(null)
  const [showTooltipLastWeek, setShowTooltipLastWeek] = useState(false)
  const [showTooltipSecondLastWeek, setShowTooltipSecondLastWeek] = useState(false)

  const result = useItems(
    () =>
      monthAttendanceLastYearQuery({
        stateAbbreviation: userGeoStateAbbreviation ? userGeoStateAbbreviation : undefined,
        dataSourceId,
        isPortfolioEad,
      }),
    [userGeoStateAbbreviation, dataSourceId, isPortfolioEad],
    { cache: 60 * 60 * 24 },
  )

  const currentDate = new Date()
  const limitDate = new Date(currentDate)
  limitDate.setFullYear(limitDate.getFullYear() - 1)

  const formattedDate = format(limitDate, 'yyyy-MM-dd')

  /**
   * Deve extrair o mês e ano em um objeto de uma string.
   * @param monthAndYear Mês e ano no formato 'yyyy-MM'
   * @returns Objeto com {month, year}
   */
  function extractMonthAndYear(monthAndYear: string) {
    return {
      month: Number(monthAndYear.slice(5)),
      year: Number(monthAndYear.slice(0, 4)),
    }
  }

  let lastYear = result.items?.filter(item => {
    const { month, year } = extractMonthAndYear(item.monthAndYear)
    const itemDate = new Date(year, month - 1, 1)
    return format(itemDate, 'yyyy-MM-dd') >= formattedDate
  })

  if (lastYear?.length === 0) {
    lastYear = result.items?.slice(0, 12)
  }

  const groupedByMonth = lastYear?.map((item, index) => {
    const { year, month } = extractMonthAndYear(item.monthAndYear)
    return {
      month: month,
      monthAndYear: `${month}/${year.toString().slice(2)}`,
      year,
      total: item.total,
    }
  })

  const resultArray = groupedByMonth ? Object.values(groupedByMonth) : null

  if (resultArray) {
    resultArray.sort((a, b) => {
      const yearComparison = a.year - b.year

      return yearComparison === 0 ? a.month - b.month : yearComparison
    })
  }

  const dates = useMemo(() => {
    if (lastDates && activeDataSourceId) {
      const lastAttendance =
        activeDataSourceId && lastDates && lastDates[activeDataSourceId]
          ? format(new Date(lastDates[activeDataSourceId]), 'dd/MM/yyyy')
          : null

      const sixDaysAgoDate =
        activeDataSourceId && lastDates[activeDataSourceId]
          ? sub(new Date(lastDates[activeDataSourceId]), { days: 6 })
          : null
      const sixDaysAgo = sixDaysAgoDate ? format(sixDaysAgoDate, 'dd/MM/yyyy') : null

      const sevenDaysAgoDate =
        activeDataSourceId && lastDates[activeDataSourceId]
          ? sub(new Date(lastDates[activeDataSourceId]), { days: 7 })
          : null
      const sevenDaysAgo = sevenDaysAgoDate ? format(sevenDaysAgoDate, 'dd/MM/yyyy') : null

      const fourteenDaysAgoDate =
        activeDataSourceId && lastDates[activeDataSourceId]
          ? sub(new Date(lastDates[activeDataSourceId]), { days: 13 })
          : null
      const fourteenDaysAgo = fourteenDaysAgoDate ? format(fourteenDaysAgoDate, 'dd/MM/yyyy') : null

      return {
        lastAttendance,
        sixDaysAgo,
        sevenDaysAgo,
        fourteenDaysAgo,
      }
    }
  }, [activeDataSourceId, lastDates])

  const values = useMemo(() => {
    if (activeDataSourceId === 1 && tabIndicators) {
      return {
        latestWeekSum: tabIndicators?.portfolio.latestWeekSum,
        secondLastWeekSum: tabIndicators?.portfolio.secondLastWeekSum,
        percent: tabIndicators?.portfolio.percentage,
      }
    }

    if (activeDataSourceId === 2 && tabIndicators) {
      return {
        latestWeekSum: tabIndicators?.sse.latestWeekSum,
        secondLastWeekSum: tabIndicators?.sse.secondLastWeekSum,
        percent: tabIndicators?.sse.percentage,
      }
    }

    if (activeDataSourceId === 3 && tabIndicators) {
      return {
        latestWeekSum: tabIndicators?.radarAli.latestWeekSum,
        secondLastWeekSum: tabIndicators?.radarAli.secondLastWeekSum,
        percent: tabIndicators?.radarAli.percentage,
      }
    }

    if (activeDataSourceId === 5 && tabIndicators) {
      return {
        latestWeekSum: tabIndicators?.ead.latestWeekSum,
        secondLastWeekSum: tabIndicators?.ead.secondLastWeekSum,
        percent: tabIndicators?.ead.percentage,
      }
    }

    if (activeDataSourceId === 6 && tabIndicators) {
      return {
        latestWeekSum: tabIndicators?.appSebrae.latestWeekSum,
        secondLastWeekSum: tabIndicators?.appSebrae.secondLastWeekSum,
        percent: tabIndicators?.appSebrae.percentage,
      }
    }
  }, [activeDataSourceId, tabIndicators])

  const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
      const { total, monthAndYear } = payload[0].payload
      return (
        <div
          className='custom-tooltip'
          style={{
            border: '1px solid lightgray',
            backgroundColor: '#fff',
            padding: '4px 8px 4px 8px',
          }}
        >
          <p className='label'>{`Data: ${monthAndYear} (mês/ano)`}</p>
          <p className='label'>{`Quantidade de registros: ${formatAsBrIntegerNumber(total)}`}</p>
        </div>
      )
    }
    return null
  }

  const arrowCondition = useMemo(() => {
    if (
      (tabIndicators?.portfolio?.condition &&
        tabIndicators?.portfolio?.percentage !== 0 &&
        activeDataSourceId === 1) ||
      (tabIndicators?.sse?.condition &&
        tabIndicators?.sse?.percentage !== 0 &&
        activeDataSourceId === 2) ||
      (tabIndicators?.radarAli?.condition &&
        tabIndicators?.radarAli?.percentage !== 0 &&
        activeDataSourceId === 3) ||
      (tabIndicators?.ead?.condition &&
        tabIndicators?.ead?.percentage !== 0 &&
        activeDataSourceId === 5) ||
      (tabIndicators?.appSebrae?.condition &&
        tabIndicators?.appSebrae?.percentage !== 0 &&
        activeDataSourceId === 6)
    ) {
      return true
    }

    if (
      (tabIndicators?.portfolio?.condition === false &&
        tabIndicators?.portfolio?.percentage !== 0 &&
        activeDataSourceId === 1) ||
      (tabIndicators?.sse?.condition === false &&
        tabIndicators?.sse?.percentage !== 0 &&
        activeDataSourceId === 2) ||
      (tabIndicators?.radarAli?.condition === false &&
        tabIndicators?.radarAli?.percentage !== 0 &&
        activeDataSourceId === 3) ||
      (tabIndicators?.ead?.condition === false &&
        tabIndicators?.ead?.percentage !== 0 &&
        activeDataSourceId === 5) ||
      (tabIndicators?.appSebrae?.condition === false &&
        tabIndicators?.appSebrae?.percentage !== 0 &&
        activeDataSourceId === 6)
    ) {
      return false
    }

    return undefined
  }, [tabIndicators, activeDataSourceId])

  return (
    <Handle isLoading={result.isLoading} error={result.error} style={{ minHeight: '100px' }}>
      <Items>
        {resultArray ? (
          <div ref={chartRef}>
            <ResponsiveContainer width={'99%'} height={250}>
              <LineChart
                width={500}
                height={250}
                data={resultArray}
                margin={{
                  top: 5,
                  right: 22,
                  left: 22,
                  bottom: 5,
                }}
              >
                <XAxis dataKey='monthAndYear' fontSize={11} />
                <Tooltip content={<CustomTooltip />} />
                <Legend />
                <Line
                  type='monotone'
                  dataKey='total'
                  isAnimationActive={false}
                  stroke='#005EB8'
                  strokeWidth={3}
                  activeDot={{ r: 8 }}
                  name={`Quantidade de ${isPortfolioEad ? 'matrículas' : 'atendimentos'}`}
                />
              </LineChart>
            </ResponsiveContainer>
          </div>
        ) : null}

        {isLoadingIndicatorsData === true || (values && values.percent === null) ? (
          <div
            style={{
              display: 'flex',
              flex: 1,
              alignItems: 'center',
              justifyContent: 'center',
              paddingTop: '5px',
              paddingBottom: '5px',
            }}
          >
            Carregando informações adicionais...
          </div>
        ) : isLoadingIndicatorsData === false ? (
          <Bottom>
            <BottomColumn>
              <BottomRow>
                <div>
                  <div className='first-wrapper'>
                    <span>7 últimos dias com dados</span>
                    <div
                      className='info-icon-container'
                      onMouseEnter={() => setShowTooltipLastWeek(true)}
                      onMouseLeave={() => setShowTooltipLastWeek(false)}
                    >
                      <span style={{ display: 'flex', marginLeft: '8px' }}>
                        <InfoIcon height={16} width={18} />
                      </span>
                      {showTooltipLastWeek ? (
                        <div className='tooltip'>
                          {dates ? (
                            <>
                              <span className='ball'></span>{' '}
                              <span
                                style={{ marginLeft: '8px', marginTop: '14px', fontSize: '13px' }}
                              >
                                Refêrencias da última semana: {dates.sixDaysAgo} -{' '}
                                {dates.lastAttendance}{' '}
                              </span>
                            </>
                          ) : null}
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div className='second-wrapper'>
                    {values ? (
                      <>
                        <span>{formatAsBrIntegerNumber(values.latestWeekSum)}</span>
                      </>
                    ) : null}
                  </div>
                </div>
              </BottomRow>
              <BottomRow>
                <div>
                  <div className='first-wrapper'>
                    <span>7 dias anteriores</span>
                    <div
                      className='info-icon-container'
                      onMouseEnter={() => setShowTooltipSecondLastWeek(true)}
                      onMouseLeave={() => setShowTooltipSecondLastWeek(false)}
                    >
                      <span style={{ display: 'flex', marginLeft: '8px' }}>
                        <InfoIcon height={16} width={18} />
                      </span>
                      {showTooltipSecondLastWeek ? (
                        <div className='tooltip' style={{ width: '400px' }}>
                          {dates ? (
                            <>
                              <span className='ball'></span>{' '}
                              <span
                                style={{ marginLeft: '8px', marginTop: '14px', fontSize: '13px' }}
                              >
                                Refêrencias da penúltima semana: {dates.sevenDaysAgo} -{' '}
                                {dates.fourteenDaysAgo}{' '}
                              </span>
                            </>
                          ) : null}
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div className='second-wrapper'>
                    {values ? (
                      <>
                        <span>{formatAsBrIntegerNumber(values.secondLastWeekSum)}</span>
                      </>
                    ) : null}
                  </div>
                </div>
              </BottomRow>
            </BottomColumn>
            <BottomColumn>
              <div className='center'>
                <div style={{ display: 'flex', padding: '5px' }}>
                  <span className='description'>Variação semanal</span>
                  <span style={{ display: 'flex', marginLeft: '8px', marginTop: '-2px' }}>
                    {arrowCondition === true ? (
                      <ArrowUpIcon height={20} width={20} fill={'#14EBAC'} />
                    ) : arrowCondition === false ? (
                      <ArrowDownIcon height={20} width={20} fill={'#EB1453'} />
                    ) : null}
                  </span>
                </div>
                <span className='value'>
                  {values?.percent?.toFixed(2) > 0
                    ? `+${formatAsBrDecimalNumber(values?.percent?.toFixed(2))}%`
                    : `${formatAsBrDecimalNumber(values?.percent?.toFixed(2))}%`}
                </span>
              </div>
            </BottomColumn>
          </Bottom>
        ) : null}
      </Items>
    </Handle>
  )
}

import debounce from 'debounce-promise'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { components, DropdownIndicatorProps, Options } from 'react-select'
import AsyncSelect from 'react-select/async'
import styled from 'styled-components'
import SearchIcon from '~/components/icons/ui/16px_zoom-2.svg'
import { EntityKey } from '~/prix'
import { fromUnknownError } from '~/prix/error'
import type { Selectables, SelectablesToItem, SerializableQuery } from '~/prix/query'
import useAPI from '~/prix/react/hooks/api'
import resolve from '~/prix/resolve'
import { isEmpty } from '~/prix/utils/empty'

interface OptionGroupBaseSelect {
  isDisabled: boolean
  isFocused: boolean
  isSelected: boolean
}

const LabelWithSubtitle = styled.div`
  line-height: 15px;
  margin-top: -5;
`

const Title = styled.div`
  margin-top: -15;
`

const SpacingParagraph = styled.p`
  margin: 0;
  line-height: 0;
`

const Subtitle = styled.span`
  font-size: 12px;
  line-height: 5px !important;
  margin-bottom: 5px;
  color: #888 !important;
  padding-top: 0;
`

interface SelectFromQueryProps<
  MainEntityKey extends EntityKey,
  MainEntityKeyAlias extends string,
  Selection extends Selectables,
  IsMultiple extends boolean,
> {
  queryFactory: (input: string) => SerializableQuery<MainEntityKey, MainEntityKeyAlias, Selection>
  labelProperty: keyof SelectablesToItem<Selection>
  idProperty: keyof SelectablesToItem<Selection>
  onDirectChange?: (value: SelectablesToItem<Selection>) => void | null
  getLabel?: (value: SelectablesToItem<Selection>) => React.ReactNode
  getValue?: (value: SelectablesToItem<Selection>) => string
  value?: SelectablesToItem<Selection>
  preLoad?: boolean
  isMultiple?: IsMultiple
  isDemarcation?: boolean
  isSearchInput?: boolean
  isSearchComplete?: boolean
  placeholder?: string
  debounceMs?: number
  isDisabled?: boolean
  isClearInput?: boolean
  onClearGeometriesRequest?: (value: boolean) => void
  onFocused?: (value: boolean) => void
  onQueryComplete?: (
    queryResult: SelectablesToItem<Selection | any>[],
  ) => SelectablesToItem<Selection>[]
}

function SelectFromQuery<
  MainEntityKey extends EntityKey,
  MainEntityKeyAlias extends string,
  Selection extends Selectables,
  IsMultiple extends boolean,
>({
  queryFactory,
  onQueryComplete,
  labelProperty,
  idProperty,
  getLabel = item =>
    item.subtitle ? createLabelWithSubtitles(item) : String(item[labelProperty] || ''),
  getValue = item => String(item[idProperty] || ''),
  preLoad = true,
  isMultiple = false as IsMultiple,
  isDemarcation = false,
  isClearInput = false,
  isSearchInput = false,
  isSearchComplete = false,
  placeholder = 'Selecione...',
  debounceMs = 1000,
  onDirectChange,
  onClearGeometriesRequest,
  onFocused,
  value,
  isDisabled = false,
}: SelectFromQueryProps<MainEntityKey, MainEntityKeyAlias, Selection, IsMultiple>) {
  const api = useAPI()
  const [isLoading, setIsLoading] = useState(preLoad)
  const [lastMultipleItem, setLastMultipleItem] = useState(undefined)
  const [firstMultipleItem, setFirstMultipleItem] = useState(undefined)
  const [multipleItemsDemarcation, setMultipleItemsDemarcation] = useState([])

  const DropdownIndicator = (props: DropdownIndicatorProps<any>) => {
    return (
      <components.DropdownIndicator {...props}>
        <SearchIcon
          width={16}
          height={14}
          fill={props.isFocused === false ? '#CCCCCC' : '#666666'}
        />
      </components.DropdownIndicator>
    )
  }

  const loadOptions = useCallback(
    async (inputValue: string): Promise<Options<SelectablesToItem<Selection>>> => {
      const query = queryFactory(inputValue)
      setIsLoading(true)
      try {
        const activateSearch = inputValue.length >= 3 || inputValue.length === 0

        if (activateSearch) {
          const { run } = resolve({
            ...api,
            query,
            onAbort: () => {
              setIsLoading(false)
            },
          })

          const { firstResponse } = await run({})
          const { items } = firstResponse

          if (onQueryComplete) {
            return onQueryComplete(items)
          }
          return items
        } else {
          setIsLoading(false)
          return []
        }
      } catch (error) {
        const parsedError = fromUnknownError(error as Error)
        return [
          {
            [labelProperty]: parsedError.message,
            [idProperty]: null,
            isDisabled: true,
          },
        ] as unknown as SelectablesToItem<Selection>[]
      } finally {
        setIsLoading(false)
      }
    },
    [api, queryFactory],
  )

  const debouncedLoadOptions = useMemo(
    () => debounce(loadOptions, debounceMs),
    [loadOptions, debounceMs],
  )

  useMemo(() => {
    if ((firstMultipleItem && lastMultipleItem && firstMultipleItem.geo !== lastMultipleItem.geo) || firstMultipleItem === undefined) {
      setMultipleItemsDemarcation([])
    }
    if (firstMultipleItem) {
      setMultipleItemsDemarcation(current => [...current, lastMultipleItem])
    }
  }, [firstMultipleItem, lastMultipleItem])

  useEffect(() => {
    if (isClearInput === true) {
      setMultipleItemsDemarcation([])
    }

  }, [isClearInput])

  const handleKeyDown = (event: { key: string }, values: any) => {
    if (event.key === 'Backspace' && values && multipleItemsDemarcation.length > 0 && isDemarcation && isMultiple) {
      setMultipleItemsDemarcation(values.slice(0, -1))
    }
  }

  return (
    <AsyncSelect
      classNamePrefix='react-select'
      value={isDemarcation && isClearInput === false ? multipleItemsDemarcation : !isSearchComplete && isClearInput === false ? value : ''}
      isMulti={isMultiple}
      isLoading={isLoading}
      cacheOptions
      defaultOptions={preLoad}
      components={isSearchInput ? { DropdownIndicator } : undefined}
      loadOptions={debouncedLoadOptions}
      noOptionsMessage={({ inputValue }) =>
        inputValue.length >= 3 ? 'Sem opções.' : 'Digite ao menos 3 caracteres'
      }
      loadingMessage={() => 'Carregando...'}
      isClearable={true}
      placeholder={placeholder}
      getOptionLabel={getLabel as any}
      getOptionValue={getValue}
      styles={{
        container: base => ({
          ...base,
          flex: 1,
          minWidth: '100%',
          maxWidth: '200px',
          pointerEvents: 'auto',

          '>div': {
            cursor: isLoading ? 'wait' : isDisabled ? 'not-allowed' : 'pointer',
            backgroundColor: isDisabled ? '#f1f1f1' : '#fff',

            ':hover': {
              borderColor: isDisabled ? 'transparent' : 'hsl(0, 0%, 70%)',
            },
          },
        }),
        singleValue: base => ({
          ...base,
          gridArea: '1/1/2/2',
        }),
        placeholder: base => ({
          ...base,
          display: '-webkit-box',
          'WebkitLineClamp': '2',
          'WebkitBoxOrient': 'vertical',
          textOverflow: 'ellipsis',
          overflowY: 'hidden',
        }),

        multiValueRemove: (base) => ({
          ...base,
          background: 'none',
          ':hover': {
            ...base[':hover'],
            background: 'none',
            color: '#005EB8',
          },
          display: isDemarcation ? 'none' : base.display,
        }),

        multiValue: (base) => ({ ...base, paddingRight: '5px' }),

        option: (styles: any, { isDisabled, isFocused, isSelected }: OptionGroupBaseSelect) => {
          return {
            ...styles,
            backgroundColor: isDisabled
              ? null
              : isSelected
                ? '#005EB8'
                : isFocused
                  ? '#ABD6FF'
                  : null,
            color: isDisabled
              ? '#838383'
              : isSelected
                ? '#fff'
                : isFocused
                  ? 'rgba(0, 0, 0, 0.75)'
                  : styles.color,

            ':active': {
              ...styles[':active'],
              backgroundColor: !isDisabled && isSelected ? '#005EB8' : '#005EB8',
              color: isDisabled && isSelected ? '#fff' : '#fff',
            },
          }
        },
      }}
      isDisabled={isDisabled}
      onFocus={() => isDemarcation && onFocused ? onFocused(true) : ''}
      onChange={newValue => {
        if (isEmpty(newValue) && isDemarcation && onClearGeometriesRequest) {
          onClearGeometriesRequest(true)
        }

        if (onDirectChange) {
          if (isDemarcation && isMultiple) {
            setFirstMultipleItem(newValue[0])
            setLastMultipleItem(newValue?.slice(-1)[0])
          }

          handleKeyDown({ key: 'Backspace' }, newValue)
          onDirectChange(newValue as unknown as SelectablesToItem<Selection>)
        }
      }}
    />
  )
}

export default React.memo(SelectFromQuery)

function createLabelWithSubtitles(item: any) {
  const { name, subtitle } = item
  const title = name

  return (
    <LabelWithSubtitle>
      <Title>{title}</Title>
      <SpacingParagraph />
      <Subtitle>{subtitle}</Subtitle>
    </LabelWithSubtitle>
  )
}
